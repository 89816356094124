import { Component, OnInit, OnDestroy } from '@angular/core';
import { fuseAnimations } from '../../../core/animations';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { ApiService } from '../../../core/services/api.service';
import { Sort } from '@angular/material';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { DataSource } from '@angular/cdk/collections';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { NativeDateAdapter, DateAdapter } from "@angular/material";


export class TurkishDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
      const str = value.split('.');
      if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
        return null;
      }
      return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}

@Component({
  selector: 'app-rivals-speeds-of-reply',
  templateUrl: './rivals-speeds-of-reply.component.html',
  styleUrls: ['./rivals-speeds-of-reply.component.scss'],
  animations: fuseAnimations,
  providers: [{ provide: DateAdapter, useClass: TurkishDateAdapter }]
})
export class RivalsSpeedsOfReplyComponent implements OnInit, OnDestroy {
  // @ViewChild(MatSort) sort: MatSort;
  subscription: Subscription;
  speedOfReply = [];
  bestList: any;
  reportModel: any = {};
  desserts = [];
  sortedData;
  cardHour = 0;
  cardMinute = 0;
  dataSource: MyDataSource;
  displayedColumns = ['name', 'zeroFourPoint', 'fiveSixPoint'];

  constructor(private chooseCompany: ChooseCompanyService,
    private api: ApiService,
    private translationLoader: FuseTranslationLoaderService,
    private dateAdapter: DateAdapter<Date>,
  ) {
    translationLoader.loadTranslations(english, turkish);
    this.subscription = chooseCompany.getCompany().subscribe(data => {
      this.desserts = [];
      this.sortedData;
      this.bestList = [];
      this.speedOfReply = [];
      this.reportModel.startDate = '2018-01-01';
      var newDate2 = new Date(new Date().getTime() + Math.abs(new Date().getTimezoneOffset() * 60000));
      this.reportModel.endDate = newDate2.toISOString().split('T')[0];
      return new Promise((resolve) => {
        this.subscription = api.sqlPost('corporate/answeredTime', {
          uniqueName: data.uniqueName,
          startDate: this.reportModel.startDate,
          endDate: this.reportModel.endDate
        }).map(res => res.json()).subscribe(element => {

          this.cardHour = Math.floor(element[0].answeredTime / 60);
          this.cardMinute = element[0].answeredTime - (this.cardHour * 60);
          this.desserts.push({
            name: data.name,
            post: element[0].postCount,
            hour: this.cardHour,
            minute: this.cardMinute
          });
        });

        resolve(true);
      }).then(() => {


        this.desserts = [];
        data.rivalBrand.forEach(element => {
          this.subscription = api.sqlPost('corporate/answeredTime', {
            uniqueName: element.uniqueName,
            startDate: this.reportModel.startDate,
            endDate: this.reportModel.endDate
          }).map(res => res.json()).subscribe(feedback => {
            this.cardHour = 0;
            this.cardMinute = 0;
            this.cardHour = Math.floor(feedback[0].answeredTime / 60);
            this.cardMinute = feedback[0].answeredTime - (this.cardHour * 60);
            this.desserts.push({
              name: element.name,
              post: feedback[0].postCount,
              hour: this.cardHour,
              minute: this.cardMinute
            });

            this.dataSource = new MyDataSource(this.desserts);
          });
        });
      });
    })
    dateAdapter.setLocale('tr');
  }
  getByDate() {
    this.reportModel.startDate = this.dateFormat(this.reportModel.startDate);
    this.reportModel.endDate = this.dateFormat(this.reportModel.endDate);
    this.subscription = this.chooseCompany.getCompany().subscribe(data => {

      this.desserts = [];
      this.sortedData;
      this.bestList = [];
      this.speedOfReply = [];
      return new Promise((resolve) => {
        this.subscription = this.api.sqlPost('corporate/answeredTime', {
          uniqueName: data.uniqueName,
          startDate: this.reportModel.startDate,
          endDate: this.reportModel.endDate
        }).map(res => res.json()).subscribe(element => {
          this.cardHour = Math.floor(element[0].answeredTime / 60);
          this.cardMinute = element[0].answeredTime - (this.cardHour * 60);
          this.desserts.push({
            name: data.name,
            post: element[0].postCount,
            hour: this.cardHour,
            minute: this.cardMinute
          });
        });
        // console.log(this.desserts);
        // this.dataSource = new MyDataSource(this.desserts);
        resolve(true);
      }).then(() => {
        this.desserts = [];
        data.rivalBrand.forEach(element => {
          this.subscription = this.api.sqlPost('corporate/answeredTime', {
            uniqueName: element.uniqueName,
            startDate: this.reportModel.startDate,
            endDate: this.reportModel.endDate
          }).map(res => res.json()).subscribe(feedback => {
            this.cardHour = 0;
            this.cardMinute = 0;
            this.cardHour = Math.floor(feedback[0].answeredTime / 60);
            this.cardMinute = feedback[0].answeredTime - (this.cardHour * 60);
            this.desserts.push({
              name: element.name,
              post: feedback[0].postCount,
              hour: this.cardHour,
              minute: this.cardMinute
            });

            this.dataSource = new MyDataSource(this.desserts);
          });
        });
      });
    })

  }
  dateFormat(data) {
    if (data.length == 10) {
      return data;
    }
    var dd = data.getDate();
    var mm = data.getMonth() + 1; //January is 0!
    var yyyy = data.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    data = yyyy + '-' + mm + '-' + dd;
    return data;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
  }

  sortData(sort: Sort) {
    const data = this.desserts.slice();
    if (!sort.active || sort.direction == '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      let isAsc = sort.direction == 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a, b, isAsc);
        case 'post': return this.compare(a, b, isAsc);
        case 'hour': return this.compare(a, b, isAsc);
        case 'minute': return this.compare(a, b, isAsc);
        default: return 0;
      }
    });
  }
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}


export class MyDataSource extends DataSource<any> {
  constructor(
    private data: any) {
    super();
  }
  _filterChange = new BehaviorSubject('');
  _filteredDataChange = new BehaviorSubject('');

  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.data,
      this._filterChange
    ];
    return Observable.merge(...displayDataChanges).map(() => {
      let data = this.data.slice();

      this.filteredData = [...data];

      data = this.sortData(data);

      // Grab the page's slice of data.
      const startIndex = 0 * 5;
      return data.splice(startIndex);
    });
  }

  sortData(data): any[] {


    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';



      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      // return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }

  disconnect() { }

}