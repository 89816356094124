import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { fuseAnimations } from '../../../core/animations';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-brand-crosscheck-all',
  templateUrl: './brand-crosscheck-all.component.html',
  styleUrls: ['./brand-crosscheck-all.component.scss'],
  animations: fuseAnimations
})
export class BrandCrosscheckAllComponent implements OnInit,OnDestroy {
  company: any;
  subscription:Subscription;
  graphicResults = [];
  totalBrand : any;
  displayedColumns = ['name', 'firstColumn', 'secondColumn', 'thirdColumn'];
  dataSource;
  desserts = [{}];
  show = true;
  constructor(private api: ApiService, private chooseCompany: ChooseCompanyService) {
    let list = {
      "Marka Sıralama": [],
      "Paylaşım Sayısı":[],
      "Yüzdeler":[]
    }
    this.subscription = chooseCompany.getCompany().subscribe(data => {
      this.graphicResults = [];
      this.company = data;
      
     
      this.subscription = api.sqlPost('corporate/crooscheckHashtagAll2', {
        uniqueName: data.uniqueName
      })
      .map(res => res.json()).subscribe(snap => {
        this.totalBrand = snap[0].totalBrand;
        var top = 0;
        snap.forEach(element => {
          if(element.hashtag == "Sikayet"){
            element.hashtag = "Şikayet"
          }
          top += element.postCount;
          this.desserts.push({
            hashtags:        this.capitalizeFirstLetter(element.hashtag),
            markaSiralama:   element.row,
            paylasimSayisi : element.postCount,
            yuzde:           (element.postCount / element.hashTotal) * 100 || 0
          });
          
        });
        this.desserts = this.desserts.slice(1);
        this.desserts.push({
          hashtags:'Toplam',
          paylasimSayisi:top,
          yuzde: top != 0 ? 100 : 0
        })
        this.dataSource = this.desserts; 
        this.show = false;  
        
        
      });
    });
  }


  

  ngOnInit() {

  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
ngOnDestroy(){
  this.subscription.unsubscribe();
}

}

