import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import * as firebase from 'firebase';
import { ImageCropperComponent, CropperSettings } from 'ng2-img-cropper';
import { MatDialog } from '@angular/material';
import { ImageCropComponent } from '../../../../core/directives/image-crop/image-crop.component';
import { UserService } from '../../../../core/services/user.service';
import { ImageToDataUrlService } from '../../../../core/services/image-to-data-url/image-to-data-url.service';
import { ResizeOptions } from 'ng2-imageupload';
import { Subscription, Observable } from 'rxjs';
import { ChooseCompanyService } from '../../../../core/services/choose-company.service';
import { DialogComponent } from '../dialog/dialog.component';
import { AngularFirestore } from 'angularfire2/firestore';

@Component({
  selector: 'app-banner-upload1',
  templateUrl: './banner-upload1.component.html',
  styleUrls: ['./banner-upload1.component.scss']
})
export class BannerUpload1Component implements OnInit {

  userId = localStorage.getItem("currentUserId");
  user: any;
  data1: any;
  data2: any;
  cropperSettings1: CropperSettings;
  cropperSettings2: CropperSettings;
  companyProfileImage: any;
  dialogRef: any;

  private subscription: Subscription = new Subscription();
  img: any;
  company: any;
  resizeOptions: ResizeOptions = {
    resizeMaxHeight: 200,
    resizeMaxWidth: 200
  }
  images: any[] = [];
  @ViewChild('cropper', undefined) cropper: ImageCropperComponent;
  @ViewChild('profileImageInput') bannerImage: ElementRef;
  @ViewChild('coverImageInput') coverImage: ElementRef;

  constructor(private afm: AngularFireDatabase,
    private userService: UserService,
    private imageResize: ImageToDataUrlService,
    private afs: AngularFirestore,
    private chooseCompany: ChooseCompanyService,
    public dialog: MatDialog) {

    chooseCompany.getCompany().subscribe(company => {
      this.company = company;

    });

    this.subscription.add(this.userService.getUser().subscribe(user => {
      this.user = user;
    }));

    this.cropperSettings1 = new CropperSettings();
    this.cropperSettings1.width = 1076;
    this.cropperSettings1.height = 400;
    this.cropperSettings1.croppedWidth = 1614;
    this.cropperSettings1.croppedHeight = 600;
    this.cropperSettings1.canvasWidth = window.matchMedia('(max-width: 768px)').matches ? 250 : 500;
    this.cropperSettings1.canvasHeight = window.matchMedia('(max-width: 768px)').matches ? 150 : 300;
    this.cropperSettings1.noFileInput = true;
    this.cropperSettings1.rounded = false;
    this.cropperSettings1.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings1.cropperDrawSettings.strokeWidth = 2;

    this.data1 = {};

    this.cropperSettings2 = new CropperSettings();
    this.cropperSettings2.width = 10;
    this.cropperSettings2.height = 10;
    this.cropperSettings2.keepAspect = false;
    this.cropperSettings2.croppedWidth = 600;
    this.cropperSettings2.croppedHeight = 600;
    this.cropperSettings1.canvasWidth = window.matchMedia('(max-width: 768px)').matches ? 250 : 500;
    this.cropperSettings1.canvasHeight = window.matchMedia('(max-width: 768px)').matches ? 150 : 300;
    this.cropperSettings2.minWidth = 200;
    this.cropperSettings2.minHeight = 200;
    this.cropperSettings2.rounded = true;
    this.cropperSettings2.noFileInput = true;
    this.cropperSettings2.minWithRelativeToResolution = false;
    this.cropperSettings2.cropperDrawSettings.strokeColor = 'rgba(255,125,125,1)';
    this.cropperSettings2.cropperDrawSettings.strokeWidth = 2;

    this.data2 = {};
  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }

  uploadImage(image, imageType) {

    return firebase.storage().ref('/files/' + this.company.uniqueName).child(imageType).putString(image, 'data_url').then(() => {
      let brandId;

      this.afs.collection('brands').ref.where('uniqueName', '==', this.company.uniqueName).get().then((data => {
        brandId = data.docs[0].id;
        if (data.docs[0].data().bannerImage) {
          this.companyProfileImage = data.docs[0].data().bannerImage;
        }
      })).then(() => {

        if (this.companyProfileImage == "no-image.png" || this.companyProfileImage == null) {
          this.afs.collection('brands').doc(brandId).update({ bannerImage: this.company.uniqueName + '%2FbannerImage' });
        }
        this.dialogRef.close();
        this.dialogRef.afterClosed().subscribe(() => {
          let dialogref5 = this.dialog.open(DialogComponent, {
            panelClass: 'my-class6',
            width: '400px',
            data: {
              message: "Banner görseliniz başarıyla yüklendi."
            }
          });

        })


      });

    });
  }

  cropImage(event, cropperSettings, imageType) {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(event.target.files[0].name)) {
      let dialogref3 = this.dialog.open(DialogComponent, {
        panelClass: 'my-class1',
        width: '400px',
        data: {
          message: "Dosya uzantısı .jpg, .jpeg, .png, .gif olmalı"
        }
      });
      return;
    }

    var _URL = window.URL;
    var file, img;
    let isBigger: boolean = false;
    file = event.target.files[0];
    img = new Image();
    img.onload = function () {
      console.log(img.width);
      if (img.width > 300) {
        isBigger = true;
        console.log("kdjkf");

      }
    };
    img.src = _URL.createObjectURL(file);
    var binaryData = [];
    binaryData.push(file);
    window.URL.createObjectURL(new Blob(binaryData, { type: "application/zip" }))
    setTimeout(() => {
      if (isBigger) {
        let dialogRef2 = this.dialog.open(DialogComponent, {
          panelClass: 'my-class',
          width: '500px',
          data: {
            message: "Lütfen genişliği 300x250px'den küçük bir banner yükleyiniz."
          }
        });
        this.bannerImage.nativeElement.value = null;
      }
      else {
        this.dialogRef = this.dialog.open(ImageCropComponent, {
          data: { "cropperSettings": cropperSettings, "image": event.target.files[0], "bool": isBigger }
        });

        this.bannerImage.nativeElement.value = null;
        let sub = this.dialogRef.componentInstance.getCroppedImage().subscribe(image => {

          if (imageType == "bannerImage") {
            let resizeOptions = [{
              maxHeight: 300,
              maxWidth: 250
            }, {
              maxHeight: 64,
              maxWidth: 64
            }, {
              maxHeight: 45,
              maxWidth: 45
            }];
            let count = 0;
            resizeOptions.forEach(option => {
              this.imageResize.readBase64([image], { resize: option }, imageResult => {
                let src = imageResult.resized
                  && imageResult.resized.dataURL
                  || imageResult.dataURL;
                firebase.storage().ref('/files/' + this.company.uniqueName).child("bannerImage_" + option.maxHeight + "_thumb").putString(src, "data_url").then(() => {
                  if (++count == resizeOptions.length) this.uploadImage(image, imageType);
                });
              });
            })
          } else {
            this.uploadImage(image, imageType);
          }
        });
        this.dialogRef.afterClosed().subscribe(() => sub.unsubscribe());
      }
    }, 200);


  }
  public blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  }
  ngOnInit() {
  }

}


