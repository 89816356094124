import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, pipe, Subscription } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { UserService } from './user.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { map } from 'rxjs/operators';

@Injectable()
export class ChooseCompanyService implements OnDestroy {
  company = new BehaviorSubject<any>({});
  companyBrand = new BehaviorSubject<any>({});
  activeCompanyIndex = new Subject<number>();
  companyProducts = new BehaviorSubject<any>({});
  subs = new Subscription();
  constructor(private afm: AngularFireDatabase, private userService: UserService,
    private afs: AngularFirestore
  ) {
    this.subs.add(userService.getUser().subscribe(user => {
      if (user)
        if (user.companies) {

          this.setCompany(user.companies[0], 0);
          this.companyId(user.companies[0]);
          this.activeCompanyIndex.next(0);
        }
    }));
  }

  setCompany(company, index) {

    this.activeCompanyIndex.next(index);
    this.afs.collection('products').ref.where('brand', '==', company.uniqueName).get().then((brandOfProducts => {
      if (!brandOfProducts.empty) {
        brandOfProducts.forEach(data => {
          this.companyProducts.next(data.data());
        })
      }
      else {
        this.companyProducts.next({});
      }
    }))
    this.company.next(company);
    // this.afm.list('brand/' + company.uniqueName + '/products').$ref.on('value', snap => {
    //   if (snap.exists()){
    //     this.companyProducts.next({ ...snap.val() });
    //     console.log(this.companyProducts);
    //   }
    //   else
    //     this.companyProducts.next({});
    // });
  }
  companyId(brand) {
    this.subs.add(this.afs.collection('brands', ref => ref.where('uniqueName', '==', brand.uniqueName)).snapshotChanges().pipe(map(data => {
      return data.map(actions => {
        return {
          id: actions.payload.doc.id
        }
      })

    })).subscribe(data => {

      this.companyBrand.next(data[0]);
    }));

  }
  getcompanyId() {
    return this.companyBrand.asObservable();
  }

  getCompany() {
    return this.company.asObservable();
  }

  getCompanyProducts() {
    return this.companyProducts.asObservable();
  }
  ngOnDestroy() {

    this.subs.unsubscribe();
  }

}
