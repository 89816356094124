import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { fuseAnimations } from '../../../core/animations';
import { MatPaginator, MatSort } from '@angular/material';
import { FuseUtils } from '../../../core/fuseUtils';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { MatTableDataSource } from '@angular/material';

import { Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { ApiService } from '../../../core/services/api.service';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { ExcelService } from './excel.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
  animations: fuseAnimations
})

export class CustomerListComponent implements OnInit, OnDestroy {
  displayedColumns = ['name', 'phone', 'email', 'count', 'city'];
  subscription: Subscription;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filter') filter: ElementRef;
  @ViewChild('TABLE') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  number: any;
  category: Array<any>;
  filteredItems: Array<any>;
  dataSource;
  input: any;
  data = [];
  constructor(
    private afm: AngularFireDatabase,
    private router: Router,
    private api: ApiService,
    private chooseCompany: ChooseCompanyService,
    private dialog: MatDialog,
    private excelService: ExcelService) {
    var deneme = parseInt('(5123)-213-3125'.replace(/[^0-9\.]/g, ''), 10);
    this.subscription = chooseCompany.getCompany().subscribe(data => {
      this.subscription = api.sqlPost('corporate/getClientList', { uniqueName: data.uniqueName }).map(res => res.json()).subscribe(res => {
        this.number = res.length;


        res.forEach(element => {

          if (element.phoneNumber === null) {

            element.phoneNumber = '-';
          }
          else {
            element.phoneNumber = parseInt(element.phoneNumber.replace(/[^0-9\.]/g, ''), 10).toString();
          }


          element.firstname = element.firstname.trim().toLowerCase();
          element.firstname = element.firstname.charAt(0).toUpperCase() + element.firstname.slice(1);
          element.lastname = element.lastname.trim().toLowerCase();
          element.lastname = element.lastname.charAt(0).toUpperCase() + element.lastname.slice(1);
        });

        this.category = res;
        res.filter(a => {
          console.log(a.phoneNumber, 'phone-number2')

          this.data.push({
            "Ad Soyad": a.firstname + ' ' + a.lastname,
            "Telefon": a.phoneNumber || '-',
            "E-posta": a.email || '-',
            "Paylaşım Sayısı": a.count || '-',
            "Şehir": a.city || '-'
          })
        })
        this.dataSource = new MatTableDataSource(this.category);
      });
    });

  }

  ngOnInit() {
    if (this.number > 0) {
      this.paginator._intl.itemsPerPageLabel = 'Sayfa başına gösterilcek miktar';

    }

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  navigate(uniqueName) {
    this.router.navigate(['/set/categories'], { queryParams: { name: uniqueName } });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.data, 'müsteri-listesi');
  }
}


