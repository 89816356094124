
import { NgModule } from '@angular/core';
import { TopTenHashtagsComponent } from './top-ten-hashtags.component';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../../_guards/auth.guard'; 
import { RoleGuard } from '../../../_guards/role.guard'; 
import { FuseWidgetModule } from '../../../core/components/widget/widget.module';
import { MatSortModule } from '../../../../../node_modules/@angular/material';
import { PopUpListComponent } from './pop-up-list/pop-up-list.component';

const routes = [
  {
      path     : 'top-ten-hashtags',
      component: TopTenHashtagsComponent,
      canActivate: [AuthGuard,RoleGuard]
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    FuseWidgetModule,
    MatSortModule
  ],
  declarations: [
    TopTenHashtagsComponent,
    PopUpListComponent
  ],
  entryComponents: [PopUpListComponent],
  providers: [
    AuthGuard,
    RoleGuard
  ]
})

export class TopTenHashtagsModule { }
