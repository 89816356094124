
import { NgModule } from '@angular/core';
import { AuthGuard } from '../../../_guards/auth.guard';
import { RoleGuard } from '../../../_guards/role.guard';
import { ApiService } from '../../../core/services/api.service';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CustomerListComponent } from './customer-list.component';
import { ExcelService } from './excel.service';

const routes = [
    {
        path     : 'customer-list',
        component: CustomerListComponent,
        canActivate: [AuthGuard,RoleGuard]
    }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxChartsModule
  ],
  declarations: [
    CustomerListComponent
  ],
  providers: [
      ApiService,
      AuthGuard,
      ExcelService,
      RoleGuard
  ]
})
export class CustomerListModule { }