import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../../_guards/auth.guard'
import { RoleGuard } from  '../../../_guards/role.guard';
import { PhotoUploadComponent } from '../profile-settings/photo-upload/photo-upload.component';
import { BannerUploadComponent2 } from '../profile-settings/banner-upload2/banner-upload2.component';
import { BannerUploadComponent3 } from '../profile-settings/banner-upload3/banner-upload3';
import { BannerUploadComponent } from './banner-upload.component';
import { BannerUpload1Component } from '../profile-settings/banner-upload1/banner-upload1.component';
import { DialogComponent } from './dialog/dialog.component';
import { Dialog2Component } from './dialog2/dialog2.component';




const routes = [
  {
      path     : 'banner-upload',
      component: BannerUploadComponent,
      canActivate: [AuthGuard,RoleGuard]
  }
];

@NgModule({
  imports     : [
      SharedModule,
      RouterModule.forChild(routes)
  ],
  declarations: [BannerUploadComponent, BannerUpload1Component,
    BannerUploadComponent2,
    BannerUploadComponent3,
DialogComponent,Dialog2Component],
  entryComponents: [DialogComponent,Dialog2Component],
  providers: [
    AuthGuard,
    RoleGuard
  ]

})
export class BannerUploadModule { }
