import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ImageCropperComponent, CropperSettings } from 'ng2-img-cropper';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-image-crop2',
  templateUrl: './image-crop2.component.html',
  styleUrls: ['./image-crop2.component.css']
})
export class ImageCropComponent2 implements OnInit {
  file: any = {};
  croppedImage = new Subject<any>();
  cropperSettings: CropperSettings;


  @ViewChild('cropper', undefined) cropper: ImageCropperComponent;
  constructor(
    public dialogRef: MatDialogRef<ImageCropComponent2>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.cropperSettings = data.cropperSettings;
    this.initCrop(data.image);
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 460;
    this.cropperSettings.height = 60;
    this.cropperSettings.croppedWidth = 460;
    this.cropperSettings.croppedHeight = 60;
    this.cropperSettings.canvasWidth = 460;
    this.cropperSettings.canvasHeight = 60;
    this.cropperSettings.noFileInput=true;

    // this.data = {};

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }
  saveCroppedImage() {
    var image = "" + this.file.image;
    this.croppedImage.next(image);

  }
  closeDialog(){
    this.dialogRef.close();
  }
  initCrop(file) {
    var image: any = new Image();
    var myReader: FileReader = new FileReader();
    var that = this;
    myReader.onloadend = function (loadEvent: any) {
      image.src = loadEvent.target.result;
      that.cropper.setImage(image);
    };
    myReader.readAsDataURL(file);
  }
  getCroppedImage(){
    return this.croppedImage.asObservable();
  }

}
