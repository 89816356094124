import { NgModule } from '@angular/core';
import { AnswerTemplatesComponent } from './answer-templates.component';
import { AuthGuard } from '../../../_guards/auth.guard';
import { RoleGuard } from '../../../_guards/role.guard';
import { ApiService } from '../../../core/services/api.service';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DialogComponent } from './dialog/dialog.component';

const routes = [
    {
        path     : 'answer-templates',
        component: AnswerTemplatesComponent,
        canActivate: [AuthGuard,RoleGuard]
    }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxChartsModule
  ],
  declarations: [
    AnswerTemplatesComponent,
    DialogComponent
  ],
  providers: [
      ApiService,
      AuthGuard,
      RoleGuard
  ],
  entryComponents: [DialogComponent]
})
export class AnswerTemplatesModule { }
