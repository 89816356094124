
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { FuseUtils } from '../../../core/fuseUtils';
import { fuseAnimations } from '../../../core/animations';
import { DialogComponent } from './dialog/dialog.component';
import { ApiService } from '../../../core/services/api.service';

import { environment } from '../../../../environments/environment';
import { AngularFirestore } from 'angularfire2/firestore';
import { UserService } from '../../../core/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-answer-templates',
  templateUrl: './answer-templates.component.html',
  styleUrls: ['./answer-templates.component.scss'],
  animations: fuseAnimations
})
export class AnswerTemplatesComponent implements OnInit, OnDestroy {

  form: FormGroup;
  formErrors: any;

  company: any;
  templates = [];

  today: any;
  dd: any;
  mm: any;
  yyyy: any;
  twoDaysAgo: any;
  editedDay: any;
  editedMonth: any;
  editedYear: any;
  editedDate: any;
  sendNotifi: boolean = false;
  subs = new Subscription();
  subject: any;
  post: any;
  user: any;

  constructor(
    private formBuilder: FormBuilder,
    private afm: AngularFireDatabase,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private chooseCompany: ChooseCompanyService,
    private userService: UserService,
    private api: ApiService,
  ) {
    this.formErrors = {
      company: {},
      main: {},
      password: {},
      currentPassword: {},
      issue: {},
      city: {},
      state: {},
      postalCode: {}
    };


    //get your offset to wait value
    // var timetarget = this.forcePageReloadGetTargetTime(13, 40).getTime();
    // var timenow = new Date().getTime();
    // var offsetmilliseconds = timetarget - timenow;


    //if it isn't notification yet, set a timeout.
    // if (offsetmilliseconds >= 0) {
    //   setTimeout(this.arrangeDate, offsetmilliseconds);
    //   console.log("Ne kadar kaldı");
    //   console.log(offsetmilliseconds);
    // }
    chooseCompany.getCompany().subscribe(company => {
      this.company = company;

      if (this.company.uniqueName) {
        this.subs.add(this.afs.collection('corporateTemplates', ref => ref.where('brand', '==', this.company.uniqueName)).valueChanges().subscribe(data => {
          if (data[0]) {
            this.templates = data[0]['templates'];
          }
        }));
      }


    });

    // this.twoDaysAgo = '05.09.2018';
    // this.api.sqlPost('corporate/revizedScore', {
    //   uniqueName: this.company.uniqueName,
    //   startDate: this.twoDaysAgo 
    // }).map(res => res.json()).subscribe(graphData => {

    //   console.log(graphData);

    //   this.subject = graphData[0].innerText;
    //   this.user = graphData[0].userKey;
    //   this.post = graphData[0].key;


    // });


    //   this.afm.object('users').$ref.orderByChild('username').equalTo('fatihdurukan').once('value', snap => {
    //     if (snap.val()) {
    //         snap.forEach(item => {
    //             const message = {
    //                 // body: '<span><strong>' + this.user.name + '</strong> gönderine "' + commentText + '" cevabını yazdı. Değerlendir!</span>',
    //                 // innerBody: this.user.name + ' gönderine "' + commentText + '" cevabını yazdı. Değerlendir!',
    //                 body: '<span><strong>' + this.user.name + '</strong> gönderine cevap yazdı.Cevabını gör, yeniden değerlendir! </span>',
    //                 innerBody: this.user.name + 'cevabına 2 gündür cevap vermedin. ',
    //                 title: 'Yorum',
    //                 click_action: environment.domainName + "post/" + this.post,
    //                 action: "post/" + this.post,
    //                 postText: this.subject,
    //                 postUser: 'Fatih Durukan',
    //                 image: (this.company.profileImage || 'no-image.png'),
    //                 isUnseen: true,
    //                 modifyDate: Date.now()
    //             };
    //             this.pushNotification(message, item.key);
    //             return false;
    //         });
    //     }
    // });




  }

  ngOnInit() {

  }
  ngOnDestroy() {
    this.subs.unsubscribe();

  }

  pushNotification(message, userId) {
    this.afs.collection('users').doc(userId).collection('notifications').add(message);
    // this.afm.object("users/" + userId + "/notifications").$ref.once("value", snap => {
    //     this.afm.object("users/" + userId + "/notifications/" + snap.numChildren()).set(message);
    // });
  }
  // arrangeDate() {

  //   this.today = new Date();
  //    //get two days ago
  //    this.twoDaysAgo = this.today - 1000 * 60 * 60 * 24 * 2;   // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
  //    this.twoDaysAgo = new Date(this.twoDaysAgo);
  //    console.log("Editlenmiş Gün");
  //    console.log(this.twoDaysAgo);​
  //    this.twoDaysAgo = this.dateFormat(this.twoDaysAgo);
  // //  console.log(this.twoDaysAgo);
  // }
  //   dateFormat(data){
  //     var dd = data.getDate();
  //     var mm = data.getMonth() + 1; //January is 0!
  //     var yyyy = data.getFullYear();

  //     if (dd < 10) {
  //      dd = '0' + dd;
  //    }
  //    if (mm < 10) {
  //      mm = '0' + mm;
  //    }

  //    data = dd + '.' + mm + '.' + yyyy;
  //    return data;
  //  }






  openModel(data?, type?, index?) {
    this.dialog.open(DialogComponent, {
      width: '700px',
      data: data ? { type: type, ...data, key: index } : null
    });
  }



  // forcePageReloadGetTargetTime(hour, minute) {
  //   var t = new Date();
  //   t.setHours(hour);
  //   t.setMinutes(minute);
  //   t.setSeconds(0);
  //   t.setMilliseconds(0);
  //   return t;
  // }



}

