import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';
import { MatDialog } from '@angular/material';
import { AlertComponent } from '../directives/alert/alert.component';

@Injectable()
export class AlertService {
    private subject = new Subject<any>();

    constructor(public dialog: MatDialog) {
        // clear alert message on route change
        // router.events.subscribe(event => {
        //     if (event instanceof NavigationStart) {
        //         if (this.keepAfterNavigationChange) {
        //             // only keep for a single location change
        //             this.keepAfterNavigationChange = false;
        //         } else {
        //             // clear alert
        //             this.subject.next();
        //         }
        //     }
        // });
    }

    success(message: string, keepAfterNavigationChange = false) {
        this.dialog.open(AlertComponent, {
            width: '290px',
            data: { type: 'success', text: message }
        });
    }

    error(message: string, keepAfterNavigationChange = false) {
        this.dialog.open(AlertComponent, {
            width: '270px',
            data: { type: 'error', text: message }
        });
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
