import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { ApiService } from '../../../core/services/api.service';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { fuseAnimations } from '../../../core/animations';
import { MatTabChangeEvent } from '@angular/material';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';

@Component({
  selector: 'app-rivals-crosscheck',
  templateUrl: './rivals-crosscheck.component.html',
  styleUrls: ['./rivals-crosscheck.component.scss'],
  animations: fuseAnimations
})
export class RivalsCrosscheckComponent implements OnInit, OnDestroy {
  company: any;
  hashInfo: any;
  rivals = [];
  rivalHashInfo = [];
  graphicResults = [];
  selectedCompany: any;
  subscription: Subscription;
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  legendTitle = "test";
  showXAxisLabel = true;
  showYAxisLabel = true;
  yAxisLabel = 'Adet';
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  customColors = [{
    name: 'Sikayet',
    value: '#b0b3b7'
  },
  {
    name: 'Tavsiye',
    value: '#ef3b2b'
  },
  {
    name: 'Öneri',
    value: '#53a6cc'
  },
  {
    name: 'Memnuniyet',
    value: '#4a2a72'
  }
  ];
  hashtags = [
    {
      name: 'Şikayet',
      uniqueName: 'şikayet',
    },
    {
      name: 'Öneri',
      uniqueName: 'öneri',
    },
    {
      name: 'Memnuniyet',
      uniqueName: 'memnuniyet',
    },
    {
      name: 'Tavsiye',
      uniqueName: 'tavsiye',
    },
    {
      name: 'Diğer',
      uniqueName: 'diğer',
    }
  ];
  view: any[] = [800, 400];
  brand = [];
  desserts = [];
  dataSource: MyDataSource;
  displayedColumns = ['name', 'firstColumn', 'secondColumn', 'thirdColumn', 'forthColumn'];
  totalBrand: any;

  constructor(private api: ApiService, private chooseCompany: ChooseCompanyService, private cd: ChangeDetectorRef) {
    let list = {
      "Marka Sıralama": [],
      "Paylaşım Sayısı": [],
      "Yüzdeler": []
    }
    this.subscription = chooseCompany.getCompany().subscribe(data => {
      console.log(data)

      this.company = [];
      this.rivals = [];

      this.company = data;
      this.rivals.push(data);
      if (data.uniqueName) {
        console.log(data.uniqueName)
        data.rivalBrand.forEach(a => {
          this.rivals.push(a)
        })
        api.sqlPost('corporate/ranking', {
          hashtag: 'şikayet'
        }).map(res => res.json()).toPromise().then(snap => {

          this.rivals.forEach(data => {
            snap.filter(element => {
              if (element.uniqueName == data.uniqueName) {
                if (element.uniqueName.search("ve") > -1) {
                  element.uniqueName = element.uniqueName.replace('ve', '&').toUpperCase();
                }
                this.desserts.push({
                  company: data.name,
                  markaSiralama: element.row,
                  toplampaylasimSayisi: element.postCount,
                  yuzde: (element.hashTotal / element.postCount) * 100,
                  paylasimSayisi: element.hashTotal
                })
              }
            })
            this.desserts = this.desserts.sort((a, b) => a.markaSiralama - b.markaSiralama)
            this.dataSource = new MyDataSource(this.desserts);

          })
        })
      }

    });

    this.desserts = [];
    this.graphicResults = [];



    // })



    //   list["Marka Sıralama"] = [];
    //   list["Paylaşım Sayısı"] = [];
    //   list["Yüzdeler"] = [];

    //   list["Marka Sıralama"].push({ name: snap[0].hashtag , value:snap[0].row},{name:snap[1].hashtag , value:snap[1].row},{ name:snap[2].hashtag , value:snap[2].row },{ name:snap[3].hashtag , value:snap[3].row });
    //   list["Paylaşım Sayısı"].push({ name: snap[0].hashtag , value:snap[0].hashTotal},{name:snap[1].hashtag , value:snap[1].hashTotal},{ name:snap[2].hashtag , value:snap[2].hashTotal }, { name:snap[3].hashtag , value:snap[3].hashTotal });
    //   list["Yüzdeler"].push({ name: snap[0].hashtag , value:snap[0].percent.toFixed(2)},{name:snap[1].hashtag , value:snap[1].percent.toFixed(2)},{ name:snap[2].hashtag , value:snap[2].percent.toFixed(2) },{ name:snap[3].hashtag , value: snap[3].percent.toFixed(2) });
    //   this.graphicResults = [];
    //   Object.entries(list).forEach(values => {
    //   this.graphicResults.push({
    //     name: values[0],
    //     series: values[1]
    //   });
    // });



  }

  generateTabs() {
    this.desserts = [];
    this.api.sqlPost('corporate/ranking', {
      hashtag: this.selectedCompany
    })
      .map(res => res.json()).toPromise().then(snap => {
        this.rivals.forEach(data => {


          snap.filter(element => {

            if (element.uniqueName == data.uniqueName) {
              this.desserts.push({
                company: data.name,
                markaSiralama: element.row,
                toplampaylasimSayisi: element.postCount,
                yuzde: (element.hashTotal / element.postCount) * 100,
                paylasimSayisi: element.hashTotal
              })
            }
          })
          this.desserts = this.desserts.sort((a, b) => a.markaSiralama - b.markaSiralama)
          this.dataSource = new MyDataSource(this.desserts);
        })
      })
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent) => {
    this.desserts = [];
    this.dataSource = new MyDataSource(this.desserts);
    this.selectedCompany = tabChangeEvent.tab.textLabel.toLowerCase();
    this.generateTabs();
  }

  ngOnInit() {
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
export class MyDataSource extends DataSource<any> {
  constructor(
    private data: any) {
    super();
  }
  _filterChange = new BehaviorSubject('');
  _filteredDataChange = new BehaviorSubject('');

  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.data,
      this._filterChange
    ];
    return Observable.merge(...displayDataChanges).map(() => {
      let data = this.data.slice();

      this.filteredData = [...data];

      data = this.sortData(data);

      // Grab the page's slice of data.
      const startIndex = 0 * 5;
      return data.splice(startIndex);
    });
  }

  sortData(data): any[] {


    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';



      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      // return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }

  disconnect() { }
}