import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hashtag'
})

export class HighlightSearch implements PipeTransform {

    transform(value: any, args: any): any {
        var re = new RegExp(args, 'g'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
        return value.replace(re, "<a>" + args + "</a>");
    }
}