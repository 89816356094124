import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FuseConfigService } from '../../core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { ChooseCompanyService } from '../../core/services/choose-company.service';
import { UserService } from '../../core/services/user.service';
import { fuseAnimations } from '../../core/animations';

import { PhotoUploadComponent } from '../content/profile-settings/photo-upload/photo-upload.component';
import { Subscription } from '../../../../node_modules/rxjs';
import { AngularFirestore } from 'angularfire2/firestore';
import { Http } from '@angular/http';



@Component({
    selector: 'fuse-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    animations: fuseAnimations
})

export class FuseToolbarComponent implements OnInit {
    userStatusOptions: any[];
    languages: any;
    selectedLanguage: any;
    showLoadingBar: boolean;
    horizontalNav: boolean;
    userId = localStorage.getItem('currentUserId');
    sub: any;
    user: any;
    panelOpenState: boolean;
    allInterestEntity = [];
    company: any;
    companyFromFirebase: any;
    ipAddress: any;

    private subscription: Subscription = new Subscription();
    companyProfileImage: any;



    constructor(
        private router: Router,
        private fuseConfig: FuseConfigService,
        private afs: AngularFirestore,
        private _http: Http,
        private translate: TranslateService,
        private afm: AngularFireDatabase,
        private userService: UserService,
        private chooseCompany: ChooseCompanyService


    ) {


        // userService.setUser(this.userId);
        if (this.userId) {
            this.sub = userService.getUser().subscribe(user => {
                if (user.companies) {
                    this.user = user;

                }
            });
        }



        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                'id': 'en',
                'title': 'English',
                'flag': 'us'
            },
            {
                'id': 'tr',
                'title': 'Turkish',
                'flag': 'tr'
            }
        ];

        this.selectedLanguage = this.languages[0];

        router.events.subscribe(
            (event) => {
                if (event instanceof NavigationStart) {
                    this.showLoadingBar = true;
                }
                if (event instanceof NavigationEnd) {
                    this.showLoadingBar = false;
                }
            });

        this.fuseConfig.onSettingsChanged.subscribe((settings) => {
            this.horizontalNav = settings.layout.navigation === 'top';
        });

        chooseCompany.getCompany().subscribe(data => {
            this.company = data;

            if (this.company.uniqueName) {
                this.company = data;

                this.getCompanyImage();


            }

            // console.log("GELEN COMPANY");
            //  console.log(this.company);


            this.allInterestEntity = [];
            chooseCompany.getCompanyProducts().subscribe(arr => {
                this.allInterestEntity = Object.values(arr);
            });
        });



    }

    ngOnInit() {
        this.getIpAdress();

    }

    choose(company, index) {
        this.chooseCompany.setCompany(company, index);



    }
    getIpAdress() {
        this.subscription.add(this._http.get("https://api.bigadvice.com/helper/ip")
            .map(data => { this.ipAddress = data.json() }).subscribe());
    }


    navigate() {
        this.afs.collection('logs').add({
            user: this.company.uniqueName,
            date: new Date(),
            type: 'logout',
            ipAddress: this.ipAddress,
            url: 'analytics.bigadvice.com'
        });

        localStorage.clear();
        this.router.navigate(['/auth']);
    }

    search(value) {
        // Do your search here...
    }

    setLanguage(lang) {
        // Set the selected language for toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this.translate.use(lang.id);
    }

    getCompanyImage() {
        this.afs.collection('brands').ref.where('uniqueName', '==', this.company.uniqueName).get().then((data => {
            this.companyProfileImage = data.docs[0].data().profileImage;
        }));
    }

    ngOnDestroy() {

    }

}
