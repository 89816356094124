import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';

// const URL = "http://localhost:8086/";
// const URL = "http://bigadvice.doksan6.com/";
// const URL = "https://api.bigadvice.com/";
// // const URL = "http://api2.bigadvice.com/";
const URL = "http://testapi.bigadvice.com/"
// const URL = "https://api.bigadvice.com/api-test/";

@Injectable()
export class ApiService {

  constructor(private http: Http) {

  }

  sqlGet(path) {

    return this.http.get(URL + path, { headers: this.jwt() }).map(res => res.json());
  }

  sqlPost(path, data) {
    return this.http.post(URL + path, JSON.stringify(data), { headers: this.jwt() });
  }

  sqlUpdate(path, data) {
    this.http.put(URL + path, JSON.stringify(data)).map(res => res.json());
  }

  sqlDelete(path, data) {
    return this.http.delete(URL + path, JSON.stringify(data)).map(res => res.json());
  }

  jwt() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return headers;
  }
}
