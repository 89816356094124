import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HashtagDemographicComponent } from './hashtag-demographic.component';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AuthGuard } from '../../../_guards/auth.guard';

const routes = [
  {
      path     : 'hashtag-demographic',
      component: HashtagDemographicComponent,
      canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    NgxChartsModule
  ],
  declarations: [
    HashtagDemographicComponent
  ],
  providers: [
    AuthGuard
  ]
})
export class HashtagDemographicModule { }
