﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as firebase from 'firebase';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private afm: AngularFireDatabase) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (localStorage.getItem('currentUserId')) {
            return true;
        }


        this.router.navigate(['/auth']);
        return false;
    }
}