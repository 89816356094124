import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChooseCompanyService } from '../../../../core/services/choose-company.service';
import { FuseUtils } from '../../../../core/fuseUtils';
import { AngularFirestore } from 'angularfire2/firestore';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  form: FormGroup;
  formErrors: any;
  companyIndex = 0;
  company: any;
  temp: any;
  templates = [];
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private afm: AngularFireDatabase,
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private chooseCompany: ChooseCompanyService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.formErrors = {
      company: {},
      main: {},
      password: {},
      currentPassword: {},
      issue: {},
      city: {},
      state: {},
      postalCode: {}
    };
    chooseCompany.activeCompanyIndex.subscribe(index => this.companyIndex = index);
    chooseCompany.getCompany().subscribe(company => {
      this.company = company;
      this.templates = company.templates || [];

    });

    this.temp = data


  }

  close() {
    this.dialogRef.close()
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      company: [
        {
          value: 'Google',
          disabled: true
        }, Validators.required
      ],
      main: [this.data ? this.data.head : "", Validators.required],
      issue: [this.data ? this.data.text : "", Validators.required]
    });

    this.form.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    });
  }

  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.formErrors[field] = {};

      // Get the control
      const control = this.form.get(field);

      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }


  send() {
    let issue = this.form.get('issue').value; // to get value in input tag
    let main = this.form.get('main').value; // to get value in input tag

    let message = {
      text: issue,
      head: main
    }

    if (this.data) {

      this.afs.collection('corporateTemplates').ref.where('brand', '==', this.company.uniqueName).get().then((tempId) => {
        let temp = tempId.docs[0].data().templates;
        temp[this.data.key] = message;

        this.afs.collection('corporateTemplates').doc(tempId.docs[0].id).update({ templates: temp });


      })
    } else {
      const tempArray = [];
      let tempId;
      this.afs.collection('corporateTemplates').ref.where('brand', '==', this.company.uniqueName).get().then((data => {

        if (!data.empty) {
          tempId = data.docs[0].id;
          if (data.docs[0].data().templates) {
            data.docs[0].data()['templates'].forEach(temp => {
              tempArray.push(temp);
            })
            tempArray.push(message);
            this.afs.collection('corporateTemplates').doc(tempId).update({ brand: this.company.uniqueName, templates: tempArray });
          }
        }
        else {
          this.afs.collection('corporateTemplates').add({ brand: this.company.uniqueName, templates: [message] });
        }

      }))


    }
    this.form.reset();
    this.dialogRef.close();
  }


  deleteTemp() {
    console.log(this.data.key);

    this.afs.collection('corporateTemplates').ref.where('brand', '==', this.company.uniqueName).get().then((data => {
      let template = data.docs[0].data().templates.filter((temp, index) => index !== this.data.key);
      this.afs.collection('corporateTemplates').doc(data.docs[0].id).set({ brand: this.company.uniqueName, templates: template })
    }));
    this.dialogRef.close();
  }


}
