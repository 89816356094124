import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from '../../../../core/services/api.service';
import { ChooseCompanyService } from '../../../../core/services/choose-company.service';
import * as shape from 'd3-shape';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit,OnDestroy {
  color = 'primary';
  mode = 'indeterminate';
  subscription:Subscription;
  company: any;
  tagArray = [];
  show = false;
  widget5 = {
    xAxis: false,
    showDataLabel:true,
    yAxis: true,
    gradient: true,
    legend: true,
    showXAxisLabel: false,
    xAxisLabel: 'Days',
    showYAxisLabel: false,
    yAxisLabel: 'Isues',
    scheme: {
      // domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
      domain: ['#f28a74', '#a17cb6']
    },
    onSelect: (ev) => {
      console.log(ev);
    },
    supporting: {
      curve: shape.curveBasis
    }
  };

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private chooseCompany: ChooseCompanyService) {
      
    this.subscription = chooseCompany.getCompany().subscribe(company => {
      Object.assign(this, { company });
      this.subscription = this.api.sqlPost('corporate/reportTag', { uniqueName: company.uniqueName }).map(res => res.json()).subscribe(tags => {
        if(tags.length != 0){
          this.show = true;
        }
        this.tagArray = tags.map(data => {return {name: data.name.toLowerCase(),value:data.value};});
        
      });
    });
  }

  ngOnInit() {

  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

}
