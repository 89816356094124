import {
  Injectable
} from '@angular/core';

import { ImageResult, Options } from './interfaces';
import { createImage, resizeImage } from './utils';

@Injectable()
export class ImageToDataUrlService {

  public readFiles(files:File[], options: Options, cb: (imageResult: ImageResult) => void) {
    for (let file of files) {
      const result: ImageResult = {
        url: URL.createObjectURL(file)
      };
      let ext: string = file.name.split('.').pop();
      ext = ext && ext.toLowerCase();
      if (ext && options.allowedExtensions
        && options.allowedExtensions.length
        && options.allowedExtensions.indexOf(ext) === -1) {
        result.error = new Error('Extension Not Allowed');
        cb(result);
      } else {
        this.fileToDataURL(file, result).then(r => this.resize(r,options))
          .catch(e => {
            result.error = e;
            return result;
          }).then(r => {
            cb(result);
          });
      }
    }
  }
  public readBase64(files: string[], options: Options, cb: (imageResult: ImageResult) => void) {
    for (let file of files) {
      const result: ImageResult = {
        url: file
      };
      // let ext: string = file.name.split('.').pop();
      // ext = ext && ext.toLowerCase();
      // if (ext && options.allowedExtensions
      //   && options.allowedExtensions.length
      //   && options.allowedExtensions.indexOf(ext) === -1) {
      //   result.error = new Error('Extension Not Allowed');
      //   cb(result);
      // } else {
      this.resize(result, options)
        .catch(e => {
          result.error = e;
          return result;
        }).then(r => {
          cb(result);
        });
      // }
    }
  }

  private resize(result: any, options: Options): Promise<ImageResult> {
    if (!options.resize) return Promise.resolve(result);
    return createImage(result.url).then(image => {
      const dataUrl = resizeImage(image, options.resize);
      result.resized = {
        dataURL: dataUrl,
        type: dataUrl.match(/:(.+\/.+;)/)[1]
      };
      return result;
    });
  }

  private fileToDataURL(file: File, result: ImageResult): Promise<ImageResult> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        result.dataURL = reader.result;
        resolve(result);
      };
      reader.readAsDataURL(file);
    });
  }
}


