
import { MatDialog, MAT_DIALOG_DATA, MatTabChangeEvent } from '@angular/material';
import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fuseAnimations } from '../../../core/animations';
import * as shape from 'd3-shape';
import { ApiService } from '../../../core/services/api.service';
import { Graphic } from '../../../core/interfaces/graphic.interface';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { NativeDateAdapter, DateAdapter } from "@angular/material";
import { locale as english } from '../../content/sample/i18n/en';
import { locale as turkish } from '../../content/sample/i18n/tr';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { AngularFireDatabase } from '../../../../../node_modules/angularfire2/database-deprecated';
import { Subscription } from 'rxjs';
import { AngularFirestore } from 'angularfire2/firestore';



export class TurkishDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
      const str = value.split('.');
      if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
        return null;
      }
      return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}

@Component({
  selector: 'app-answer-details',
  templateUrl: './answer-details.component.html',
  styleUrls: ['./answer-details.component.scss'],
  animations: fuseAnimations,
  providers: [{ provide: DateAdapter, useClass: TurkishDateAdapter }],
})
export class AnswerDetailsComponent implements OnInit, Graphic, OnDestroy {
  dougShow = true;
  subscription: Subscription
  view = [850, 400];
  scheme = { domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72'] };
  range = new FormControl('M');
  timeline = true;
  revize: any;
  toppost: any;
  oran: any;
  afterReplyRate = [];
  ranges = {
    'Y': {
      name: 'Yıl',
      period: 1,
      param: 'year',
      format: (data) => { return data.year }
    },
    'Q': {
      name: 'Çeyrek',
      period: 2,
      param: 'quarter',
      format: (data) => { return `${data["year"]}-${data["quarter"] * 3}` }
    },
    'M': {
      name: 'Ay',
      period: 3,
      param: 'month',
      format: (data) => { return `${data["year"]}-${data["month"]}` }
    },
    'D': {
      name: 'Gün',
      period: 4,
      param: 'day',
      format: (data) => { return `${data["year"]}-${data["month"]}-${data["day"]}` }
    }
  };
  chart = [];
  currentRange = "D";
  title = "Score Change"
  reportModel: any = {};
  feedback: any;
  feedback2: any;
  deneme = true;
  revizeInf = true;
  valueToplam = 0;
  ilkToplam = 0;
  doughnChart = [];
  singleValue = false;
  cardHour = 0;
  cardMinute = 0;
  yedekTarih: any;

  widget5 = {
    timeline: false,
    currentRange: 'TW',
    xAxis: true,
    yAxis: true,
    gradient: false,
    legend: false,
    showXAxisLabel: false,
    xAxisLabel: 'Days',
    showYAxisLabel: false,
    yAxisLabel: 'Isues',
    scheme: {
      domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
    },
    onSelect: (ev) => {
      console.log(ev);
    },
    supporting: {
      currentRange: '',
      xAxis: false,
      yAxis: false,
      gradient: false,
      legend: false,
      showXAxisLabel: false,
      xAxisLabel: 'Days',
      showYAxisLabel: false,
      yAxisLabel: 'Isues',
      scheme: {
        domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
      },
      curve: shape.curveBasis
    }
  };
  widget = {
    currentRange: this.currentRange,
    xAxis: true,
    yAxis: true,
    gradient: false,
    legend: false,
    showXAxisLabel: false,
    xAxisLabel: 'Months',
    showYAxisLabel: false,
    yAxisLabel: 'Isues',
    scheme: {
      domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
    },
    onSelect: (ev) => {
      console.log(ev);
    },
    supporting: {
      currentRange: 'D',
      xAxis: false,
      yAxis: false,
      gradient: false,
      legend: false,
      showXAxisLabel: false,
      xAxisLabel: 'Months',
      showYAxisLabel: false,
      yAxisLabel: 'Isues',
      scheme: {
        domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
      },
      curve: shape.curveBasis
    }
  };
  company: any;
  brandRate: any;
  brandKey: any;
  gecmisScore = 0;

  constructor(private translationLoader: FuseTranslationLoaderService,
    private api: ApiService,
    private chooseCompany: ChooseCompanyService,
    private cd: ChangeDetectorRef,
    private dateAdapter: DateAdapter<Date>,
    private afs: AngularFirestore,
    public dialog: MatDialog,
    private afm: AngularFireDatabase,
  ) {
    this.translationLoader.loadTranslations(english, turkish);
    this.reportModel.startDate = '2018-01-01';

    var newDate2 = new Date(new Date().getTime() + Math.abs(new Date().getTimezoneOffset() * 60000));
    this.reportModel.endDate = newDate2.toISOString().split('T')[0];

    this.subscription = chooseCompany.getCompany().subscribe(data => {
      this.company = data;


    });
    this.subscription = this.chooseCompany.getcompanyId().subscribe(data => {
      if (data.id) {

        this.afs.collection('brands').doc(data.id).collection('categories').ref.where('name', '==', 'Genel').get().then((data => {
          if (!data.empty) {
            this.brandRate = data.docs[0].data().total / data.docs[0].data().peopleCount;
          }
        }))
        this.cd.markForCheck();
        this.generateChart();
      }
      this.cd.markForCheck();



    })

    this.dateAdapter.setLocale('tr');
  }

  generateColumn = (data) => {


    if (this.currentRange == 'M') {
      if (data.month == 1) data.month = 'Ocak'
      if (data.month == 2) data.month = 'Şubat'
      if (data.month == 3) data.month = 'Mart'
      if (data.month == 4) data.month = 'Nisan'
      if (data.month == 5) data.month = 'Mayıs'
      if (data.month == 6) data.month = 'Haziran'
      if (data.month == 7) data.month = 'Temmuz'
      if (data.month == 8) data.month = 'Ağustos'
      if (data.month == 9) data.month = 'Eylül'
      if (data.month == 10) data.month = 'Ekim'
      if (data.month == 11) data.month = 'Kasım'
      if (data.month == 12) data.month = 'Aralık'
      return {
        'name': data.month + '-' + data.year,
        'value': data.average.toFixed(2)
      }
    }
    else if (this.currentRange == 'D') {
      return {
        'name': data.day + '/' + data.month + '/' + data.year,
        'value': data.average.toFixed(2)
      }
    }
    else if (this.currentRange == 'Q') {
      return {
        'name': data.quarter + '.Çeyrek /' + data.year,
        'value': data.average.toFixed(2)
      }
    }
    else if (this.currentRange == 'Y') {
      return {
        'name': data.year,
        'value': data.average.toFixed(2)
      }
    }
  }

  generateChart() {
    this.reportModel.startDate = this.formatDate(this.reportModel.startDate);
    this.reportModel.endDate = this.formatDate(this.reportModel.endDate);
    this.gecmisScore = 0;
    this.chart = [];


    this.currentRange = this.range.value;


    this.subscription = this.api.sqlPost('corporate/reportFeedbackHome', {
      uniqueName: this.company.uniqueName,
      startDate: this.reportModel.startDate,
      endDate: this.reportModel.endDate,
    }).map(res => res.json()).subscribe(feedback => {

      this.cardHour = Math.floor(feedback[0].answeredTime / 60);

      this.cardMinute = feedback[0].answeredTime - (this.cardHour * 60);

      this.feedback = feedback[0];
    });


    this.subscription = this.api.sqlPost("corporate/scoreChange", {
      uniqueName: this.company.uniqueName,
      startDate: this.reportModel.startDate,
      endDate: this.reportModel.endDate,
      period: this.ranges[this.currentRange].period
    }).map(res => {
      this.gecmisScore = 0;

      res.json();

      this.toppost = 0;
      this.revize = 0;
      res.json().forEach(element => {

        this.gecmisScore += element.average;







        this.toppost += element.total;
        this.revize += element.count;
      });
      this.gecmisScore = this.gecmisScore / res.json().length;


      this.oran = (this.revize / this.toppost);
      this.chart = [{
        'name': this.company.name,
        'series': res.json().map(column => this.generateColumn(column))
      }];
      if (this.toppost == 0) {
        this.deneme = false;
      } else {
        this.deneme = true;
      }



    }).subscribe();
  }

  onSelect(event) {


  }
  ngOnInit() {


  }

  getByDate() {

    this.reportModel.startDate = this.formatDate(this.reportModel.startDate);
    this.reportModel.endDate = this.formatDate(this.reportModel.endDate);


    this.subscription = this.chooseCompany.getCompany().subscribe(data => {

      this.subscription = this.api.sqlPost('corporate/afterReplyRate2', {
        uniqueName: data.uniqueName,
        startDate: this.reportModel.startDate,
        endDate: this.reportModel.endDate
      }).map(res => {
        // if(res.json().length == 1){
        //   this.singleValue = true;
        //   let singleList = {
        //     "Eski Puan": [],
        //     "Revize Puan": []
        //   }
        //   res.json().forEach((elem, index) => {
        //     singleList["Eski Puan"].push(elem.oldPoint.toFixed(2));
        //     singleList["Revize Puan"].push(elem.newPoint.toFixed(2));
        //   });
        //   this.afterReplyRate = [];
        // Object.entries(singleList).forEach(element => {

        //   this.afterReplyRate.push({
        //     name: element[0],
        //     value: element[1]
        //   });
        // });

        // }
        // else {
        this.singleValue = false;


        let list = {
          "Eski Puan": [],
          "Revize Puan": []
        }

        res.json().forEach((elem, index) => {

          this.ilkToplam += elem.newPoint;
          list["Eski Puan"].push({ name: elem.day + '/' + elem.month + '/' + elem.year, value: elem.oldPoint.toFixed(2) });
          list["Revize Puan"].push({ name: elem.day + '/' + elem.month + '/' + elem.year, value: elem.newPoint.toFixed(2) });

        });
        if (res.json().length == 0) {
          this.revizeInf = false;
        } else {
          this.revizeInf = true;
        }
        this.afterReplyRate = [];
        Object.entries(list).forEach(element => {
          this.afterReplyRate.push({
            name: element[0],
            series: element[1]
          });
        });
        // }
      }).subscribe();
    });
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.generateChart();
    this.generateRevize();
  }

  generateRevize() {
    this.reportModel.startDate = '2018-01-01';
    this.reportModel.startDate = this.formatDate(this.reportModel.startDate);
    this.reportModel.endDate = this.formatDate(this.reportModel.endDate);
    this.afterReplyRate = [];
    this.subscription = this.chooseCompany.getCompany().subscribe(data => {
      this.company = data;
      if (data) {


        this.subscription = this.api.sqlPost('corporate/afterReplyRate2', {
          uniqueName: data.uniqueName,
          startDate: this.reportModel.startDate,
          endDate: this.reportModel.endDate
        }).map(res => res.json()).subscribe(data => {
          let list = {
            "Eski Puan": [],
            "Revize Puan": []
          }
          data.forEach(elem => {
            this.ilkToplam += elem.newPoint;
            list["Eski Puan"].push({ name: elem.day + '/' + elem.month + '/' + elem.year, value: elem.oldPoint.toFixed(2) });
            list["Revize Puan"].push({ name: elem.day + '/' + elem.month + '/' + elem.year, value: elem.newPoint.toFixed(2) });
          });
          //   if(list["Revize Puan"].length == 1){
          //     this.singleValue = true;
          //   let singleList = {
          //     "Eski Puan": [],
          //     "Revize Puan": []
          //   }
          //   data.forEach((elem, index) => {
          //     singleList["Eski Puan"].push(elem.oldPoint.toFixed(2));
          //     singleList["Revize Puan"].push(elem.newPoint.toFixed(2));
          //   });
          //   this.afterReplyRate = [];
          // Object.entries(singleList).forEach(element => {
          //   this.afterReplyRate.push({
          //     name: element[0],
          //     value: element[1]
          //     });
          //     });
          //   }
          // else {

          if (this.ilkToplam == 0) {
            this.revizeInf = false;
          }
          else {
            this.revizeInf = true;
          }
          this.afterReplyRate = [];
          Object.entries(list).forEach(element => {
            this.afterReplyRate.push({
              name: element[0],
              series: element[1]
            });
          });
          // }
        });
      }
    });
  }
  formatDate(date) {
    if (date.length == 10) {
      return date;
    }
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}