import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import 'hammerjs';
import { SharedModule } from './core/modules/shared.module';
import { AngularFireModule } from 'angularfire2';
import { AppComponent } from './app.component';
import { FuseMainModule } from './main/main.module';
import { FuseSplashScreenService } from './core/services/splash-screen.service';
import { FuseConfigService } from './core/services/config.service';
import { FuseNavigationService } from './core/components/navigation/navigation.service';
import { FuseSampleModule } from './main/content/sample/sample.module';
import { TranslateModule } from '@ngx-translate/core';
import { HashtagDemographicModule } from './main/content/hashtag-demographic/hashtag-demographic.module';
import { FollowerDemographicModule } from './main/content/follower-demographic/follower-demographic.module';
import { PagesModule } from './main/content/pages/pages.module';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { AnswerDetailsModule } from './main/content/answer-details/answer-details.module';
import { TagsDetailsModule } from './main/content/tags-details/tags-details.module';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { PricingPageModule } from './main/content/pricing-page/pricing-page.module';
import { ProfileSettingsModule } from './main/content/profile-settings/profile-settings.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SupportModule } from './main/content/support/support.module';
import { AnswerTemplatesModule } from './main/content/answer-templates/answer-templates.module';
import { CustomerListModule } from './main/content/customer-list/customer-list.module';
import { FeedbackHomeModule } from './main/content/feedback-home/feedback-home.module';
import { BrandCriteriasModule } from './main/content/brand-criterias/brand-criterias.module';
import { UserCriteriasModule } from './main/content/user-criterias/user-criterias.module';
import { BrandCrosscheckModule } from './main/content/brand-crosscheck/brand-crosscheck.module';
import { RivalsCrosscheckModule } from './main/content/rivals-crosscheck/rivals-crosscheck.module';
import { BrandCrosscheckAllModule } from './main/content/brand-crosscheck-all/brand-crosscheck-all.module';
import { RivalsSpeedsOfReplyModule } from './main/content/rivals-speeds-of-reply/rivals-speeds-of-reply.module';
import { InteractionPostsModule } from './main/content/interaction-posts/interaction-posts.module';
import { SpeedOfReplyListModule } from './main/content/speed-of-reply-list/speed-of-reply-list.module';
import { BrandHashtagsModule } from './main/content/brand-hashtags/brand-hashtags.module';
import { TopTenHashtagsModule } from './main/content/top-ten-hashtags/top-ten-hashtags.module';
import { BestInteractionsPostsModule } from './main/content/best-interactions-posts/best-interactions-posts.module';
import { SearchByTagModule } from './main/content/search-by-tag/search-by-tag.module';
import { ImageToDataUrlService } from './core/services/image-to-data-url/image-to-data-url.service';
import { ImageCropComponent } from './core/directives/image-crop/image-crop.component';
import { ImageCropperModule } from 'ng2-img-cropper/src/imageCropperModule';
import { ExcelService } from './main/content/customer-list/excel.service';
import { AuthGuard } from './_guards/auth.guard';
import { MatFormFieldModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { ImageCropComponent2 } from './core/directives/image-crop2/image-crop2.component';
import { BannerUploadModule } from './main/content/banner-upload/banner-upload.module';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { KeywordDetailsModule } from './main/content/keyword-list/keyword-list.module';

const appRoutes: Routes = [
    {
        path: '',
        pathMatch: "full",
        redirectTo: 'feedback-home',
        canActivate: [AuthGuard]
    },
    {
        path: 'hashtag-demographic',
        loadChildren: './main/content/hashtag-demographic/hashtag-demographic.module#HashtagDemographicModule'
    },
    {
        path: 'follower-demographic',
        loadChildren: './main/content/follower-demographic/follower-demographic.module#FollowerDemographicModule'
    },
    {
        path: 'answer-details',
        loadChildren: './main/content/answer-details/answer-details.module#AnswerDetailsModule'
    },
    {
        path: 'tags-details',
        loadChildren: './main/content/tags-details/tags-details.module#TagsDetailsModule'

    },
    {
        path: 'keyword-list',
        loadChildren: './main/content/keyword-list/keyword-list.module#KeywordDetailsModule'

    },
    {
        path: 'post-reply',
        loadChildren: './main/content/post-reply/mail.module#FuseMailModule'
    },
    {
        path: 'pricing',
        loadChildren: './main/content/pricing-page/pricing-page.module#PricingPageModule'
    },
    {
        path: 'profile-settings',
        loadChildren: './main/content/profile-settings/profile-settings.module#ProfileSettingsModule'
    },
    {
        path: 'support',
        loadChildren: './main/content/support/support.module#SupportModule'
    },
    {
        path: 'answer-templates',
        loadChildren: './main/content/answer-templates/answer-templates.module#AnswerTemplatesModule'
    },
    {
        path: 'customer-list',
        loadChildren: './main/content/customer-list/customer-list.module#CustomerListModule'
    },
    {
        path: 'sample',
        loadChildren: './main/content/sample/sample.module#FuseSampleModule'
    },
    {
        path: 'brand-criterias',
        loadChildren: './main/content/brand-criterias/brand-criterias.module#BrandCriteriasModule'
    },
    {
        path: 'user-criterias',
        loadChildren: './main/content/user-criterias/user-criterias.module#UserCriteriasModule'
    },
    {
        path: 'brand-crosscheck',
        loadChildren: './main/content/brand-crosscheck/brand-crosscheck.module#BrandCrosscheckModule'
    },
    {
        path: 'rivals-crosscheck',
        loadChildren: './main/content/rivals-crosscheck/rivals-crosscheck.module#RivalsCrosscheckModule'
    },
    {
        path: 'brand-crosscheck-all',
        loadChildren: './main/content/brand-crosscheck-all/brand-crosscheck-all.module#BrandCrosscheckAllModule'
    },
    {
        path: 'banner-upload',
        loadChildren: './main/content/banner-upload/banner-upload.module#BannerUploadModule'
    },
    {
        path: 'rivals-speeds-of-reply',
        loadChildren: './main/content/rivals-speeds-of-reply/rivals-speeds-of-reply.module#RivalsSpeedsOfReplyModule'
    },
    {
        path: 'interaction-posts',
        loadChildren: './main/content/interaction-posts/interaction-posts.module#InteractionPostsModule'
    },
    {
        path: 'reply-list',
        loadChildren: './main/content/speed-of-reply-list/speed-of-reply-list.module#SpeedOfReplyListModule'
    },
    {
        path: 'brand-hashtags',
        loadChildren: './main/content/brand-hashtags/brand-hashtags.module#BrandHashtagsModule'
    },
    {
        path: 'top-ten-hashtags',
        loadChildren: './main/content/top-ten-hashtags/top-ten-hashtags.module#TopTenHashtagsModule'
    },
    {
        path: 'best-interactions-posts',
        loadChildren: './main/content/best-interactions-posts/best-interactions-posts.module#BestInteractionsPostsModule'
    },
    {
        path: 'search-by-tag',
        loadChildren: './main/content/search-by-tag/search-by-tag.module#SearchByTagModule'
    },
    {
        path: '**',
        redirectTo: '/feedback-home'
    }
];

const firebaseConfig = {
    apiKey: "AIzaSyD940A-zvTWg29dXG9YV7u7xzrUjMFxf_M",
    authDomain: "bigadvice-analytics.firebaseapp.com",
    databaseURL: "https://bigadvice-development.firebaseio.com",
    projectId: "bigadvice-development",
    storageBucket: "bigadvice-development.appspot.com",
    messagingSenderId: "939444140358"
};
const production = {
    apiKey: "AIzaSyCBulj8EZhgjzaW_RZ7nkraY9JvKcoGlRw",
    authDomain: "big-advice.firebaseapp.com",
    databaseURL: "https://big-advice.firebaseio.com",
    projectId: "big-advice",
    storageBucket: "big-advice.appspot.com",
    messagingSenderId: "150409001497"
    // apiKey: "AIzaSyCBulj8EZhgjzaW_RZ7nkraY9JvKcoGlRw",
    // authDomain: "bigadvice-analytics-33c46.firebaseapp.com",
    // databaseURL: "https://big-advice.firebaseio.com",
    // projectId: "bigadvice-analytics-33c46",
    // storageBucket: "big-advice.appspot.com",
    // messagingSenderId: "150409001497"
};

@NgModule({
    declarations: [
        AppComponent,
        ImageCropComponent,
        ImageCropComponent2

    ],
    imports: [
        BrowserModule,
        HttpModule,
        HttpClientModule,
        AnswerTemplatesModule,
        InteractionPostsModule,
        SearchByTagModule,
        BrandCrosscheckAllModule,
        TopTenHashtagsModule,
        RivalsCrosscheckModule,
        SpeedOfReplyListModule,
        BestInteractionsPostsModule,
        SupportModule,
        BrandHashtagsModule,
        ProfileSettingsModule,
        FuseSampleModule,
        RivalsSpeedsOfReplyModule,
        CustomerListModule,
        PricingPageModule,
        BrowserAnimationsModule,
        BrandCrosscheckModule,
        BrandCriteriasModule,
        RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules }),
        SharedModule,
        TranslateModule.forRoot(),
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireDatabaseModule,
        FuseMainModule,
        FeedbackHomeModule,
        AngularFirestoreModule,
        AnswerDetailsModule,
        TagsDetailsModule,
        KeywordDetailsModule,
        PagesModule,
        HashtagDemographicModule,
        FollowerDemographicModule,
        ReactiveFormsModule,
        ImageCropperModule,
        UserCriteriasModule,
        MatFormFieldModule,
        MatInputModule,
        BannerUploadModule

    ],
    providers: [
        FuseSplashScreenService,
        FuseConfigService,
        FuseNavigationService,
        AngularFireDatabase,
        AngularFireAuth,
        ImageToDataUrlService,
        ExcelService
    ],
    bootstrap: [
        AppComponent
    ],
    entryComponents: [
        ImageCropComponent,
        ImageCropComponent2


    ]
})
export class AppModule {
}
