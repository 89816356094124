import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { fuseAnimations } from '../../../core/animations';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { NativeDateAdapter, DateAdapter } from "@angular/material";
import { MatDialog } from '@angular/material';
import { ApiService } from '../../../core/services/api.service';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import { PopUpListComponent } from './pop-up-list/pop-up-list.component';
import { Subscription } from 'rxjs';


export class TurkishDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
      const str = value.split('.');
      if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
        return null;
      }
      return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}
@Component({
  selector: 'app-top-ten-hashtags',
  templateUrl: './top-ten-hashtags.component.html',
  styleUrls: ['./top-ten-hashtags.component.scss'],
  animations: fuseAnimations,
  providers: [{ provide: DateAdapter, useClass: TurkishDateAdapter }]
})
export class TopTenHashtagsComponent implements OnInit, OnDestroy {

  @Input() topEtkilesim;

  company: any;
  hashtags = [];
  displayedColumns: string[] = ['hashtag', 'count', 'postlar'];
  dataSource: MatTableDataSource<any>;
  subscription: Subscription;
  reportModel: any = {};

  selectedRow: any;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private chooseCompany: ChooseCompanyService,
    private api: ApiService,
    private dateAdapter: DateAdapter<Date>,
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog
  ) {
    this.translationLoader.loadTranslations(english, turkish);
    this.reportModel.startDate = '2018-01-01';
    var newDate2 = new Date(new Date().getTime() + Math.abs(new Date().getTimezoneOffset() * 60000));
    this.reportModel.endDate = newDate2.toISOString().split('T')[0];
    this.subscription = chooseCompany.getCompany().subscribe(data => {
      if (data.uniqueName) {
        this.hashtags = [];
        this.company = data;
        this.subscription = api.sqlPost('corporate/topTenHashtags', {
          uniqueName: data.uniqueName,
          startDate: this.reportModel.startDate,
          endDate: this.reportModel.endDate
        })
          .map(res => res.json()).subscribe(snap => {
            snap.forEach(element => {
              this.hashtags.push({
                name: element.name,
                count: element.count
              });
            });
            //this.dataSource = new MyDataSource(this.hashtags);
            this.dataSource = new MatTableDataSource(this.hashtags);
            this.dataSource.sort = this.sort;

          });
        this.reportModel.endDate = newDate2;
      }
    });
    this.dateAdapter.setLocale('tr');
  }
  ngOnInit() {

  }

  selectRow(row) {

    return this.selectedRow = row;
  }

  openDialog(row) {
    /* Açılan dialoga data geçmek için kullanılan bir yöntem.Dialog constructorda @Inject edilerek data taşınmış olur.
    Kullanımı için PopUpListComponent'ın constructorına gözat   */
    this.dialog.open(PopUpListComponent, {
      data: row
    });

  }
  getByDate() {
    this.reportModel.startDate = this.dateFormat(this.reportModel.startDate);
    this.reportModel.endDate = this.dateFormat(this.reportModel.endDate);
    this.subscription = this.chooseCompany.getCompany().subscribe(data => {
      this.hashtags = [];
      this.company = data;
      this.subscription = this.api.sqlPost('corporate/topTenHashtags', {
        uniqueName: data.uniqueName,
        startDate: this.reportModel.startDate,
        endDate: this.reportModel.endDate
      })
        .map(res => res.json()).subscribe(snap => {
          snap.forEach(element => {
            this.hashtags.push({
              name: element.name,
              count: element.count
            });
          });
          //this.dataSource = new MyDataSource(this.hashtags);
          this.dataSource = new MatTableDataSource(this.hashtags);
          this.dataSource.sort = this.sort;

        });
    });

  }
  dateFormat(data) {
    if (data.length == 10) {
      return data;
    }
    var dd = data.getDate();
    var mm = data.getMonth() + 1; //January is 0!
    var yyyy = data.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    data = yyyy + '-' + mm + '-' + dd;
    return data;
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}



//Oğuz abinin versiyonunda vardı, sorting yapılınca gerek kalmadı.Yine de silmiyorum.Belki birgün lazım olur :)
// export class MyDataSource extends DataSource<any> {
//   constructor(
//     private data: any) {
//     super();
//   }
//   _filterChange = new BehaviorSubject('');
//   _filteredDataChange = new BehaviorSubject('');

//   get filteredData(): any {
//     return this._filteredDataChange.value;
//   }

//   set filteredData(value: any) {
//     this._filteredDataChange.next(value);
//   }

//   get filter(): string {
//     return this._filterChange.value;
//   }

//   set filter(filter: string) {
//     this._filterChange.next(filter);
//   }
//   /** Connect function called by the table to retrieve one stream containing the data to render. */
//   connect(): Observable<any[]> {
//     const displayDataChanges = [
//       this.data,
//       this._filterChange
//     ];
//     return Observable.merge(...displayDataChanges).map(() => {
//       let data = this.data.slice();

//       this.filteredData = [...data];

//       // Grab the page's slice of data.
//       const startIndex = 0 * 5;
//       return data.splice(startIndex);
//     });
//   }

//   disconnect() { }

// }