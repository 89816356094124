import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '../../../../core/services/config.service';
import { fuseAnimations } from '../../../../core/animations';
import * as firebase from 'firebase';
import { Router } from "@angular/router";
import { UserService } from '../../../../core/services/user.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { Http } from '@angular/http';
import { Subscription } from 'rxjs';

@Component({
    selector: 'fuse-login-2',
    templateUrl: './login-2.component.html',
    styleUrls: ['./login-2.component.scss'],
    animations: fuseAnimations
})
export class FuseLogin2Component implements OnInit {
    loginForm: FormGroup;
    forgotForm: FormGroup;
    forgotFormErrors: any;
    loginFormErrors: any;
    model: any;
    alert: any;
    loading = false;
    forgotPassword = false;
    staticAlertClosed = false;
    verifyerror = false;
    successFullAlert: any;
    alert1: any;
    ipAddress: any;
    subsctiprion = new Subscription();
    constructor(
        private fuseConfig: FuseConfigService,
        private formBuilder: FormBuilder,
        private afs: AngularFirestore,
        private _http: Http,
        private router: Router,
        private userService: UserService
    ) {

        this.fuseConfig.setSettings({
            layout: {
                navigation: 'none',
                toolbar: 'none',
                footer: 'none'
            }
        });
        this.forgotFormErrors = {
            email: {}
        };

        this.loginFormErrors = {
            email: {},
            password: {}
        };
    }

    ngOnInit() {
        this.getIpAdress();

        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
        this.forgotForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        })

        this.loginForm.valueChanges.subscribe(() => {
            this.onLoginFormValuesChanged();
        });
        this.forgotForm.valueChanges.subscribe(() => {
            this.onForgotFromValuesChanged();
        })
    }
    getIpAdress() {
        this.subsctiprion.add(this._http.get("https://api.bigadvice.com/helper/ip")
            .map(data => { this.ipAddress = data.json() }).subscribe());
    }

    onForgotFromValuesChanged() {
        for (const field in this.forgotFormErrors) {
            if (!this.forgotFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.forgotFormErrors[field] = {};

            // Get the control
            const control = this.forgotForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.forgotFormErrors[field] = control.errors;
            }
        }
    }


    onLoginFormValuesChanged() {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }
    onSubmit(form) {
        if (form) {
            firebase.auth().sendPasswordResetEmail(form.email).then(data => {
                this.loading = false;
                this.forgotPassword = false;
                this.staticAlertClosed = true;
                this.successFullAlert = 'Aktivasyon linkiniz e-posta adresinize gönderilmiştir!';
                //   this.cd.markForCheck();
                // this.alertService.success('Aktivasyon linkiniz e-posta adresinize gönderilmiştir!')
            })
        }
    }

    dateFormat(data) {
        if (data.length == 10) {
            return data;
        }
        var dd = data.getDate();
        var mm = data.getMonth() + 1; //January is 0!
        var yyyy = data.getFullYear();
        var hh = data.getHours();
        var mi = data.getMinutes();

        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }

        data = yyyy + '-' + mm + '-' + dd + '-' + hh + ':' + mi;
        return data;
    }

    login(form) {
        var date = new Date();
        if (form) {
            firebase.auth().signInWithEmailAndPassword(form.email, form.password).then(data => {
                // localStorage.setItem('currentUserId', data.user.uid);
                console.log(data)
                this.userService.setUser(data.uid);
                firebase.database().ref().child('corporateUsers').child(data.uid).once('value', isAdmin => {
                    // if(isAdmin.val()['isEmailVerified']===true){
                    localStorage.setItem('currentUserId', data.uid);
                    firebase.database().ref().child('corporateLogin').child(data.uid).push({
                        date: this.dateFormat(date),
                        email: form.email


                    }).then(() => {
                        this.afs.collection('logs').add({
                            userId: data.uid,
                            date: new Date(),
                            type: 'login',
                            ipAddress: this.ipAddress,
                            url: 'analytics.bigadvice.com'
                        });
                    });

                    this.router.navigate(['/']);
                    // } else {
                    // this.staticAlertClosed=true;
                    // this.verifyerror=true;
                    // this.alert1="E-mail adresiniz doğrulanmamıştır.lütfen doğrulama yapınız!"
                    // firebase.auth().signOut();
                    // localStorage.clear();
                    // }
                });
            },
                (err: firebase.FirebaseError) => {
                    this.staticAlertClosed = false;
                    switch (err.code) {
                        case "auth/user-not-found": this.alert = "Bu hesaba tanımlanan kullanıcı kaydı yoktur"; break;
                        case "auth/invalid-email": this.alert = "E-posta adresi hatalı girilmiştir, lütfen adresinizi kontrol ederek tekrar giriş yapın"; break;
                        case "auth/wrong-password": this.alert = "Parola geçersiz ya da henüz tanımlanmamıştır"; break;
                        default: this.alert = "Hatalı giriş yaptınız"
                    }
                });
        }
    }
}
