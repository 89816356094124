import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportComponent } from './support.component';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';

const routes = [
  {
      path     : 'support',
      component: SupportComponent
  }
];

@NgModule({
  imports     : [
    SharedModule,
    RouterModule.forChild(routes)
],
  declarations: [SupportComponent]
})
export class SupportModule { }
