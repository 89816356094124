import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as firebase from 'firebase';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { ChooseCompanyService } from '../core/services/choose-company.service';
import { FuseNavigationService } from '../core/components/navigation/navigation.service';
import { AngularFirestore } from 'angularfire2/firestore';
@Injectable()
export class RoleGuard implements CanActivate {
    nM: FuseNavigationService;
    authRole: any;
    url = [];
    constructor(private router: Router,
        private afm: AngularFireDatabase,
        private afs: AngularFirestore,
        private navigationModel: FuseNavigationService) {
        this.nM = this.navigationModel;
    }


    findByProp(o, prop, val, retprop) {
        if (o == null) return false;
        if (o[prop] === val) {
            return (retprop) ? o[retprop] : o;
        }
        var result, p;
        for (p in o) {
            if (o.hasOwnProperty(p) && typeof o[p] === 'object') {
                result = this.findByProp(o[p], prop, val, retprop);
                if (result) {
                    return (retprop) ? result[retprop] : result;
                }
            }
        }
        return (retprop) ? result[retprop] : result;
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let a;
        let role;
        let o = this.nM.getNavigationModel();

        this.afs.collection('corporateUsers').doc(localStorage.getItem('currentUserId')).ref.get().then((data => {
            if (data.exists) {
           
                a = data.data().list;
                role = data.data().companies[0].role;

            }
            // let a =  (await this.afm.list('corporateUsers/' + localStorage.getItem('currentUserId') + '/list').$ref.once('value')).val();
            let authRole = this.findByProp(a, 'url', "/" + route.routeConfig.path, '').role;


            // let role = (await this.afm.list('corporateUsers/' + localStorage.getItem('currentUserId') + '/companies/0/role').$ref.once('value')).val();


            this.afs.collection('corporateUsers').doc(localStorage.getItem('currentUserId')).ref.get().then((data => {
                if (localStorage.getItem('currentUserId') && authRole.indexOf(role) > -1 && data.exists) {
                    return true;
                }
                else if (localStorage.getItem('currentUserId')) {
                    var b = route.routeConfig.path;
                    var c = this.findByProp(a, 'url', "/" + route.routeConfig.path, '').title;
                    this.navigationModel.test.next(c);
                    this.router.navigate(['/pricing'], { queryParams: { url: b } });

                    return false;
                }
            }))
        }))

        return true;


        // not logged in so redirect to login page with the return url
    }
}