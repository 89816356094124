import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { fuseAnimations } from '../../../../core/animations';
import { ChooseCompanyService } from '../../../../core/services/choose-company.service';
import { TopTenHashtagsComponent } from '../top-ten-hashtags.component';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pop-up-list',
  templateUrl: './pop-up-list.component.html',
  styleUrls: ['./pop-up-list.component.scss'],
  animations: fuseAnimations
})
export class PopUpListComponent implements OnInit, OnDestroy {

  selectedRow: any;
  onAdd = new EventEmitter();
  hitPosts = [];
  topEtkilesim = 0;
  company: any;
  sub: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private chooseCompany: ChooseCompanyService) {
    this.selectedRow = data;
    this.sub = this.chooseCompany.getCompany().subscribe(data => {
      this.company = data.name.toLowerCase();


    })

    api.sqlPost('corporate/topTenHashtagsPosts2', {
      hashtag: this.selectedRow,
      uniqueName: this.company
    })
      .map(res => res.json()).subscribe(posts => {
        posts.forEach(element => {
      

          this.topEtkilesim = this.topEtkilesim + (element.likes) + (element.reposts) + (element.comments);
          var date = new Date(element.modifyDate);
          var day = String(date.getDate());
          var month = String(date.getMonth() + 1);
          var year = String(date.getFullYear());
          if (day.length < 2) { day = '0' + day; }
          if (month.length < 2) { month = '0' + month }
          element.modifyDate = day + '/' + month + '/' + year;

          this.hitPosts.push({

            firstname: element.firstname,
            lastname: element.lastname,
            innerText: element.text,
            likes: element.likes,
            reposts: element.reposts,
            comments: element.comments,
            modifyDate: element.modifyDate,
            total: element.total,
            key: element.key
          });
        });
      })



  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }
}
