import { Component, OnInit } from '@angular/core';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { ApiService } from '../../../core/services/api.service';
import { fuseAnimations } from '../../../core/animations';

@Component({
  selector: 'app-brand-hashtags',
  templateUrl: './brand-hashtags.component.html',
  styleUrls: ['./brand-hashtags.component.scss'],
  animations: fuseAnimations
})
export class BrandHashtagsComponent implements OnInit {

  company: any;
  hashInfo = [];
  hashModel = [
    { uniqueName: 'şikayetimvar', name: 'Şikayet Paylaşım Oranı' },
    { uniqueName: 'benimtavsiyem', name: 'Tavsiye Paylaşım Oranı' },
    { uniqueName: 'markamaönerim', name: 'Öneri Paylaşım Oranı' },
    { uniqueName: 'bunuçoksevdim', name: 'Memnuniyet Paylaşım Oranı' }
  ]

  constructor(private chooseCompany: ChooseCompanyService, private api: ApiService) {
    chooseCompany.getCompany().subscribe(data => {
      this.hashInfo = [];
      this.company = data;
      this.hashModel.forEach(hash => {
        api.sqlPost('corporate/crooscheckHashtagAll', {
          uniqueName: data.uniqueName,
          hashtag: hash.uniqueName
        })
          .map(res => res.json()).subscribe(snap => {
            
            this.hashInfo.push({
              percent: snap[0].percent,
              row: snap[0].row,
              hashHead: hash.name
            });
          });
      });
    });
  }

  ngOnInit() {
  }

}
