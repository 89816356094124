import { NgModule } from '@angular/core';
import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { UserRatePipe } from './user-rate.pipe';
import { StoragePathPipe } from './storage-path.pipe';
import { StoragePath2Pipe } from './storage-path2.pipe';
import { HighlightSearch } from './topfiveposts.pipe';
import { PhonePipe } from './phone.pipe';
import { ReplaceBadwordsPipe } from './badword.pipe';
@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        UserRatePipe,
        StoragePathPipe,
        StoragePath2Pipe,
        HighlightSearch,
        PhonePipe,
        ReplaceBadwordsPipe

    ],
    imports     : [],
    exports     : [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        UserRatePipe,
        StoragePathPipe,
        StoragePath2Pipe,
        HighlightSearch,
        PhonePipe,
        ReplaceBadwordsPipe
    ]
})

export class FusePipesModule
{

}
