import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompetitionAnalysisComponent } from './competition-analysis/competition-analysis.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../../core/modules/shared.module';
import { FuseWidgetModule } from '../../../core/components/widget/widget.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ScoreChangeComponent } from './score-change/score-change.component';
import { ApiService } from '../../../core/services/api.service';
import { AuthGuard } from '../../../_guards/auth.guard';
import { RoleGuard } from '../../../_guards/role.guard';

const routes: Routes = [
  {
    path: 'competetion',
    component: CompetitionAnalysisComponent,
    canActivate: [AuthGuard,RoleGuard]
  }, {
    path: 'score-change',
    component: ScoreChangeComponent,
    canActivate: [AuthGuard,RoleGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FuseWidgetModule,
    NgxChartsModule
  ],
  declarations: [CompetitionAnalysisComponent, ScoreChangeComponent],
  providers: [ApiService, AuthGuard,RoleGuard]
})
export class GraphicsModule { }
