import { Component, ViewChild, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ngb-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  @ViewChild('notif') notif: ElementRef;
  message:string;
  constructor(
    public dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.message = data.text;
     }

  closeNotification() {
    // this.notif.nativeElement.classList.remove('show-up');
    // this.notif.nativeElement.classList.add('close');
  }
}