import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { fuseAnimations } from '../../../core/animations';
import { MatPaginator, MatSort } from '@angular/material';
import $ from "jquery";
import "jquery-circliful";





import { AngularFireDatabase } from 'angularfire2/database-deprecated';

import { Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { AngularFirestore } from 'angularfire2/firestore';
@Component({
  selector: 'app-brand-criterias',
  templateUrl: './brand-criterias.component.html',
  styleUrls: ['./brand-criterias.component.scss'],
  animations: fuseAnimations
})
export class BrandCriteriasComponent implements OnInit, OnDestroy {
  displayedColumns = ['name', 'zeroFourPoint', 'fiveSixPoint', 'sevenEightPoint', 'nineTenPoint'];
  subscription: Subscription;
  to;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filter') filter: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  graph = false;
  value = 45;
  number: any;
  criterias: any;
  reportModel: any = {};
  legend = true;
  labels = 'Toplam';
  explodeSlices = false;
  doughnut = false;
  brandRate: any;
  filteredItems: Array<any>;
  dataSource: MyDataSource;
  criteriasChart = [];
  input: any;
  company: any;
  toplam = 0;
  totalUsersVoted = 0;
  topFiyat = 0;
  topUrun = 0;
  topSatis = 0;
  topKalite = 0;
  topsatisSonrasi = 0;
  fiyat: any;
  urun: any;
  satis: any;
  kalite: any;
  satissonrasi: any;
  sayi = 0.15;
  show = false;

  constructor(
    private afm: AngularFireDatabase,
    private afs: AngularFirestore,
    private router: Router,
    private cd: ChangeDetectorRef,
    private chooseCompany: ChooseCompanyService,
    private dialog: MatDialog) {

    this.subscription = chooseCompany.getCompany().subscribe(data => {

      if (data.uniqueName) {
        this.company = data;
        this.afs.collection('brands').ref.where('uniqueName', '==', data.uniqueName).get().then((snap => {
          this.afs.collection('brands').doc(snap.docs[0].id).collection('categories').ref.where('name', '==', 'Genel').get().then((res => {
            this.number = 0;
            this.brandRate = res.docs[0].data();

            this.criterias = res.docs[0].data().criterias;
            this.criterias.map(column => {
              this.criteriasChart.push(column);

            });
            this.topFiyat = parseFloat((this.criteriasChart[0].total / this.criteriasChart[0].peopleCount).toFixed(2));

            this.fiyat = this.criteriasChart[0].name;
            this.topUrun = parseFloat((this.criteriasChart[1].total / this.criteriasChart[1].peopleCount).toFixed(2));
            this.urun = this.criteriasChart[1].name;
            this.topSatis = parseFloat((this.criteriasChart[2].total / this.criteriasChart[2].peopleCount).toFixed(2));
            this.satis = this.criteriasChart[2].name;
            this.topKalite = parseFloat((this.criteriasChart[3].total / this.criteriasChart[3].peopleCount).toFixed(2));
            this.kalite = this.criteriasChart[3].name;
            this.topsatisSonrasi = parseFloat((this.criteriasChart[4].total / this.criteriasChart[4].peopleCount).toFixed(2));
            this.satissonrasi = this.criteriasChart[4].name;
            this.dataSource = new MyDataSource(this.criterias, this.sort);
            this.cd.markForCheck();
            this.criterias.forEach(element => {
              if (element.countDetails) {

                var onefour = (element.countDetails._1 || 0) + (element.countDetails._2 || 0) + (element.countDetails._3 || 0) + (element.countDetails._4 || 0) + (element.countDetails._5 || 0) + (element.countDetails._6 || 0) + (element.countDetails._7 || 0) + (element.countDetails._8 || 0) + (element.countDetails._9 || 0) + (element.countDetails._10 || 0);
                this.totalUsersVoted = onefour;
              }
              else {
                this.show = true;
              }
            });


          }))
        }));
        this.cd.markForCheck();
      }
    });

  }
  setBold(product: any, ratingScale: number) {
    var onefour = (product.countDetails._1 || 0) + (product.countDetails._2 || 0) + (product.countDetails._3 || 0) + (product.countDetails._4 || 0);
    var fivesix = (product.countDetails._5 || 0) + (product.countDetails._6 || 0);
    var seveneig = (product.countDetails._7 || 0) + (product.countDetails._8 || 0);
    var nineten = (product.countDetails._9 || 0) + (product.countDetails._10 || 0);
    var eb = [onefour, fivesix, seveneig, nineten];
    var top = Math.max(...eb);





    if (onefour == top && ratingScale == 1)
      return "bold";
    else if (fivesix == top && ratingScale == 2)
      return "bold";
    else if (seveneig == top && ratingScale == 3)
      return "bold";
    else if (nineten == top && ratingScale == 4)
      return "bold";
    else
      return "normal";
  }
  setColor(product: any, ratingScale: number) {
    var onefour = (product.countDetails._1 || 0) + (product.countDetails._2 || 0) + (product.countDetails._3 || 0) + (product.countDetails._4 || 0);
    var fivesix = (product.countDetails._5 || 0) + (product.countDetails._6 || 0);
    var seveneig = (product.countDetails._7 || 0) + (product.countDetails._8 || 0);
    var nineten = (product.countDetails._9 || 0) + (product.countDetails._10 || 0);
    var eb = [onefour, fivesix, seveneig, nineten];
    var top = Math.max(...eb);
    if (onefour == top && ratingScale == 1)
      return "#800080";
    else if (fivesix == top && ratingScale == 2)
      return "#800080";
    else if (seveneig == top && ratingScale == 3)
      return "#800080";
    else if (nineten == top && ratingScale == 4)
      return "#800080";
    else
      return "black";
  }

  ngOnInit() {

    this.to = setTimeout(function () {
      var a = parseFloat($("#sikayet-val").val()) || 0;
      console.log(a);
      var b = parseFloat($("#kararsiz-val").val()) || 0;
      var c = parseFloat($("#mutlu-val").val()) || 0;
      var d = parseFloat($("#sadik-val").val()) || 0;
      var e = parseFloat($("#kalite-val").val()) || 0;
      $("#mutlak-sikayet").circliful({
        animationStep: 5,
        foregroundColor: '#f28a74',
        fontColor: '#898a8c',
        foregroundBorderWidth: 10,
        backgroundBorderWidth: 15,
        percent: a * 10,
        replacePercentageByText: a
      });
      $("#kararsiz").circliful({
        animation: 1,
        animationStep: 5,
        start: 2,
        showPercent: 1,
        percent: b * 10,
        foregroundColor: '#8a8b8a',
        fontColor: '#898a8c',
        multiPercentage: 1,
        foregroundBorderWidth: 10,
        backgroundBorderWidth: 15,
        replacePercentageByText: b,
      });
      $("#mutlu").circliful({
        animation: 1,
        animationStep: 5,
        start: 2,
        showPercent: 1,
        percent: c * 10,
        foregroundColor: '#a17cb6',
        fontColor: '#898a8c',
        multiPercentage: 1,
        foregroundBorderWidth: 10,
        backgroundBorderWidth: 15,
        replacePercentageByText: c,
      });
      $("#sadik").circliful({
        animation: 1,
        animationStep: 5,
        start: 2,
        showPercent: 1,
        percent: d * 10,
        foregroundColor: '#89aa6c',
        fontColor: '#898a8c',
        foregroundBorderWidth: 10,
        backgroundBorderWidth: 15,
        replacePercentageByText: d,
      });
      $("#kalite").circliful({
        animation: 1,
        animationStep: 5,
        start: 2,
        showPercent: 1,
        percent: e * 10,
        foregroundColor: '#7f90a8',
        fontColor: '#898a8c',
        foregroundBorderWidth: 10,
        backgroundBorderWidth: 15,
        replacePercentageByText: e,
      });
    }, 1000)
  }

  navigate(uniqueName) {
    this.router.navigate(['/set/categories'], { queryParams: { name: uniqueName } });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearTimeout(this.to);
  }
}

export class MyDataSource extends DataSource<any> {
  constructor(
    private data: any,
    private _sort: MatSort) {
    super();
  }
  _filterChange = new BehaviorSubject('');
  _filteredDataChange = new BehaviorSubject('');

  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.data,
      this._filterChange,
      this._sort.sortChange
    ];
    return Observable.merge(...displayDataChanges).map(() => {
      let data = this.data.slice();

      this.filteredData = [...data];

      data = this.sortData(data);

      // Grab the page's slice of data.
      const startIndex = 0 * 5;
      return data.splice(startIndex);
    });
  }

  sortData(data): any[] {
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'id':
          [propertyA, propertyB] = [a.id, b.id];
          break;
        case 'name':
          [propertyA, propertyB] = [a.name, b.name];
          break;
        case 'categories':
          [propertyA, propertyB] = [a.categories[0], b.categories[0]];
          break;
        case 'price':
          [propertyA, propertyB] = [a.priceTaxIncl, b.priceTaxIncl];
          break;
        case 'quantity':
          [propertyA, propertyB] = [a.quantity, b.quantity];
          break;
        case 'active':
          [propertyA, propertyB] = [a.active, b.active];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }

  disconnect() { }


}

