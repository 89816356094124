import { NgModule } from '@angular/core';
import { FeedbackHomeComponent } from './feedback-home.component';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../../_guards/auth.guard';
import { FuseWidgetModule } from '../../../core/components/widget/widget.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';

const routes = [
  {
      path     : 'feedback-home',
      component: FeedbackHomeComponent,
      canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    FuseWidgetModule,
    NgxChartsModule
  ],
  declarations: [
    FeedbackHomeComponent
  ],
  providers: [
    AuthGuard
  ]
})

export class FeedbackHomeModule { }
