import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { fuseAnimations } from '../../../../core/animations';
import { ChooseCompanyService } from '../../../../core/services/choose-company.service';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {MatTableModule} from '@angular/material/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-competition-analysis',
  templateUrl: './competition-analysis.component.html',
  styleUrls: ['./competition-analysis.component.scss'],
  animations: fuseAnimations
})
export class CompetitionAnalysisComponent implements OnInit,OnDestroy {
  chart: any;
  subscription:Subscription;
  tableData = [];
  top = 0;
  dataSource;
  widget = {
    legend: false,
    explodeSlices: false,
    labels: true,
    doughnut: true,
    gradient: false,
    scheme: {
      domain: ['#f28a74', '#a17cb6', '#89aa6c', '#7f90a8','#8a8b8a','#edf291']
    },
    onSelect: (ev) => {
      console.log(ev);
    }
  };
    show = false;
    displayedColumns: string[] = ['marka','adet', 'yüzde'];
  constructor(private api: ApiService, private chooseCompany: ChooseCompanyService) {
    this.subscription = this.chooseCompany.getCompany().subscribe(company => {
      
      let rivals = company.rivalBrand.map(data => {
        return data.uniqueName;
      });
      rivals.push(company.uniqueName);  
      
      this.subscription = this.api.sqlPost("entity/searchByUniqueNames", rivals).map(res => res.json()).subscribe(data => {
        this.chart = [];
       
       
        
        this.chart = this.generateChart(data);
      
        
      });
    });
    
    
  }
  getTotalCost() {
    return 5;
  }

  ngOnInit() {

  }

  generateColumn = (column) => {
    console.log(column);
    
    this.tableData.push({
      'name': JSON.parse(column.json).name,
      'value': JSON.parse(column.json).popularity || 0
    })
    this.top += JSON.parse(column.json).popularity || 0;
    
    this.dataSource = new MatTableDataSource(this.tableData);
    
    return this.show=true,{
      'name': JSON.parse(column.json).name,
      'value': JSON.parse(column.json).popularity || 0
    }
  }
  generateChart = (competitions) => {
    return {
      'title': "",
      'mainChart': competitions.map(column => this.generateColumn(column))
    }
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

}
