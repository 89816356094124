import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChooseCompanyService } from '../../../../core/services/choose-company.service';
import { FuseUtils } from '../../../../core/fuseUtils';
import { snapshotChanges } from 'angularfire2/database';
import { appInitializerFactory } from '@angular/platform-browser/src/browser/server-transition';
import { AngularFirestore } from 'angularfire2/firestore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog1.component.html',
  styleUrls: ['./dialog1.component.scss']
})
export class DialogComponent1 implements OnInit {
  @ViewChild('main') Input: ElementRef;

  form: FormGroup;
  formErrors: any;
  company: any;
  item = [];
  item2 = [];
  tags = [];
  tags1 = [];
  text: any = "";
  text2: any = "";
  itemIndex: any = null;
  itemIndex2: any = null;
  subs = new Subscription();
  error: string;


  constructor(
    public dialogRef: MatDialogRef<DialogComponent1>,
    private afm: AngularFireDatabase,
    private afs: AngularFirestore,
    private formBuilder: FormBuilder,
    private chooseCompany: ChooseCompanyService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.formErrors = {
      company: {},
      main: {},
      password: {},
      currentPassword: {},
      issue: {},
      city: {},
      state: {},
      postalCode: {}
    };
    chooseCompany.getCompany().subscribe(company => {
      this.company = company;
    });

  }

  close() {
    this.dialogRef.close()
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      company: [
        {
          value: 'Google',
          disabled: true
        }, Validators.required
      ],
      main: [this.data ? this.data.head : '']
    });

    this.form.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    });
    this.subs.add(this.afs.collection('corporateTags', ref => ref.where('brand', '==', this.company.uniqueName)).valueChanges().subscribe(data => {
      if (data[0]) {
      
        if (data[0]['tags']) {
          this.item = data[0]['tags'];

        }
        else {
          this.item = [];

        }

      }
    }));
    this.subs.add(this.afs.collection('corporateTags', ref => ref.where('brand', '==', this.company.uniqueName)).valueChanges().subscribe(data => {
      if (data[0]) {
        if (data[0]['tags2']) {
          this.item2 = data[0]['tags2'];

        }
        else {
          this.item2 = [];

        }

      }
    }));
    // this.afs.collection('corporateTags').ref.where('brand', '==', this.company.uniqueName).get().then((data => {
    //   if (!data.empty) {
    //     if (data.docs[0].data().tags) {
    //       this.item = data.docs[0].data().tags;

    //     }
    //     else {
    //       this.item = [];
    //     }

    //   }

    // }));
    // this.afs.collection('corporateTags').ref.where('brand', '==', this.company.uniqueName).get().then((data => {
    //   if (!data.empty) {
    //     if (data.docs[0].data().tags2) {
    //       this.item2 = data.docs[0].data().tags2;

    //     }
    //     else {
    //       this.item2 = [];
    //     }

    //   }

    // }))
  }

  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.formErrors[field] = {};

      // Get the control
      const control = this.form.get(field);

      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }


  send() {
    let main = this.form.get('main').value;
    let latin: string = ''; // to get value in input tag


    if (main !== null && main !== '' && this.itemIndex === null && this.item !== null && this.itemIndex2 === null) {

      latin = this.convertLatin(main).toLowerCase();

      if (!this.item.includes(main) && !this.item2.includes(main) && !this.item2.includes(latin) && !this.item.includes(latin)) {
       
        this.error = "";
        this.item.push(main);
        this.item2.push(latin);
      
        this.afs.collection('corporateTags').ref.where('brand', '==', this.company.uniqueName).get().then(snap => {
          if (!snap.empty) {
            let tagsArr = [];
            let tagsArr2 = [];
            if (snap.docs[0].data().tags) {
              tagsArr = snap.docs[0].data().tags;
              tagsArr.push(main);
              this.afs.collection('corporateTags').doc(snap.docs[0].id).update({ tags: tagsArr });

            }
            if (snap.docs[0].data().tags2) {
              tagsArr2 = snap.docs[0].data().tags2;
              tagsArr2.push(latin.toLocaleLowerCase());
              this.afs.collection('corporateTags').doc(snap.docs[0].id).update({ tags2: tagsArr2 });

            }
            else {
              this.afs.collection('corporateTags').doc(snap.docs[0].id).update({ tags: main, tags2: latin.toLocaleLowerCase() });

            }
          }
          else {
            this.afs.collection('corporateTags').add({ tags: [main], tags2: [latin.toLocaleLowerCase()], brand: this.company.uniqueName })
          }
        });
      }
      else {
        this.error = "Daha önce böyle bir etiket oluşturulmuştur.";

      }

    }

    else if (this.itemIndex !== null && main !== null && main !== '' && this.itemIndex2 !== null) {
      
      let editArr;
      this.afs.collection('corporateTags').ref.where('brand', '==', this.company.uniqueName).get().then((data => {
        editArr = data.docs[0].data().tags;
 
      
        editArr[this.itemIndex] = main;
      
        this.afs.collection('corporateTags').doc(data.docs[0].id).update({ tags: editArr });
      }));
      this.afs.collection('corporateTags').ref.where('brand', '==', this.company.uniqueName).get().then((data => {
        let latin = this.convertLatin(main);
        let editArr2 = data.docs[0].data().tags2;
        editArr2[this.itemIndex2] = latin.toLocaleLowerCase();
        this.afs.collection('corporateTags').doc(data.docs[0].id).update({ tags2: editArr2 });
      }))

      // .then(() => {
      //   this.itemIndex = null;
      //   this.itemIndex2 = null;
      // })
    }
  
    // if (this.itemIndex === null && main !== '' && this.itemIndex2 === null) {
    //   console.log("girididiidi")
    //   let latin = this.convertLatin(main);
    //   this.item[this.itemIndex] = main;
    //   this.item2[this.itemIndex2] = latin;
    //   this.afs.collection('corporateTags').add({ brand: this.company.uniqueName, tags: this.item, tags2: this.item2 });
    // }


    this.form.reset();

    // this.dialogRef.close();
  }

  // if (this.itemIndex != null) {
  //     this.item[this.itemIndex].name = data;
  //     this.item[this.itemIndex].tooltip = tooltip;

  // } else {
  //     this.item.push({ name: data, point: 0, tooltip: tooltip });
  // }
  // this.text = "";
  // this.textTool = "";
  // this.itemIndex = null;

  delete(data) {
    let latin = this.convertLatin(data);

    this.item = this.item.filter(x => {
      return data !== x;
    })
    this.item2 = this.item2.filter(y => {
      return latin.toLowerCase() !== y;

    });
    this.afs.collection('corporateTags').ref.where('brand', '==', this.company.uniqueName).get().then((data => {
      this.afs.collection('corporateTags').doc(data.docs[0].id).update({ tags: this.item, tags2: this.item2 });
    }));



  }
  edit(data, index) {
    let latin = this.convertLatin(data);

    this.text2 = latin;
    this.text = data;

    this.itemIndex = index;
    this.itemIndex2 = index;
  }
  convertLatin(main) {
    let latin = '';
    for (let i = 0; i < main.length; i++) {
      let x = main.charAt(i);
      switch (x) {
        case "ş":
          latin = latin + 's';
          break;
        case "ı":
          latin = latin + 'i';
          break;
        case "ç":
          latin = latin + 'c';
          break;
        case "ğ":
          latin = latin + 'g';
          break;
        case "ö":
          latin = latin + "o";
          break;
        case "ü":
          latin = latin + "u";
          break;
        case "Ş":
          latin = latin + 's';
          break;
        case "I":
          latin = latin + 'i';
          break;
        case "Ç":
          latin = latin + 'c';
          break;
        case "Ğ":
          latin = latin + 'g';
          break;
        case "Ö":
          latin = latin + "o";
          break;
        case "Ü":
          latin = latin + "u";
          break;
        case "İ":
          latin = latin + "i";
          break;
        default:
          latin = latin + x;
          break;
      }

    }
    return latin;
  }


}
