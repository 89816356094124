import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable()
export class BadwordsService {
  badwords = new BehaviorSubject<any>([]);
  constructor(private afm: AngularFireDatabase,
    private afs: AngularFirestore
  ) { }

  getBadwords(): Observable<any[]> {
    return this.badwords.asObservable();
  }
  initialize() {
    let blacklist = [];
    this.afs.collection('blacklist').ref.get().then((data => {

      data.forEach(blackWord => {
        blacklist.push(blackWord.data().word);
      })

      this.badwords.next(blacklist);
    }))
  }
}
