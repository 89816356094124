import { Component, HostBinding, Input, OnInit, NgZone } from '@angular/core';
import { ChooseCompanyService } from '../../../../services/choose-company.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { FuseNavigationService } from '../../navigation.service';

@Component({
    selector: 'fuse-nav-vertical-item',
    templateUrl: './nav-vertical-item.component.html',
    styleUrls: ['./nav-vertical-item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit {
    @HostBinding('class') classes = 'nav-item';
    @Input() item: any;
    company: any;
    test;
    show = false;
    navigationModel : any[];
    navigationModelChangeSubscription : Subscription;
    constructor(
        private chooseCompany: ChooseCompanyService,
        private zone: NgZone,
        private dialog: MatDialog,
        private router: Router,
        private fuseNavigationService:FuseNavigationService) {
        chooseCompany.getCompany().subscribe(data => {
            this.company = data;
           
        })
        var a = this.fuseNavigationService.test;
        a.subscribe(data => {
           this.test = '/' + data;
        })
        
        
    }

    // navigate() {
    //     if (this.item.role.indexOf(this.company.role) > -1) {
    //         this.zone.run(() => this.router.navigate(['/' + this.item.url]))
    //     } else {
    //         this.router.navigate(['/pricing'])
    //     }
    // }

    ngOnInit() {
        
        
        // this.router.events.subscribe(data => {
        //     console.log(data);
            
        //     // if(this.item.role.indexOf(this.company.role) > -1){
        //     //     this.router.navigate(['/' + this.item.url])
        //     // }
        //     // else {
        //     //     this.router.navigate(['/pricing'])
        //     // }
        // })
    }
}
