import { NgModule } from '@angular/core';
import { RivalsCrosscheckComponent } from './rivals-crosscheck.component';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../../_guards/auth.guard';
import { RoleGuard } from '../../../_guards/role.guard';
import { FuseWidgetModule } from '../../../core/components/widget/widget.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';

const routes = [
  {
      path     : 'rivals-crosscheck',
      component: RivalsCrosscheckComponent,
      canActivate: [AuthGuard,RoleGuard]
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    FuseWidgetModule,
    NgxChartsModule
  ],
  declarations: [
    RivalsCrosscheckComponent
  ],
  providers: [
    AuthGuard,
    RoleGuard
  ]
})

export class RivalsCrosscheckModule { }
