
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseWidgetModule } from '../../../core/components/widget/widget.module';
import { AuthGuard } from '../../../_guards/auth.guard';
import { RoleGuard } from '../../../_guards/role.guard';
import { KeywordDetailsComponent } from './keyword-list.component';

const routes = [
    {
        path: 'keyword-list',
        component: KeywordDetailsComponent,

        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes),
        NgxChartsModule,
        FuseWidgetModule
    ],
    declarations: [
        KeywordDetailsComponent
    ],
    providers: [
        AuthGuard
    ]
})
export class KeywordDetailsModule { }