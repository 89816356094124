import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import * as firebase from "firebase";
import { DialogComponent } from './dialog/dialog.component';
import { MatDialog } from '@angular/material';
import { fuseAnimations } from '../../../core/animations';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';



export class MyValidator {

  static MatchPassword(AC: AbstractControl) {
    let password = AC.get('password').value; // to get value in input tag
    let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    if (password != confirmPassword) {
      AC.get('confirmPassword').setErrors({ MatchPassword: true })
    } else {
      return null
    }
  }
}

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss'],
  animations: fuseAnimations
})
export class ProfileSettingsComponent implements OnInit {
  form: FormGroup;
  formErrors: any;
  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {
    this.formErrors = {
      company: {},
      confirmPassword: {},
      password: {},
      currentPassword: {},
      address2: {},
      city: {},
      state: {},
      postalCode: {}
    };
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      company: [
        {
          value: 'Google',
          disabled: true
        }, Validators.required
      ],
      confirmPassword: ['', Validators.required],
      password: ['', Validators.required],
      currentPassword: ['', Validators.required]
      // address2  : ['', Validators.required],
      // city      : ['', Validators.required],
      // state     : ['', Validators.required],
      // postalCode: ['', [Validators.required, Validators.maxLength(5)]]
    },
      {
        validator: [MyValidator.MatchPassword]
      });

    this.form.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    });
  }

  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.formErrors[field] = {};

      // Get the control
      const control = this.form.get(field);

      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  saveChanges() {

    let password = this.form.get('password').value; // to get value in input tag
    let currentPassword = this.form.get('currentPassword').value; // to get value in input tag
    const credentials = firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email, currentPassword)
    firebase.auth().currentUser.reauthenticateWithCredential(credentials)
      .then(() => {

        firebase.auth().currentUser.updatePassword(password).then(data => {
          this.dialog.open(DialogComponent, {
            width: '400px',
            data: {
              message: "Değişiklikler kaydedildi!"
            }
          });
          //this.loading = false;
        }).catch((e) => {
          // this.loading = false;
          this.dialog.open(DialogComponent, {
            width: '400px',
            data: {
              message: "Hesabının güvenliği için seni doğrulamamız gerekiyor. Lütfen tekrar giriş yap!"
            }
          });
        });
      },
        (error) => {
          this.dialog.open(DialogComponent, {
            width: '400px',
            data: {
              message: "Eski Şifren Yanlış!"
            }
          });
          // this.loading = false;
        });

  }

  onFileSelected(event) {
    console.log("Dosya yüklendi.");
    console.log(event);


  }


}
