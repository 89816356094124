import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { ApiService } from '../../../core/services/api.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { fuseAnimations } from '../../../core/animations';
import $ from "jquery";
import "jquery-circliful";
import { MatDialog } from '@angular/material';
import { PopUpComponent } from './pop-up/pop-up.component';
import { Subscription } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { AngularFirestore } from 'angularfire2/firestore';
import { reserveSlots } from '@angular/core/src/render3/instructions';

@Component({
  selector: 'app-user-criterias',
  templateUrl: './user-criterias.component.html',
  styleUrls: ['./user-criterias.component.scss'],
  animations: fuseAnimations
})
export class UserCriteriasComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  timeOutIDs: number[] = [];
  displayedColumns: string[] = ['position', 'name', 'weight', 'musteri'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  scheme = { domain: ['#7aa3e5', '#a8385d', '#5a78a8', '#9d5ea8'] };
  view = [800, 400];
  to;
  barPadding = 8;
  legend = true;
  labels = true;
  explodeSlices = false;
  doughnut = false;
  doughnuts = true;
  mutlakSikayet = [];
  kararsizlar = [];
  mutluMusteriler = [];
  selectedRow: any;
  sadikMusteriler = [];
  topRates = [];
  top: any;
  topSikayet = 0;
  topKararsiz = 0;
  topMutlu = 0;
  topSadik = 0;
  musteriListesi;
  show: boolean;
  test: false;
  brandRate;
  graph = false;
  widget5 = {
    currentRange: 'TW',
    xAxis: true,
    yAxis: true,
    gradient: false,
    legend: false,
    showXAxisLabel: false,
    xAxisLabel: 'month',
    showYAxisLabel: false,
    yAxisLabel: 'Isues',
    showDataLabel: false,
    roundDomains: true,
    scheme: {
      domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
    },
    onSelect: (ev) => {
      console.log(ev);
    },
    supporting: {
      currentRange: '',
      xAxis: false,
      yAxis: false,
      gradient: false,
      legend: false,
      showXAxisLabel: false,
      xAxisLabel: 'month',
      showYAxisLabel: false,
      yAxisLabel: 'Isues',
      scheme: {
        domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
      }
    }
  };
  company: any;
  constructor(
    private api: ApiService,
    private afs: AngularFirestore,
    private chooseCompany: ChooseCompanyService,
    public dialog: MatDialog,
    private afm: AngularFireDatabase, ) {
    this.subscription = chooseCompany.getCompany().subscribe(data => {

      this.subscription = chooseCompany.getcompanyId().subscribe((a => {
        this.afs.collection('brands').doc(a.id).collection('categories').ref.where('name', '==', 'Genel').get().then((d => {
          this.brandRate = data.docs[0].data();
        }))
      }));
      this.topRates = [];
      this.company = data;
      api.sqlPost("corporate/userRates", {
        uniqueName: data.uniqueName
      }).map(res => {
        var checkSame = [];
        res.json().forEach((elem, index) => {
          elem.firstname = elem.firstname.trim().toLowerCase();
          elem.firstname = elem.firstname.charAt(0).toUpperCase() + elem.firstname.slice(1);
          elem.lastname = elem.lastname.trim().toLowerCase();
          elem.lastname = elem.lastname.charAt(0).toUpperCase() + elem.lastname.slice(1);

          if (elem.phoneNumber === null) {

            elem.phoneNumber = '-';
          }
          else {
            elem.phoneNumber = parseInt(elem.phoneNumber.replace(/[^0-9\.]/g, ''), 10).toString();
          }

          if (elem.userRate < 4) {
            let test = {
              "name": elem.firstname + " " + elem.lastname,
              "phone": elem.phoneNumber,
              "userRate": elem.userRate
            }
            this.mutlakSikayet.push(test);
          }
          else if (elem.userRate >= 4 && elem.userRate < 7) {
            let test = {
              "name": elem.firstname + " " + elem.lastname,
              "phone": elem.phoneNumber,
              "userRate": elem.userRate
            }
            this.kararsizlar.push(test);
          }
          else if (elem.userRate >= 7 && elem.userRate < 9) {
            let test = {
              "name": elem.firstname + " " + elem.lastname,
              "phone": elem.phoneNumber,
              "userRate": elem.userRate
            }
            this.mutluMusteriler.push(test);
          }
          else if (elem.userRate >= 9 && elem.userRate <= 10) {
            let test = {
              "name": elem.firstname + " " + elem.lastname,
              "phone": elem.phoneNumber,
              "userRate": elem.userRate
            }
            this.sadikMusteriler.push(test);
          }
        });

        var toplam = this.mutluMusteriler.length + this.sadikMusteriler.length;
        this.mutluMusteriler = this.removeDuplicates(this.mutluMusteriler, "name");
        this.mutlakSikayet = this.removeDuplicates(this.mutlakSikayet, "name");
        this.sadikMusteriler = this.removeDuplicates(this.sadikMusteriler, "name");
        this.kararsizlar = this.removeDuplicates(this.kararsizlar, "name");

        if (toplam > this.kararsizlar.length) {
          this.show = true;
        }
        else {
          this.show = false;
        }


        let pieNewEntryGrouped = {
          "name": "Mutlak Şikayetçiler",
          "value": this.mutlakSikayet.length
        }
        this.topRates.push(pieNewEntryGrouped);
        let kararsizlar = {
          "name": "Kararsızlar",
          "value": this.kararsizlar.length
        }
        this.topRates.push(kararsizlar);
        let mutluMusteriler = {
          "name": "Mutlu Müşteriler",
          "value": this.mutluMusteriler.length
        }
        this.topRates.push(mutluMusteriler);
        let sadikMusteriler = {
          "name": "Sadık Müşteriler",
          "value": this.sadikMusteriler.length
        }
        this.topRates.push(sadikMusteriler);
        this.top = this.topRates[0].value + this.topRates[1].value + this.topRates[2].value + this.topRates[3].value;
        this.topSikayet = (100 / this.top) * this.mutlakSikayet.length;
        this.topKararsiz = (100 / this.top) * this.kararsizlar.length;
        this.topMutlu = (100 / this.top) * this.mutluMusteriler.length;
        this.topSadik = (100 / this.top) * this.sadikMusteriler.length;
        this.topRates.forEach(element => {

          if (element.name == 'Mutlak Şikayetçiler') {
            element.yuzde = this.topSikayet;
          }
          else if (element.name == 'Kararsızlar') {
            element.yuzde = this.topKararsiz;
          }
          else if (element.name == 'Mutlu Müşteriler') {
            element.yuzde = this.topMutlu;
          }
          else {
            element.yuzde = this.topSadik;
          }
        });

        this.dataSource = new MatTableDataSource(this.topRates);

      }).subscribe();




    });

  }



  formatter = (value) => Math.round(value);



  ngOnInit() {
    // $(document).ready(function() { 





    this.to = setTimeout(function () {
      var a = parseFloat($("#sikayet-val").val()) || 0;
      var b = parseFloat($("#kararsiz-val").val()) || 0;
      var c = parseFloat($("#mutlu-val").val()) || 0;
      var d = parseFloat($("#sadik-val").val()) || 0;
      $("#mutlak-sikayet").circliful({
        animationStep: 5,
        backgroundColor: '#e8c3bb',
        foregroundColor: '#f28a74',
        fontColor: '#898a8c',
        foregroundBorderWidth: 10,
        backgroundBorderWidth: 15,
        percent: a,
        percentageTextSize: 17,
      });
      $("#kararsiz").circliful({
        animationStep: 5,
        percentageTextSize: 17,
        backgroundColor: '#d4d6d4',
        foregroundColor: '#8a8b8a',
        fontColor: '#898a8c',
        foregroundBorderWidth: 10,
        backgroundBorderWidth: 15,
        percent: b,
      });
      $("#mutlu").circliful({
        animationStep: 5,
        percentageTextSize: 17,
        backgroundColor: '#b2aab7',
        foregroundColor: '#a17cb6',
        fontColor: '#898a8c',
        foregroundBorderWidth: 10,
        backgroundBorderWidth: 15,
        percent: c,
      });
      $("#sadik").circliful({
        animationStep: 5,
        percentageTextSize: 17,
        backgroundColor: '#b8d89c',
        foregroundColor: '#89aa6c',
        fontColor: '#898a8c',
        foregroundBorderWidth: 10,
        backgroundBorderWidth: 15,
        percent: d,
      });
    }, 1000);
    //  });
  }
  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }
  openDialog(row) {
    if (row == 'Mutlak Şikayetçiler') {
   

      row = this.removeDuplicates(this.mutlakSikayet, "name");
    }
    else if (row == 'Kararsızlar') {
      

      row = this.removeDuplicates(this.kararsizlar, "name");
    }
    else if (row == 'Mutlu Müşteriler') {
  

      row = this.removeDuplicates(this.mutluMusteriler, "name");
    }
    else if (row == 'Sadık Müşteriler') {
    

      row = this.removeDuplicates(this.sadikMusteriler, "name");
    }

    /* Açılan dialoga data geçmek için kullanılan bir yöntem.Dialog constructorda @Inject edilerek data taşınmış olur.
    Kullanımı için PopUpListComponent'ın constructorına gözat   */
    this.dialog.open(PopUpComponent, {
      data: row,
      panelClass: 'test-dialog'

    });

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearTimeout(this.to);
  }

}
