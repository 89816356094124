import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '../../../../../../node_modules/@angular/material';
import { fuseAnimations } from '../../../../core/animations';
import { ExcelService } from '../../customer-list/excel.service';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
  animations: fuseAnimations
})
export class PopUpComponent implements OnInit {
  displayedColumns = ['name', 'phone'];
  tableData = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private excelService:ExcelService) {
    
   }

  ngOnInit() {
  }
  exportAsXLSX():void {
    this.data.filter(snap => {
      this.tableData.push({
        "Adı Soyadı": snap.name,
        "Telefon Numarası": snap.phone,
      })
    })
    this.excelService.exportAsExcelFile(this.tableData, 'detay-listesi');
  }

}
