// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    hmr: false,
    domainName: 'https://bigadvice-development.firebaseapp.com/',
    storagePath: 'https://firebasestorage.googleapis.com/v0/b/bigadvice-development.appspot.com/o/brand_logo%2F',
    storagePath2: 'https://firebasestorage.googleapis.com/v0/b/bigadvice-development.appspot.com/o/files%2F'


    // storagePath: 'https://firebasestorage.googleapis.com/v0/b/big-advice.appspot.com/o/brand_logo%2F'

};
