
import { locale as english } from '../sample/i18n/en';
import { locale as turkish } from '../sample/i18n/tr';
import { OnInit, OnDestroy } from '@angular/core';
import { fuseAnimations } from '../../../core/animations';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { ApiService } from '../../../core/services/api.service';
import * as shape from 'd3-shape';
import { Component, ViewEncapsulation } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { NativeDateAdapter, DateAdapter, MatTableDataSource } from "@angular/material";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

export class TurkishDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
      const str = value.split('.');
      if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
        return null;
      }
      return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}


@Component({
  selector: 'app-interaction-posts',
  templateUrl: './interaction-posts.component.html',
  styleUrls: ['./interaction-posts.component.scss'],
  animations: fuseAnimations
})
export class InteractionPostsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['etkilesim', 'adet', 'yüzde'];
  subscription: Subscription;
  form: FormGroup;
  customColors = [{
    name: 'Paylaşımlar',
    value: '#8a8b8a'
  },
  {
    name: 'Beğeniler',
    value: '#f28a74'
  },
  {
    name: 'Yorumlar',
    value: '#7f90a8'
  },
  {
    name: 'Yeniden Paylaşımlar',
    value: '#edf291'
  },
  {
    name: 'Koleksiyona Kaydedilenler',
    value: '#a17cb6'
  }
  ];
  graphDataSec = [];
  widget = {
    currentRange: "",
    xAxis: true,
    yAxis: true,
    gradient: false,
    legend: false,
    showXAxisLabel: false,
    xAxisLabel: 'Days',
    view: ['1000', '400'],
    showYAxisLabel: false,
    yAxisLabel: 'Isues',
    scheme: {
      domain: ['#f28a74', '#a17cb6', '#89aa6c', '#7f90a8', '#8a8b8a', '#edf291']
    },

    onSelect: (ev) => {
      console.log(ev);
    },
    supporting: {
      currentRange: 'D',
      xAxis: false,
      yAxis: false,
      gradient: false,
      legend: false,
      showXAxisLabel: false,
      xAxisLabel: 'Days',
      showYAxisLabel: false,
      yAxisLabel: 'Isues',
      scheme: {
        domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
      },
      curve: shape.curveBasis
    }
  };
  feedback: any;
  graphData = [];
  timeline = true;
  graphicResults = [];
  company: any;
  likes = 0;
  comments = 0;
  reposts = 0;
  totalAct = 0;
  favorites = 0;
  reportModel: any = {};
  postCount = 0;
  dataSource;
  total: any;
  isData = true;

  constructor(private translationLoader: FuseTranslationLoaderService,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private chooseCompany: ChooseCompanyService,
    private dateAdapter: DateAdapter<Date>) {

    this.translationLoader.loadTranslations(english, turkish);
    this.table();
    this.dateAdapter.setLocale('tr');
  }
  onLinkClick(event) {
    if (event.index == 0) {
      this.table();
    }
    if (event.index == 1) {
      this.getByDate();
    }
  }

  table() {
    this.reportModel.startDate = '2018-01-01';
    this.reportModel.endDate = this.dateFormat(new Date());

    var top = 0;
    this.likes = 0;
    this.comments = 0;
    this.reposts = 0;
    this.totalAct = 0;
    this.postCount = 0;
    this.favorites = 0;
    this.graphData = [];
    this.subscription = this.chooseCompany.getCompany().subscribe(data => {


      if (Object.values(data).length > 0) {
        this.company = data;


        this.api.sqlPost('corporate/interactionPosts3', {
          uniqueName: this.company.uniqueName,
          startDate: this.reportModel.startDate,
          endDate: this.reportModel.endDate,
          period: 1
        }).map(res => res.json()).toPromise().then(graphData => {

          graphData.forEach(element => {
            this.postCount += element.postCount;
            this.likes += parseInt(element.likes);
            this.comments += parseInt(element.comments);
            this.favorites += parseInt(element.favorites);
            this.reposts += parseInt(element.reposts);
            this.totalAct += parseInt(element.totalAct);
          });

        }).then(() => {
          var repAct = 0;
          this.api.sqlPost('corporate/interactionPosts3', {
            uniqueName: this.company.uniqueName,
            startDate: this.reportModel.startDate,
            endDate: this.reportModel.endDate,
            period: 2
          }).map(res => res.json()).toPromise().then(graphData => {
            graphData.forEach(element => {
              this.likes += parseInt(element.likes);
              this.comments += parseInt(element.comments);
              this.favorites += parseInt(element.favorites);
              this.reposts += parseInt(element.reposts);
              repAct += parseInt(element.likes) + parseInt(element.comments) + parseInt(element.favorites) + parseInt(element.reposts);
            });
          }).then(() => {
            this.totalAct += repAct;


            // this.totalAct = this.postCount + this.likes + this.comments + this.favorites + this.reposts;
            top = this.totalAct;

            let test = [
              {
                "name": "Paylaşımlar",
                "value": this.postCount,
                "yuzde": (100 / this.totalAct) * this.postCount,
              },
              {
                "name": "Beğeniler",
                "value": this.likes,
                "yuzde": (100 / this.totalAct) * this.likes,
              },
              {
                "name": "Yorumlar",
                "value": this.comments,
                "yuzde": (100 / this.totalAct) * this.comments,
              },
              {
                "name": "Yeniden Paylaşımlar",
                "value": this.reposts,
                "yuzde": (100 / this.totalAct) * this.reposts,
              },
              {
                "name": "Koleksiyona Eklenenler",
                "value": this.favorites,
                "yuzde": (100 / this.totalAct) * this.favorites,
              }
            ];
            test.push({
              "name": "Toplam",
              "value": this.totalAct,
              "yuzde": (100 / this.totalAct) * this.totalAct
            });
            this.dataSource = new MatTableDataSource(test);
          }).then(() => {
            this.getByDate();
          });
        });
      }
    })
  }


  getByDate() {
    this.reportModel.startDate = this.dateFormat(this.reportModel.startDate);
    this.reportModel.endDate = this.dateFormat(this.reportModel.endDate);

    this.likes = 0;
    this.comments = 0;
    this.reposts = 0;
    this.totalAct = 0;
    this.favorites = 0;
    var top = 0;
    this.api.sqlPost('corporate/interactionPosts3', {
      uniqueName: this.company.uniqueName,
      startDate: this.reportModel.startDate,
      endDate: this.reportModel.endDate,
      period: 1
    }).map(res => res.json()).toPromise().then(graphData => {
      this.graphData = graphData;

      graphData.forEach(element => {
        this.postCount += element.postCount;
        this.likes += parseInt(element.likes);
        this.comments += parseInt(element.comments);
        this.favorites += parseInt(element.favorites);
        this.reposts += parseInt(element.reposts);
        this.totalAct += parseInt(element.totalAct);
      });

    }).then(() => {
      this.api.sqlPost('corporate/interactionPosts3', {
        uniqueName: this.company.uniqueName,
        startDate: this.reportModel.startDate,
        endDate: this.reportModel.endDate,
        period: 2
      }).map(res => res.json()).toPromise().then(graphData => {
        this.graphDataSec = graphData;
        graphData.forEach(element => {
          this.likes += parseInt(element.likes);
          this.comments += parseInt(element.comments);
          this.favorites += parseInt(element.favorites);
          this.reposts += parseInt(element.reposts);
        });
      }).then(() => {
        // this.totalAct = this.postCount + this.likes + this.comments + this.favorites + this.reposts;
        // this.total = this.likes + this.comments + this.reposts + this.favorites;
      });
    })


    this.graphDataSec.filter(data => {
      this.graphData.filter(el => {
        if (el.year == data.year && el.month == data.month && el.day == data.day) {
          el.reposts += data.reposts;
          el.likes += data.likes;
          el.comments += data.comments;
          el.favorites += data.favorites;
        }
      })
    })

    let advancedPieData = this.graphData.reduce((result, curr) => {

      let dynamicName;

      dynamicName = curr.day + "/" + curr.month + "/" + curr.year;
      let chartSeries = (result[dynamicName] || []);
      chartSeries.push(
        {
          "name": "Paylaşımlar",
          "value": curr.postCount,
        },
        {
          'name': 'Beğeniler',
          'value': curr.likes
        },
        {
          'name': 'Yorumlar',
          'value': curr.comments
        },
        {
          'name': 'Yeniden Paylaşımlar',
          'value': curr.reposts
        },
        {
          'name': 'Koleksiyona Kaydedilenler',
          'value': curr.favorites
        }
      );
      result[dynamicName] = chartSeries;
      return result;
    }, {});
    let entriesArray = Object.entries(advancedPieData);
    this.graphicResults = [];
    entriesArray.forEach(data => {

      this.graphicResults.push({
        "name": data[0],
        "series": data[1]
      })
    })
  }

  // generateChart() {



  //   this.graphicResults = [];
  //   let list = {
  //     "Beğeniler": [],
  //     "Yorumlar": [],
  //     "Yeniden Paylaşımlar": [],
  //     "Koleksiyona Kaydedilenler": [],
  //     "Toplam Etkileşim": []
  //   }



  //   this.graphData.forEach(data => { 

  //     list["Beğeniler"].push({ name: data.day + '/' + data.month + '/' + data.year, value: data.likes });
  //     list["Yorumlar"].push({ name: data.day + '/' + data.month + '/' + data.year, value: data.comments });
  //     list["Yeniden Paylaşımlar"].push({ name: data.day + '/' + data.month + '/' + data.year, value: data.reposts });
  //     list["Koleksiyona Kaydedilenler"].push({ name: data.day + '/' + data.month + '/' + data.year, value: data.favorites });
  //     list["Toplam Etkileşim"].push({ name: data.day + '/' + data.month + '/' + data.year, value: data.totalAct });
  //   });
  //   Object.entries(list).forEach(values => {

  //     this.graphicResults.push({
  //       name: values[0],
  //       series: values[1]
  //     });
  //   });

  //   if( this.likes == 0 && this.comments ==0 && this.reposts == 0 && this.totalAct ==0 && this.favorites == 0){
  //           console.log("DATA YOK");

  //     this.isData = false;

  //    }else{

  //     this.isData = true;

  //    }

  // }


  onSelect(event) {

  }
  dateFormat(data) {
    if (data.length == 10) {
      return data;
    }
    var dd = data.getDate();
    var mm = data.getMonth() + 1; //January is 0!
    var yyyy = data.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    data = yyyy + '-' + mm + '-' + dd;
    return data;
  }

  ngOnInit() { }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
