import { Component, ViewEncapsulation, NgModule, Inject, ViewChild, OnDestroy } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import * as shape from 'd3-shape';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { ApiService } from '../../../core/services/api.service';
import { fuseAnimations } from '../../../core/animations';
import { NativeDateAdapter, DateAdapter, MatDialogConfig, MatDialog, MatTabChangeEvent, } from "@angular/material";
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { UserService } from '../../../core/services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PopUpTabloComponent } from './pop-up-tablo/pop-up-tablo.component';
import { Subscription } from 'rxjs';

export class TurkishDateAdapter extends NativeDateAdapter {
    parse(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
            const str = value.split('.');
            if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
                return null;
            }
            return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }
}

@Component({
    selector: 'fuse-sample',
    templateUrl: './sample.component.html',
    styleUrls: ['./sample.component.scss'],
    animations: fuseAnimations,
    providers: [{ provide: DateAdapter, useClass: TurkishDateAdapter }],
})
export class FuseSampleComponent implements OnDestroy {
    form: FormGroup;
    formErrors: any;
    subscription: Subscription;
    scheme = { domain: ['#f28a74', '#ef3b2b', '#b0b3b7', '#4a2a72'] };
    view1 = [800, 400];
    view: any[] = [800, 400];
    barPadding = 8;
    legend = true;
    labels = true;
    explodeSlices = false;
    doughnut = false;
    doughnuts = true;

    //deneme ismi değiştir
    deneme = true;
    valueToplam = 0;
    ilkToplam = 0;
    numbers = [];
    top = 0;
    number = 0;

    hashtagsData = [];
    hashtagsData1 = [];

    year: any;
    month: any;
    dt: any;

    customColors = [{
        name: 'şikayetimvar',
        value: '#8a8b8a'
    },
    {
        name: 'bunuçoksevdim',
        value: '#f28a74'
    },
    {
        name: 'markamaönerim',
        value: '#89aa6c'
    },
    {
        name: 'benimtavsiyem',
        value: '#a17cb6'
    }
    ];
    widget5 = {
        currentRange: 'TW',
        xAxis: true,
        yAxis: true,
        gradient: false,
        legend: false,
        showXAxisLabel: false,
        xAxisLabel: 'month',
        showYAxisLabel: false,
        yAxisLabel: 'Isues',
        showDataLabel: false,
        roundDomains: true,
        scheme: {
            domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
        },
        onSelect: (ev) => {
            console.log(ev);
        },
        supporting: {
            currentRange: '',
            xAxis: false,
            yAxis: false,
            gradient: false,
            legend: false,
            showXAxisLabel: false,
            xAxisLabel: 'month',
            showYAxisLabel: false,
            yAxisLabel: 'Isues',
            scheme: {
                domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
            },
            curve: shape.curveBasis
        }
    };
    reportModel: any = {};
    company: any;
    allInterestEntity = [];
    test = [];


    constructor(private translationLoader: FuseTranslationLoaderService,
        private api: ApiService,
        private formBuilder: FormBuilder,
        private chooseCompany: ChooseCompanyService,
        private dateAdapter: DateAdapter<Date>,
        public dialog: MatDialog) {
        this.translationLoader.loadTranslations(english, turkish);

        this.reportModel.startDate = '2018-01-01';
        this.ilkToplam = 0;

        var newDate2 = new Date(new Date().getTime() + Math.abs(new Date().getTimezoneOffset() * 60000));
        this.reportModel.endDate = newDate2.toISOString().split('T')[0];


        this.subscription = chooseCompany.getCompany().subscribe(data => {
            this.number = 0;
            this.hashtagsData = [];
            this.company = data;
            this.numbers = [];
            this.form = this.formBuilder.group({
                entity: [this.company.uniqueName, Validators.required]
            });


            api.sqlPost("corporate/repotByHashtagAllTime", {
                uniqueName: data.uniqueName,
                startDate: this.reportModel.startDate,
                endDate: this.reportModel.endDate
            }).map(res => {
                res.json().forEach((elem, index) => {


                    this.ilkToplam += elem.count;
                    this.numbers.push(elem.count);
                    let pieNewEntryGrouped = {
                        "name": elem.name,
                        "value": elem.count
                    }

                    this.hashtagsData.push(pieNewEntryGrouped);
                    this.reportModel.endDate = newDate2;

                });
                if (this.ilkToplam == 0) {

                    this.deneme = false;
                } else {

                    this.deneme = true;
                }
            }).toPromise().then(() => {

                this.numbers.forEach(data => {
                    this.top += data;
                    this.hashtagsData1.push(Math.round((data / this.top) * 100));
                })


                var toplam = 0;
                this.hashtagsData1.forEach(element => {
                    toplam += element;
                })
                var number = toplam;


                if (number > 100) {
                    do {
                        let index = this.hashtagsData1.indexOf(Math.max(...this.hashtagsData1));
                        this.hashtagsData1[index]--;
                        number++;
                    }
                    while (number === 100);
                }
                else if (number < 100) {
                    do {
                        let index = this.hashtagsData1.indexOf(Math.max(...this.hashtagsData1));
                        this.hashtagsData1[index]++;
                        number--;
                    }
                    while (number === 100);
                }
            });
        });
        this.formErrors = {
            entity: {}
        };
        this.dateAdapter.setLocale('tr');


    }



    formatter = (value) => {
        // value = this.closest(value,this.hashtagsData1);
        return value.toFixed(2);
        // return Math.round(value);

    }

    closest(num, arr) {
        var curr = arr[0];
        var diff = Math.abs(num - curr);
        for (var val = 0; val < arr.length; val++) {
            var newdiff = Math.abs(num - arr[val]);
            if (newdiff < diff) {
                diff = newdiff;
                curr = arr[val];
            }
        }
        return curr;
    }




    // getByDateAndPeriod(period, part?, string?) {

    //     let defaultendDate;
    //     switch (period) {
    //         case 1: defaultendDate = new Date(new Date('04/01/2018').getTime() + (86400000 * 10)); break;
    //         case 2: defaultendDate = new Date(new Date('04/01/2018').getTime() + (86400000 * 365)); break;
    //         case 3: defaultendDate = new Date(new Date('04/01/2018').getTime() + (86400000 * 365)); break;
    //         case 4: defaultendDate = new Date(new Date('04/01/2018').getTime() + (86400000 * 365 * 3)); break;
    //     }

    //     let startDate = '2018-04-01';
    //     let endDate = defaultendDate;
    //     // this.reportModel.startDate = startDate;
    //     // this.reportModel.endDate = endDate;
    //     this.api.sqlPost("corporate/reportByHashtag", {
    //         uniqueName: this.company.uniqueName,
    //         period: period,
    //         startDate: this.reportModel.startDate,
    //         endDate: this.reportModel.endDate
    //     }).map(res => res.json())
    //         .subscribe((data: any[]) => {



    //             this.barPadding = (1000 / data.length)
    //             let advancedPieData = data.reduce((result, curr) => {
    //                 let dynamicName;
    //                 switch (period) {
    //                     case 1: dynamicName = curr.day + "/" + curr.month + "/" + curr.year; break;
    //                     case 2: dynamicName = curr.month + ". Ay/" + curr.year; break;
    //                     case 3: dynamicName = curr.quarter + ". Çeyrek/" + curr.year; break;
    //                     case 4: dynamicName = curr.year; break;
    //                 }
    //                 let chartSeries = (result[dynamicName] || []);
    //                 chartSeries.push({
    //                     'name': curr.name ? curr.name : '',
    //                     'value': curr.count
    //                 });
    //                 result[dynamicName] = chartSeries;

    //                 return result;
    //             }, {});
    //             let entriesArray = Object.entries(advancedPieData);

    //             entriesArray.forEach(data => {
    //                 this.stackedBarArray.push({
    //                     "name": data[0],
    //                     "series": data[1]
    //                 });
    //             });
    //         });
    // }

    getByDate() {


        // var yedekDate = this.reportModel.endDate ;

        // var newDate2 =  new Date( this.reportModel.endDate.getTime() + Math.abs(this.reportModel.endDate.getTimezoneOffset()*60000)   );
        // this.reportModel.endDate = newDate2.toISOString().split('T')[0];


        // if(this.reportModel.startDate == this.reportModel.startDate ){
        //    this.reportModel.startDate = this.reportModel.startDate;

        // }
        // else{
        // var newDate3 =  new Date( this.reportModel.startDate.getTime() + Math.abs(this.reportModel.startDate.getTimezoneOffset()*60000)   );
        // this.reportModel.startDate = newDate3.toISOString().split('T')[0];
        // }
        // this.reportModel.startDate = this.dateFormat(this.reportModel.startDate);
        // this.reportModel.endDate = this.dateFormat(this.reportModel.endDate);

        this.reportModel.startDate = this.dateFormat(this.reportModel.startDate);
        this.reportModel.endDate = this.dateFormat(this.reportModel.endDate);


        this.ilkToplam = 0;

        this.hashtagsData = [];
        this.hashtagsData1 = [];
        this.numbers = [];

        this.api.sqlPost("corporate/repotByHashtagAllTime", {
            uniqueName: this.company.uniqueName,
            startDate: this.reportModel.startDate,
            endDate: this.reportModel.endDate
        }).map(res => {



            res.json().forEach((elem, index) => {
                //    // console.log("Degerler");
                //    // console.log(elem.count);
                this.ilkToplam += elem.count;
                this.numbers.push(elem.count);
                //     console.log("Toplam:");

                let pieNewEntryGrouped = {
                    "name": elem.name,
                    "value": elem.count
                }

                this.hashtagsData.push(pieNewEntryGrouped);

                // this.reportModel.endDate = yedekDate ;

            });
            if (this.ilkToplam == 0) {

                this.deneme = false;

            } else {

                this.deneme = true;

            }


        })
            .toPromise().then(() => {
                this.numbers.forEach(data => {
                    this.top += data;

                    this.hashtagsData1.push(Math.round((data / this.top) * 100));
                })
                var toplam = 0;

                this.hashtagsData1.forEach(element => {
                    toplam += element;
                })
                var number = toplam;


                if (number > 100) {
                    do {
                        let index = this.hashtagsData1.indexOf(Math.max(...this.hashtagsData1));
                        this.hashtagsData1[index]--;
                        number++;
                    }
                    while (number === 100);
                }
                else if (number < 100) {
                    do {
                        let index = this.hashtagsData1.indexOf(Math.max(...this.hashtagsData1));
                        this.hashtagsData1[index]++;
                        number--;
                    }
                    while (number === 100);
                }
            });
    }

    onSelect(event) {

    }

    openDialog() {
        this.dialog.open(PopUpTabloComponent, {
        });
    }


    entityChange(uniqueName) {
        this.allInterestEntity.filter((data, index) => {
            if (data.uniqueName === uniqueName)
                this.chooseCompany.setCompany(data, index)
        })
    }
    tabChanged = (tabChangeEvent: MatTabChangeEvent) => {

    }
    dateFormat(data) {
        if (data.length == 10) {
            return data;
        }
        var dd = data.getDate();
        var mm = data.getMonth() + 1; //January is 0!
        var yyyy = data.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }

        data = yyyy + '-' + mm + '-' + dd;
        return data;
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

