import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FollowerDemographicComponent } from './follower-demographic.component';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AuthGuard } from '../../../_guards/auth.guard';

const routes = [
  {
      path     : 'follower-demographic',
      component: FollowerDemographicComponent,
      canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    NgxChartsModule
  ],
  declarations: [
    FollowerDemographicComponent
  ],
  providers: [
    AuthGuard
  ]
})
export class FollowerDemographicModule { }
