import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import * as firebase from 'firebase';
import { AlertService } from '../../../../core/services/alert.service';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { FuseConfigService } from '../../../../core/services/config.service';
import { fuseAnimations } from '../../../../core/animations';

@Component({
  selector: 'app-corporate-verify',
  templateUrl: './corporate-verify.component.html',
  styleUrls: ['./corporate-verify.component.scss'],
  animations: fuseAnimations
})
export class CorporateVerifyComponent implements OnInit {
  updatePasswordModel: any = {};
  customToken: any;
  oobCode: any;
  loading: boolean;
  formGroup: FormGroup;
  successFullAlert: any;
  alert: any;
  datakey: string;
  staticalertclosed = false;
  userinfo = {
    email: '',
    password: ''
  }
  constructor(private route: ActivatedRoute,
    private afm: AngularFireDatabase,
    private alertService: AlertService,
    private router: Router,
    private formBuilder: FormBuilder,
    private fuseConfig: FuseConfigService, ) {
    this.fuseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });
  }

  ngOnInit() {

    const sub = this.route.queryParams.subscribe(async params => {
      this.customToken = params["customToken"];

      this.afm.list('corporateUsersTokens').$ref.orderByChild('token').equalTo(this.customToken).once('value', snap => {
        if (snap.exists()) {
          snap.forEach(data => {
            this.datakey = data.key;
            this.userinfo = {
              email: data.val().email,
              password: data.val().password
            }
            this.afm.object('corporateUsers/' + data.val().uid + '/isEmailVerified').set(true);
            return false;
          })
        } else {
          console.log('Token yok');
        }
      });
    });

  }

  updatePassword() {
    this.loading = true;
    if (this.updatePasswordModel.password == this.updatePasswordModel.confirmPassword) {
      return firebase.auth().signInWithEmailAndPassword(this.userinfo.email, this.userinfo.password)
        .then(() => {
          return firebase.auth().currentUser.updatePassword(this.updatePasswordModel.password)
            .then(() => {
              return this.afm.object('corporateUsersTokens/' + this.datakey).set(null).then(() => {
                return firebase.auth().signOut().then(() => {
                  this.staticalertclosed = true;
                  this.successFullAlert = 'Şifreniz başarıyla değiştirildi.';
                  this.loading = false;
                  setTimeout(() => {
                    this.router.navigate(['/auth']);
                  }, 3000);
                })
              });
            }).catch(error => {
              this.loading = false;
              console.log(error);
            });
        }).catch(error => {
          this.loading = false;
          console.log(error);
        });
    } else {
      this.loading = false;
      this.alert = 'Şifreniz eşleşmedi!';
    }
  }

}
