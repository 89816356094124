import { NgModule } from '@angular/core';
import { Login2Module } from './login-2/login-2.module';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { CorporateVerifyModule } from './corporate-verify/corporate-verify.module';
@NgModule({
    imports: [
        Login2Module,
        CorporateVerifyModule,
        FormsModule,
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule

    ],
    declarations: []
})
export class PagesModule {
}
