import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileSettingsComponent } from './profile-settings.component';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { DialogComponent } from './dialog/dialog.component';
import { PhotoUploadComponent } from './photo-upload/photo-upload.component';

import { AuthGuard } from '../../../_guards/auth.guard'
import { BannerUploadComponent2 } from './banner-upload2/banner-upload2.component';
import { BannerUploadComponent3 } from './banner-upload3/banner-upload3';
import { RoleGuard } from  '../../../_guards/role.guard';
import { BannerUpload1Component } from './banner-upload1/banner-upload1.component';

const routes = [
  {
      path     : 'profile-settings',
      component: ProfileSettingsComponent,
      canActivate: [AuthGuard,RoleGuard]
  }
];

@NgModule({
  imports     : [
      SharedModule,
      RouterModule.forChild(routes)
  ],
  declarations: [ProfileSettingsComponent, DialogComponent, PhotoUploadComponent],
  entryComponents: [DialogComponent],
  providers: [
    AuthGuard,
    RoleGuard
  ]

})
export class ProfileSettingsModule { }
