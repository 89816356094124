import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { ApiService } from '../../../core/services/api.service';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { fuseAnimations } from '../../../core/animations';

import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { BehaviorSubject ,  Observable, Subscription } from 'rxjs';



@Component({
  selector: 'app-brand-crosscheck',
  templateUrl: './brand-crosscheck.component.html',
  styleUrls: ['./brand-crosscheck.component.scss'],
  animations: fuseAnimations
})
export class BrandCrosscheckComponent implements OnInit,OnDestroy {
  company: any;
  graphicResults = [];
  subscription:Subscription;
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  legendTitle = "test";
  showXAxisLabel = true;
  showYAxisLabel = true;
  yAxisLabel = 'Adet';
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  customColors =  [{
    name:'Sikayet',
    value:'#b0b3b7'
},
{
    name:'Tavsiye',
    value:'#ef3b2b'
},
{
    name:'Öneri',
    value:'#53a6cc'
},
{
    name:'Memnuniyet',
    value:'#4a2a72'
}
];
  view: any[] = [800, 400];
  displayedColumns = ['name', 'firstColumn', 'secondColumn', 'thirdColumn'];
  dataSource: MyDataSource;
  desserts = [{}];
  totalBrand : any;


  constructor(private api: ApiService, private chooseCompany: ChooseCompanyService,private afm:AngularFireDatabase) {
    let list = {
      "Marka Sıralama": [],
      "Paylaşım Sayısı":[],
      "Yüzdeler":[]
    }
    this.subscription = chooseCompany.getCompany().subscribe(data => {
      this.graphicResults = [];
      this.company = data;
      this.desserts = [{}];


    

      
      
      this.subscription = api.sqlPost('corporate/crooscheckHashtagAll3', {
        category: data.category,
        uniqueName: data.uniqueName
      })
      .map(res => res.json()).subscribe(snap => {
        var top = 0;
        snap.forEach(element => {
          if(element.hashtag == "Sikayet"){
            element.hashtag = "Şikayet"
          }
          top += element.postCount;
          this.totalBrand = element.totalBrand;
          this.desserts.push({
            hashtags:        this.capitalizeFirstLetter(element.hashtag),
            markaSiralama:   element.row,
            paylasimSayisi : element.postCount,
            yuzde:           (element.postCount / element.hashTotal) * 100
          });
          
        });
        this.desserts.push({
          hashtags:'Toplam',
          paylasimSayisi:top,
          yuzde:'100'
        })
        this.desserts = this.desserts.slice(1)
        this.dataSource = new MyDataSource(this.desserts);


        
        
        
        
        
      //   this.graphicResults = [];
      //   list["Marka Sıralama"] = [];
      //   list["Paylaşım Sayısı"] = [];
      //   list["Yüzdeler"] = [];
      //   list["Marka Sıralama"].push({ name: snap[0].hashtag , value:snap[0].row},{name:snap[1].hashtag , value:snap[1].row},{ name:snap[2].hashtag , value:snap[2].row },{ name:snap[3].hashtag , value:snap[3].row });
      //   list["Paylaşım Sayısı"].push({ name: snap[0].hashtag , value:snap[0].hashTotal},{name:snap[1].hashtag , value:snap[1].hashTotal},{ name:snap[2].hashtag , value:snap[2].hashTotal }, { name:snap[3].hashtag , value:snap[3].hashTotal });
      //   list["Yüzdeler"].push({ name: snap[0].hashtag , value:snap[0].percent.toFixed(2)},{name:snap[1].hashtag , value:snap[1].percent.toFixed(2)},{ name:snap[2].hashtag , value:snap[2].percent.toFixed(2) },{ name:snap[3].hashtag , value: snap[3].percent.toFixed(2) });
      //   Object.entries(list).forEach(values => {
      //   this.graphicResults.push({
      //     name: values[0],
      //     series: values[1]
      //   });
      // });
      });
    });
  }


  ngOnInit() {
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}



export class MyDataSource extends DataSource<any> {
  constructor(
    private data: any) {
    super();
  }
  _filterChange = new BehaviorSubject('');
  _filteredDataChange = new BehaviorSubject('');

  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this.data,
      this._filterChange
    ];
    return Observable.merge(...displayDataChanges).map(() => {
      let data = this.data.slice();

      this.filteredData = [...data];

      data = this.sortData(data);

      // Grab the page's slice of data.
      const startIndex = 0 * 5;
      return data.splice(startIndex);
    });
  }

  sortData(data): any[] {
    

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

     

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

     // return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }

  disconnect() { }

}