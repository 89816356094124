
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnswerDetailsComponent } from './answer-details.component';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseWidgetModule } from '../../../core/components/widget/widget.module';
import { AuthGuard } from '../../../_guards/auth.guard';
import { RoleGuard } from '../../../_guards/role.guard';

const routes = [
  {
      path     : 'answer-details',
      component: AnswerDetailsComponent,
      
      canActivate: [AuthGuard,RoleGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    NgxChartsModule,
    FuseWidgetModule
  ],
  declarations: [
    AnswerDetailsComponent
  ],
  providers: [
    AuthGuard,
    RoleGuard
  ]
})
export class AnswerDetailsModule { }