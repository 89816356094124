import { NgModule } from '@angular/core';
import { UserCriteriasComponent } from './user-criterias.component';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../../_guards/auth.guard';
import { FuseWidgetModule } from '../../../core/components/widget/widget.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PopUpComponent } from './pop-up/pop-up.component';
import { RoleGuard } from '../../../_guards/role.guard';

const routes = [
  {
      path     : 'user-criterias',
      component: UserCriteriasComponent,
      canActivate: [AuthGuard,RoleGuard]
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    FuseWidgetModule,
    NgxChartsModule
  ],
  declarations: [
    UserCriteriasComponent,
    PopUpComponent
  ],
  entryComponents: [PopUpComponent],
  providers: [
    AuthGuard,
    RoleGuard
  ]
})

export class UserCriteriasModule { }