import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChooseCompanyService } from '../../../../core/services/choose-company.service';
import { FuseUtils } from '../../../../core/fuseUtils';
import { AngularFirestore } from 'angularfire2/firestore';
import * as firebase from 'firebase';
import { UserService } from '../../../../core/services/user.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-dialog2',
  templateUrl: './dialog2.component.html',
  styleUrls: ['./dialog2.component.scss']
})
export class Dialog2Component implements OnInit, OnDestroy {

  form: FormGroup;
  formErrors: any;
  companyIndex = 0;
  company: any;
  temp: any;
  templates = [];
  bannerLinks2: any;
  deleteitem = false;
  saveMessage: any;
  savedButton = false;
  brandId: any;
  subs = new Subscription();
  bannerImage2: any;
  constructor(
    public dialogRef: MatDialogRef<Dialog2Component>,
    private afm: AngularFireDatabase,
    private formBuilder: FormBuilder,
    private userSrw: UserService,
    private afs: AngularFirestore,
    private chooseCompany: ChooseCompanyService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.formErrors = {
      company: {},
      main: {},
      password: {},
      currentPassword: {},
      issue: {},
      city: {},
      state: {},
      postalCode: {}
    };
    chooseCompany.activeCompanyIndex.subscribe(index => this.companyIndex = index);
    this.subs.add(chooseCompany.getCompany().subscribe(company => {
      this.company = company;
      this.templates = company.templates || [];

    }));
    this.subs.add(chooseCompany.getcompanyId().subscribe(dataId => {
      this.brandId = dataId;
    }))



  }

  close() {
    this.dialogRef.close()
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      company: [
        {
          value: 'Google',
          disabled: true
        }, Validators.required
      ],
      main: ["", Validators.required]
    });

    this.form.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    });

    this.afs.collection('brands').doc(this.brandId.id).ref.get().then((data => {
      if (data.data().bannerLink2) {
        this.bannerLinks2 = data.data().bannerLink2;
      }
      if (data.data().bannerImage3) {
        this.bannerImage2 = data.data().bannerImage2;
      }
    }))

  }

  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.formErrors[field] = {};

      // Get the control
      const control = this.form.get(field);

      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }


  send() {
    let main = this.form.get('main').value;
    this.bannerLinks2 = main;
    this.afs.collection('brands').doc(this.brandId.id).update({ bannerLink2: main });

    this.deleteitem = false;
    this.savedButton = true;
    this.form.reset();
  }


  delete() {
    this.deleteitem = true;
    this.afs.collection('brands').doc(this.brandId.id).update({ bannerLink2: firebase.firestore.FieldValue.delete() });
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }


}
