import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AngularFireDatabase } from 'angularfire2/database';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { fuseAnimations } from '../../../core/animations';
import { UserService } from '../../../core/services/user.service';
import { Subscription } from 'rxjs';
import { DialogComponent } from '../profile-settings/dialog/dialog.component';
import { AngularFirestore } from 'angularfire2/firestore';


@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
    animations: fuseAnimations
})
export class SupportComponent implements OnInit, OnDestroy {
    model = [
        {
            'id': 'feedback-home',
            'title': 'Anasayfa',
            'type': 'item',
            'icon': 'feedback-home.png',
            'url': '/feedback-home',
            'role': '/business/professional/proplus'
        },
        {
            'id': 'mdy',
            'title': 'Müşteri Deneyim Yönetimi',
            'type': 'collapse',
            'icon': 'list',
            'children': [

                {
                    'id': 'post-reply',
                    'title': 'Cevapla',
                    'type': 'item',
                    'icon': 'reply.png',
                    'url': '/post-reply',
                    'role': '/business/professional/proplus'
                },
                {
                    'id': 'sample',
                    'title': 'Hashtag Kırılım Analizi',
                    'type': 'item',
                    'icon': 'hashtag-kirilim.png',
                    'url': '/sample',
                    'role': '/business/professional/proplus'
                },

                {
                    'id': 'answer-details',
                    'title': 'Marka Skor Analizi',
                    'type': 'item',
                    'icon': 'answer-details.png',
                    'url': '/answer-details',
                    'role': '/business/professional/proplus'
                },
                {
                    'id': 'customer-list',
                    'title': 'Müşteri Listesi',
                    'type': 'item',
                    'icon': 'customer-list.png',
                    'url': '/customer-list',
                    'role': '/professional/proplus'
                },
                {
                    'id': 'answer-templates',
                    'title': 'Şablonlar',
                    'type': 'item',
                    'icon': 'templates.png',
                    'url': '/answer-templates',
                    'role': '/business/professional/proplus'
                },
                {
                    'id': 'search-by-tag',
                    'title': 'Etiketler',
                    'type': 'item',
                    'icon': 'interactions.png',
                    'url': '/search-by-tag',
                    'role': '/business/professional/proplus'
                },
                {
                    'id': 'tags-details',
                    'tile': 'Etiket Analizi',
                    'type': 'item',
                    'icon': 'tags.png',
                    'url': '/tags-details',
                    'role': '/business/professional/proplus'


                },
                {
                    'id': 'word-details',
                    'tile': 'Kelime Analizi',
                    'type': 'item',
                    'icon': 'tags.png',
                    'url': '/keyword-list',
                    'role': '/business/professional/proplus'


                }
            ]
        },
        {
            'id': 'ry',
            'title': 'Rekabet Analizi',
            'type': 'collapse',
            'icon': 'list',
            'children': [
                {
                    'id': 'brand-crosscheck-all',
                    'title': 'Genel Sıralama',
                    'type': 'item',
                    'icon': 'rivals-brand-crosscheck.png',
                    'url': '/brand-crosscheck-all',
                    'role': '/business/professional/proplus'
                },
                {
                    'id': 'brand-crosscheck',
                    'title': 'Sektör Sıralama',
                    'type': 'item',
                    'icon': 'brand-crosscheck.png',
                    'url': '/brand-crosscheck',
                    'role': '/professional/proplus'
                },
                {
                    'id': 'rivals-crosscheck',
                    'title': 'Rekabet Sıralama',
                    'type': 'item',
                    'icon': 'rivals-crosscheck.png',
                    'url': '/rivals-crosscheck',
                    'role': '/proplus'
                },
                {
                    'id': 'rivals-speeds-of-reply',
                    'title': 'Cevaplama Hızı Rakipler',
                    'type': 'item',
                    'icon': 'rivals-speed-of-reply.png',
                    'url': '/rivals-speeds-of-reply',
                    'role': '/proplus'
                },
                {
                    'id': 'competetion',
                    'title': 'Tıklama Oranı Analizi',
                    'type': 'item',
                    'icon': 'competition-analysis.png',
                    'url': '/competetion',
                    'role': '/proplus'
                }
            ]
        },
        {
            'id': 'mde',
            'title': 'Performans Analizi',
            'type': 'collapse',
            'icon': 'list',
            'children': [
                {
                    'id': 'brand-criterias',
                    'title': 'Marka Değerlendirme',
                    'type': 'item',
                    'icon': 'brand-crosscheck.png',
                    'url': '/brand-criterias',
                    'role': '/professional/proplus'
                },
                {
                    'id': 'user-criterias',
                    'title': 'Müşteri Segmentasyonu',
                    'type': 'item',
                    'icon': 'brand-crosscheck.png',
                    'url': '/user-criterias',
                    'role': '/proplus'
                }
            ]
        },
        {
            'id': 'me',
            'title': 'Etkileşim Analizi',
            'type': 'collapse',
            'icon': 'list',
            'children': [
                {
                    'id': 'interaction-posts',
                    'title': 'Etkileşimler',
                    'type': 'item',
                    'icon': 'interactions.png',
                    'url': '/interaction-posts',
                    'role': '/business/professional/proplus'
                },
                {
                    'id': 'top-ten-hashtags',
                    'title': 'İlk 10 Hashtag',
                    'type': 'item',
                    'icon': 'interactions.png',
                    'url': '/top-ten-hashtags',
                    'role': '/business/professional/proplus'
                },
                {
                    'id': 'best-interactions-posts',
                    'title': 'İlk 5 Post',
                    'type': 'item',
                    'icon': 'interactions.png',
                    'url': '/best-interactions-posts',
                    'role': '/business/professional/proplus'
                }
            ]
        },
        {
            'id': 'support',
            'title': 'Destek',
            'type': 'item',
            'icon': 'support.png',
            'url': '/support',
            'role': '/business/professional/proplus'
        }
    ];
    form: FormGroup;
    subscription: Subscription;
    formErrors: any;
    mains = ["Teknik Destek", "Paylaşım İçeriği", "Kullanıcı Tanımlama ve İptali", "Diğer"]
    company: any;
    constructor(
        private formBuilder: FormBuilder,
        private afm: AngularFireDatabase,
        private afs: AngularFirestore,
        private dialog: MatDialog,
        private chooseCompany: ChooseCompanyService,
        private user: UserService,


    ) {
        // const itemRef = this.afm.object('corporateUsers/GX9xrgK6MpTcxKLOSj73AVlAJit2/list');
        // itemRef.set(this.model);
        // var test = this.user.getUid();
        // // const itemRef = this.afm.object("corporateUsers/" + test  + "/list");
        // // itemRef.set(this.model);

        this.formErrors = {
            company: {},
            main: {},
            password: {},
            currentPassword: {},
            issue: {},
            city: {},
            state: {},
            postalCode: {}
        };
        this.subscription = chooseCompany.getCompany().subscribe(data => {
            this.company = data;
        });
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            company: [
                {
                    value: 'Google',
                    disabled: true
                }, Validators.required
            ],
            main: ['', Validators.required],
            // password: ['', Validators.required],
            // currentPassword: ['', Validators.required]
            issue: ['', Validators.required],
            // city      : ['', Validators.required],
            // state     : ['', Validators.required],
            // postalCode: ['', [Validators.required, Validators.maxLength(5)]]
        });

        this.subscription = this.form.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.formErrors[field] = {};

            // Get the control
            const control = this.form.get(field);

            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    send() {

        let issue = this.form.get('issue').value; // to get value in input tag
        let main = this.form.get('main').value; // to get value in input tag

        let message = {
            message: issue,
            main: main,
            brand: this.company.name,
            uniqeuName: this.company.uniqueName
        }
        this.afs.collection('supportMessages').add(message);
        let dialogRef = this.dialog.open(DialogComponent, {
            panelClass: 'my-class-1',
            width: '500px',
            data: {
                message: "Mesajınız tarafımıza ulaşmıştır. En kısa zamanda ilgili konu değerlendirmeye alınıp, tarafınıza dönüş sağlanacaktır."
            }
        });
        this.form.reset();

    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
