import { Component, OnInit, OnDestroy } from '@angular/core';
import { fuseAnimations } from '../../../core/animations';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { ApiService } from '../../../core/services/api.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { FuseTranslationLoaderService } from '../../../core/services/translation-loader.service';
import { NativeDateAdapter, DateAdapter } from "@angular/material";
import { Subscription } from 'rxjs';

export class TurkishDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
      if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
          const str = value.split('.');
          if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
              return null;
          }
          return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
      }
      const timestamp = typeof value === 'number' ? value : Date.parse(value);
      return isNaN(timestamp) ? null : new Date(timestamp);
  }
}

@Component({
  selector: 'app-best-interactions-posts',
  templateUrl: './best-interactions-posts.component.html',
  styleUrls: ['./best-interactions-posts.component.scss'],
  animations: fuseAnimations,
  providers: [{ provide: DateAdapter, useClass: TurkishDateAdapter }]
})
export class BestInteractionsPostsComponent implements OnInit,OnDestroy {
  subscription:Subscription;
  company: any;
  hitPosts = [];
  reportModel: any = {};

  constructor(private chooseCompany: ChooseCompanyService, 
              private api: ApiService,
              private dateAdapter: DateAdapter<Date>,
              private translationLoader: FuseTranslationLoaderService
  ) { 
    this.translationLoader.loadTranslations(english, turkish);
    this.reportModel.startDate = '2018-01-01';
    var newDate2 =  new Date( new Date().getTime() + Math.abs(new Date().getTimezoneOffset()*60000)   );
    this.reportModel.endDate = newDate2.toISOString().split('T')[0];
    
    this.subscription = chooseCompany.getCompany().subscribe(data => {
      this.hitPosts = [];
      this.company = data;
      this.subscription = api.sqlPost('corporate/topFivePosts', {
        uniqueName: data.uniqueName,
        startDate:this.reportModel.startDate,
        endDate:this.reportModel.endDate
      }).map(res => res.json()).subscribe(posts => {
        posts.forEach(element => {
          var date = new Date(element.modifyDate);
          var day = String(date.getDate());
          var month = String(date.getMonth() + 1) ;
          var year = String(date.getFullYear());
          if(day.length < 2){ day = '0' + day;}
          if(month.length < 2){month = '0' + month}
          element.modifyDate = day + '/' + month + '/' + year;
          
          
          this.hitPosts.push({
            $key: element.postKey,
            firstname: element.firstname,
            lastname: element.lastname,
            innerText: element.text,
            likes: element.likes,
            reposts: element.reposts,
            comments: element.comments,
            favorites:element.favorites,
            modifyDate : element.modifyDate,
            total: element.total
          });
          this.reportModel.endDate = newDate2 ;
        });        
      });
   
      
    });
    this.dateAdapter.setLocale('tr');
  }
  getByDate() {
    this.reportModel.startDate = this.dateFormat(this.reportModel.startDate);
    this.reportModel.endDate = this.dateFormat(this.reportModel.endDate);
    this.subscription = this.chooseCompany.getCompany().subscribe(data => {
      this.hitPosts = [];
      this.company = data;
      this.subscription = this.api.sqlPost('corporate/topFivePosts', {
        uniqueName: data.uniqueName,
        startDate:this.reportModel.startDate,
        endDate:this.reportModel.endDate
      }).map(res => res.json()).subscribe(posts => {
        posts.forEach(element => {
          var date = new Date(element.modifyDate);
          var day = String(date.getDate());
          var month = String(date.getMonth() + 1) ;
          var year = String(date.getFullYear());
          if(day.length < 2){ day = '0' + day;}
          if(month.length < 2){month = '0' + month}
          element.modifyDate = day + '/' + month + '/' + year;
          
          
          this.hitPosts.push({
            $key: element.postKey,
            firstname: element.firstname,
            lastname: element.lastname,
            innerText: element.text,
            likes: element.likes,
            favorites:element.favorites,
            reposts: element.reposts,
            comments: element.comments,
            modifyDate : element.modifyDate,
            total: element.total
          });
        });        
      });
      
    });

  }
  dateFormat(data){
    if(data.length == 10){
        return data;
    }
    var dd = data.getDate();
    var mm = data.getMonth() + 1; //January is 0!
    var yyyy = data.getFullYear();
     
    if (dd < 10) {
     dd = '0' + dd;
   }
   if (mm < 10) {
     mm = '0' + mm;
   }

   data = yyyy + '-' + mm + '-' + dd;
   return data;
 }

  ngOnInit() {

  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

}
