import { Component, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import * as shape from 'd3-shape';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { fuseAnimations } from '../../../core/animations';
@Component({
  selector: 'app-follower-demographic',
  templateUrl: './follower-demographic.component.html',
  styleUrls: ['./follower-demographic.component.scss'],
  animations: fuseAnimations
})
export class FollowerDemographicComponent {
  
  scheme = { domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72'] };
  viewPie: any[] = [400, 250];
  viewBar: any[] = [400, 250];
  reportModel: any = {};
  legend = true;
  labels = true;
  explodeSlices = false;
  doughnut = false;
  gender = [];
  ages = [];
  city = [];
  education = [];
  widget5 = {
    xAxis: true,
    yAxis: true,
    gradient: false,
    legend: true,
    showXAxisLabel: false,
    xAxisLabel: 'Days',
    showYAxisLabel: false,
    yAxisLabel: 'Isues',
    scheme: {
      domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
    },
    onSelect: (ev) => {
      console.log(ev);
    },
    supporting: {
      curve: shape.curveBasis
    }
  };
  company: any;
  allInterestEntity = [];
  form: FormGroup;
  formErrors: any;

  constructor(private api: ApiService,
    private formBuilder: FormBuilder,
    private chooseCompany: ChooseCompanyService) {

    chooseCompany.getCompany().subscribe(data => {
      chooseCompany.getCompanyProducts().subscribe(arr => {
        this.allInterestEntity = Object.values(arr);
      });
      this.ages = [];
      this.gender = [];
      this.company = data;
      this.form = this.formBuilder.group({
        entity: [this.company.uniqueName, Validators.required]
      });
      this.reportModel.startDate = new Date(Date.now() - (86400000 * 30));
      this.reportModel.endDate = new Date(Date.now());
      this.generateChart(this.reportModel.startDate, this.reportModel.endDate, 1);
      this.generateChart(this.reportModel.startDate, this.reportModel.endDate, 2);
      this.generateChart(this.reportModel.startDate, this.reportModel.endDate, 3);
      this.generateChart(this.reportModel.startDate, this.reportModel.endDate, 4);
    });
    this.formErrors = {
      entity: {}
    };

  }

  generateColumn = (data, type) => {
    return {
      name: data[type] ? data[type] : 'Bilinmiyor',
      value: data.count
    }
  }

  generateChart(startDate, endDate, period) {
    this.api.sqlPost("corporate/brandDemographicByFollowers", {
      uniqueName: this.company.uniqueName,
      startDate: startDate,
      endDate: endDate,
      period: period
    }).map(res => res.json()).subscribe((data: any[]) => {
      if (period === 1) this.ages = data.map(column => this.generateColumn(column, "age"))
      if (period === 2) this.education = data.map(column => this.generateColumn(column, "education"));
      if (period === 3) this.gender = data.map(column => this.generateColumn(column, "gender"));
      if (period === 4) this.city = data.map(column => this.generateColumn(column, "city"));
    });
  }


  getByDate() {
    this.ages = [];
    this.gender = [];
    this.generateChart(this.reportModel.startDate, this.reportModel.endDate, 1);
    this.generateChart(this.reportModel.startDate, this.reportModel.endDate, 2);
    this.generateChart(this.reportModel.startDate, this.reportModel.endDate, 3);
    this.generateChart(this.reportModel.startDate, this.reportModel.endDate, 4);
  }

  // entityChange(uniqueName) {
  //   let filteredEntity = this.allInterestEntity.filter(data => {
  //     return data.uniqueName === uniqueName
  //   })
  //   this.chooseCompany.setCompany(filteredEntity[0])
  // }

}
