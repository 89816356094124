import { Pipe, PipeTransform } from '@angular/core';
import { BadwordsService } from '../services/badword.service';
@Pipe({
  name: 'replaceBadwords'
})
export class ReplaceBadwordsPipe implements PipeTransform {
  constructor(private badwordService: BadwordsService) { }

  transform(value: any, args?: any): any {
    if (value == null) {
      return
    }
    else {
      return this.badwordService.getBadwords().map(badwors => {


        badwors.forEach(badword => {

          let pattern = new RegExp('(\b|^|[^A-Za-z0-9\-\_ğüşöçıİĞÜŞÖÇ])(' + badword + ')(\b|$|[^A-Za-z0-9\-\_ğüşöçıİĞÜŞÖÇ])', 'gi');
          let pattern1 = new RegExp('(\b|^|[^A-Za-z0-9\-\_ğüşöçıİĞÜŞÖÇ])(' + badword.toUpperCase() + ')(\b|$|[^A-Za-z0-9\-\_ğüşöçıİĞÜŞÖÇ])', 'gi');
          let pattern2 = new RegExp('(\b|^|[^A-Za-z0-9\-\_ğüşöçıİĞÜŞÖÇ])(' + badword.replace(/\s/g, '') + ')(\b|$|[^A-Za-z0-9\-\_ğüşöçıİĞÜŞÖÇ])', 'gi');
          let replacement = this.repeat(badword.length);
          let replacement2 = this.repeat(badword.replace(/\s/g, '').length);
          value = value.replace(pattern, replacement).replace(/İ/g, 'I');
          value = value.replace(pattern1, replacement).replace(/İ/g, 'I');
          value = value.replace(pattern2, replacement2).replace(/İ/g, 'I');
        });
        return value;
      })
    }
  }
  repeat(num) {
    return new Array(num + 1).join('*');
  }

}
