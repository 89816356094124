import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { fuseAnimations } from '../../../core/animations';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { ApiService } from '../../../core/services/api.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from './dialog/dialog.component';
import{ DialogComponent1 } from './dialog1/dialog1.component';
import { Observable, Subscription } from '../../../../../node_modules/rxjs';
import { FormControl } from '../../../../../node_modules/@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete } from '@angular/material';
import { map, startWith } from '../../../../../node_modules/rxjs/operators';

@Component({
  selector: 'app-search-by-tag',
  templateUrl: './search-by-tag.component.html',
  styleUrls: ['./search-by-tag.component.scss'],
  animations: fuseAnimations
})
export class SearchByTagComponent implements OnInit,OnDestroy {
  input = '';
  subscription:Subscription;
  posts = [];
  company: any;
  myControl = new FormControl();
  options = [];
  filteredOptions: Observable<string[]>;

  constructor(private chooseCompany: ChooseCompanyService, public dialog: MatDialog, private api: ApiService) {
    this.subscription = chooseCompany.getCompany().subscribe(data => {
      this.posts = [];
      this.company = data;
      this.subscription = this.api.sqlPost('corporate/reportTag', { uniqueName: data.uniqueName }).map(res => res.json()).subscribe(tags => {
        tags.forEach(element => {
          this.options.push(element.name)
        });
      })
      this.subscription = api.sqlPost('corporate/searchByTag', { searchBytag: '', uniqueName: data.uniqueName }).map(res => res.json()).subscribe(post => {
        
        post.forEach(element => {
          // this.options.push(element.searchBytag);
          this.posts.push({
            $key: element.postKey,
            firstname: element.firstname,
            lastname: element.lastname,
            innerText: element.text,
            likes: element.likes,
            reposts: element.reposts,
            comments: element.comments,
            total: element.total,
          });

        });
      });
    });
  }
  displayFn(user?): string | undefined {
    return user ? user : undefined;
  }
  trackByFn(index, item) {
    return index; // or item.id
  }
  ngOnInit() {
    this.subscription = this.api.sqlPost('corporate/reportTag', { uniqueName: this.company.uniqueName }).map(res => res.json()).subscribe(tags => { 
      tags.forEach(element => {

      });
    
    })
    this.subscription = this.myControl.valueChanges.subscribe(data => {
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      if (data.length >= 3) {
        this.options = [];
        this.posts = [];
        this.subscription = this.api.sqlPost('corporate/reportTag', { uniqueName: this.company.uniqueName }).map(res => res.json()).subscribe(tags => { 
          tags.forEach(element => {
            this.options.push(element.name)
          });
        
        })
        this.subscription = this.api.sqlPost('corporate/searchByTag', { searchBytag: data, uniqueName: this.company.uniqueName }).map(res => res.json()).subscribe(post => {

          post.forEach(element => {
            
           
            // console.log(this.options);
            
            this.posts.push({
              $key: element.postKey,
              firstname: element.firstname,
              lastname: element.lastname,
              innerText: element.text,
              likes: element.likes,
              reposts: element.reposts,
              comments: element.comments,
              total: element.total
            });

          });
        });
        
      }
      else if (data.length == 0) {
        this.options = [];
        this.posts = [];
        this.subscription =this.api.sqlPost('corporate/reportTag', { uniqueName: this.company.uniqueName }).map(res => res.json()).subscribe(tags => { 
          tags.forEach(element => {
            this.options.push(element.name)
          });
        })
        this.subscription = this.api.sqlPost('corporate/searchByTag', { searchBytag: data, uniqueName: this.company.uniqueName }).map(res => res.json()).subscribe(post => {
          post.forEach(element => {
            this.posts.push({
              $key: element.postKey,
              firstname: element.firstname,
              lastname: element.lastname,
              innerText: element.text,
              likes: element.likes,
              reposts: element.reposts,
              comments: element.comments,
              total: element.total
            });

          });

        });
       
      }


    })


  }
  // inputKeyUp() {
  //   this.posts = [];
  //   this.api.sqlPost('corporate/searchByTag', { searchBytag: this.input, uniqueName: this.company.uniqueName }).map(res => res.json()).subscribe(post => {

  //     post.forEach(element => {
  //       this.options.push(element.searchBytag)
  //       this.posts.push({
  //         $key: element.postKey,
  //         firstname: element.firstname,
  //         lastname: element.lastname,
  //         innerText: element.innerText,
  //         likes: element.likes,
  //         reposts: element.reposts,
  //         comments: element.comments,
  //         total: element.total
  //       });

  //     });

  //   });

  // }

  displayReport() {
    let dialogRef = this.dialog.open(DialogComponent, {
      panelClass: 'report-tag-dialog',
      height: '75%',
      width: '70%',
      data: {
        post: 'this.post'
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.includes(filterValue));

  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  openModel(){
    let dialogRef1=this.dialog.open(DialogComponent1,{
    

    });

  }

}
