import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../core/modules/shared.module';
import { ApiService } from '../../../core/services/api.service';

import { FuseSampleComponent } from './sample.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AuthGuard } from '../../../_guards/auth.guard';
import { RoleGuard } from '../../../_guards/role.guard';
import { PopUpTabloComponent } from './pop-up-tablo/pop-up-tablo.component';

const routes = [
    {
        path     : 'sample',
        component: FuseSampleComponent,
        canActivate: [AuthGuard,RoleGuard]
    }
];

@NgModule({
    declarations: [
        FuseSampleComponent,
        PopUpTabloComponent
    ],
    imports     : [
        SharedModule,
        RouterModule.forChild(routes),
        NgxChartsModule
    ],
    exports     : [
        FuseSampleComponent,
        PopUpTabloComponent
    ],
    providers: [
        ApiService,
        AuthGuard,
        RoleGuard
    ],
    entryComponents: [ 
        PopUpTabloComponent    
    ]
})

export class FuseSampleModule
{

}
