import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userRate'
})
export class UserRatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (Math.round(Number(value) / 2) > 4){
      return "#00f900";
    }
    switch (Math.round(Number(value) / 2)) {
      case 0:
        return "#FF0010";
      case 1:
        return "#FF5a00";
      case 2:
        return "#FFe600";
      case 3:
        return "#b0ff00";
      case 4:
        return "#17ff00";
      default:
        break;
    }
  }

}
