import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import * as firebase from 'firebase';
import { AngularFirestore } from 'angularfire2/firestore';



@Injectable()
export class UserService {
  user = new BehaviorSubject<any>({});
  userId;

  // userId = localStorage.getItem('currentUserId');


  constructor(private afm: AngularFireDatabase,
    private afs: AngularFirestore
  ) {
    this.userId = localStorage.getItem('currentUserId');

    if (this.userId) {
      this.setUser(this.userId);
    }
  }
  getUid() {
    return this.userId;
  }
  clearUser() {
    localStorage.clear();
    this.userId = null;
    this.user.next({});
  }
  getShortUser() {
    return firebase.auth().currentUser;
  }
  setUser(uid) {

    this.afs.collection('corporateUsers').doc(uid).ref.get().then((user => {
      this.user.next(user.data());
    }))
  }

  getUser() {
    return this.user.asObservable();
  }
}
