import { NgModule } from '@angular/core';
import { BestInteractionsPostsComponent } from './best-interactions-posts.component';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../../_guards/auth.guard';
import { RoleGuard } from '../../../_guards/role.guard';
import { FuseWidgetModule } from '../../../core/components/widget/widget.module';

const routes = [
  {
      path     : 'best-interactions-posts',
      component: BestInteractionsPostsComponent,
      canActivate: [AuthGuard,RoleGuard]
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    FuseWidgetModule
  ],
  declarations: [
    BestInteractionsPostsComponent
  ],
  providers: [
    AuthGuard,
    RoleGuard
  ]
})

export class BestInteractionsPostsModule { }
