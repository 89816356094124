import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { CorporateVerifyComponent } from '../corporate-verify/corporate-verify.component';
import { AlertComponent } from '../../../../core/directives/alert/alert.component';
import { MatFormFieldModule, MatLabel, MatInputModule } from '@angular/material';

const routes = [
    {
        path     : 'corporateverify',
        component: CorporateVerifyComponent,
    },
];

@NgModule({
    declarations: [
        CorporateVerifyComponent,
        AlertComponent
    ],
    imports     : [
        SharedModule,
        RouterModule.forChild(routes),
        MatFormFieldModule,
        MatInputModule
    ]
})

export class CorporateVerifyModule
{

}
