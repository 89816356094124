import { Component, OnInit, Input } from '@angular/core';
import { ViewEncapsulation, NgModule, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';
import * as shape from 'd3-shape';
import { locale as english } from '../i18n/en';
import { locale as turkish } from '../i18n/tr';
import { ApiService } from '../../../../core/services/api.service';
import { fuseAnimations } from '../../../../core/animations';
import { NativeDateAdapter, DateAdapter, MatSelect } from "@angular/material";
import { ChooseCompanyService } from '../../../../core/services/choose-company.service';
import { UserService } from '../../../../core/services/user.service';
import { FormGroup, FormBuilder, Validators,FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';

export class TurkishDateAdapter extends NativeDateAdapter {
    parse(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
            const str = value.split('.');
            if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
                return null;
            }
            return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }
}


@Component({
    selector: 'app-pop-up-tablo',
    templateUrl: './pop-up-tablo.component.html',
    styleUrls: ['./pop-up-tablo.component.scss'],
    animations: fuseAnimations,
    providers: [{ provide: DateAdapter, useClass: TurkishDateAdapter }],
})
export class PopUpTabloComponent implements OnInit {
    form: FormGroup;
    formErrors: any;
    scheme = { domain: ['#53a6cc', '#ef3b2b', '#b0b3b7', '#4a2a72'] }
    view1 = [800, 400];
    view: any[] = [700, 400];
    barPadding = 8;
    legend = true;
    labels = true;
    explodeSlices = false;
    doughnut = false;
    doughnuts = true;
    periodData:any;

    isData = true;
    valueToplam = 0;
    ilkToplam = 0;
    months = [
        {
        name:'Ocak',
        value:1
        },
        {
        name:'Şubat',
        value:2
        },
        {
        name:'Mart',
        value:3
        },
        {
        name:'Nisan',
        value:4
        },
        {
        name:'Mayıs',
        value:5
        },
        {
        name:'Haziran',
        value:6
        },
        {
        name:'Temmuz',
        value:7
        },
        {
        name:'Ağustos',
        value:8
        },
        {
        name:'Eylül',
        value:9
        },
        {
        name:'Ekim',
        value:10
        },
        {
        name:'Kasım',
        value:11
        },
        {
        name:'Aralık',
        value:12
        }
    ]
    years = [
    {
        name:2018
    },
    {
        name:2019
    },
    {
        name:2020
    },
    ]
    stackedBarArray = [];
    customColors = [{
        name:'şikayetimvar',
        value:'#8a8b8a'
    },
    {
        name:'bunuçoksevdim',
        value:'#f28a74'
    },
    {
        name:'markamaönerim',
        value:'#89aa6c'
    },
    {
        name:'benimtavsiyem',
        value:'#a17cb6'
    }
    ];
    tooltipTemplate = '#fff';
    widget5 = {
        currentRange: 'TW',
        xAxis: true,
        yAxis: true,
        gradient: false,
        legend: false,
        showXAxisLabel: false,
        xAxisLabel: 'month',
        showYAxisLabel: false,
        yAxisLabel: 'Isues',
        showDataLabel: false,
        roundDomains: true,
        scheme: {
            domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
        },
        onSelect: (ev) => {
            console.log(ev);
        },
        supporting: {
            currentRange: '',
            xAxis: false,
            yAxis: false,
            gradient: false,
            legend: false,
            showXAxisLabel: false,
            xAxisLabel: 'month',
            showYAxisLabel: false,
            yAxisLabel: 'Isues',
            scheme: {
                domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
            },
            curve: shape.curveBasis
        }
    };
    reportModel: any = {};
    company: any;
    allInterestEntity = [];

    constructor(private translationLoader: FuseTranslationLoaderService,
        private api: ApiService,
        private formBuilder: FormBuilder,
        private chooseCompany: ChooseCompanyService,
        private dateAdapter: DateAdapter<Date>,
        public dialog: MatDialog) {
        this.translationLoader.loadTranslations(english, turkish);
        this.reportModel.startDate = '2018-01-01';
        this.reportModel.endDate = (new Date().toISOString().split('T'))[0];

        chooseCompany.getCompany().subscribe(data => {
            this.stackedBarArray = [];

            this.company = data;
            //     this.form = this.formBuilder.group({
            //         entity: [this.company.uniqueName, Validators.required]
            //     });

            //         console.log(this.reportModel.startDate);


            //     api.sqlPost("corporate/reportByHashtag", {
            //         uniqueName: data.uniqueName,
            //         period: 1,
            //         startDate: this.reportModel.startDate,
            //         endDate: this.reportModel.endDate
            //     }).map(res => res.json())
            //         .subscribe((data: any[]) => {
            //             this.barPadding = (1000 / data.length)
            //             let advancedPieData = data.reduce((result, curr) => {
            //                 let chartSeries = (result[curr.day + "/" + curr.month + "/" + curr.year] || []);

            //               //this.valueToplam += curr.count;
            //              // console.log("Dizinin birinci elemanı");
            //                 chartSeries.push({
            //                     'name': curr.name ? curr.name : '',
            //                     'value': curr.count
            //                 });
            //                 result[curr.day + "/" + curr.month + "/" + curr.year] = chartSeries;
            //                 return result;

            //             }, {});
            //             let entriesArray = Object.entries(advancedPieData);

            //              entriesArray.forEach(data => {
            //               this.stackedBarArray.push({
            //                     "name": data[0],
            //                     "series": data[1]
            //                 });

            //             });
            //         });
            //     //////////////////



            // });
            // this.formErrors = {
            //     entity: {}
        });
        this.dateAdapter.setLocale('tr');
        this.getByDateAndPeriod(2, 'month', 'ay');




    }


    formatter = (value) => value.toFixed(2);


    getByDateAndPeriod(period, part?, string?) {
        this.isData = true;
        this.stackedBarArray = [];
        this.valueToplam = 0;
        let defaultendDate;
        switch (period) {
            case 1: defaultendDate = new Date(new Date('04/01/2018').getTime() + (86400000 * 10)); break;
            case 2: defaultendDate = new Date(new Date('04/01/2018').getTime() + (86400000 * 365)); break;
            case 3: defaultendDate = new Date(new Date('04/01/2018').getTime() + (86400000 * 365)); break;
            case 4: defaultendDate = new Date(new Date('04/01/2018').getTime() + (86400000 * 365 * 3)); break;
        }
        this.periodData = period;
        if(period == 1){
            let startDate = new Date();
            startDate.setDate(startDate.getDate() - 30);
            this.reportModel.startDate = this.dateFormat(startDate);
            let endDate = new Date();
            this.reportModel.endDate = this.dateFormat(endDate);
        }
        else {
            this.reportModel.startDate = '2018-01-01';
            this.reportModel.endDate = (new Date().toISOString().split('T'))[0];
        }
        let startDate = '2018-04-01';
        let endDate = defaultendDate;
        // this.reportModel.startDate = startDate;
        // this.reportModel.endDate = endDate;
        this.api.sqlPost("corporate/reportByHashtag", {
            uniqueName: this.company.uniqueName,
            period: period,
            startDate: this.reportModel.startDate,
            endDate: this.reportModel.endDate
        }).map(res => res.json())
            .subscribe((data: any[]) => {
                this.barPadding = (1000 / data.length)
                let advancedPieData = data.reduce((result, curr) => {

                    let dynamicName;
                    switch (period) {
                        
                        case 1: dynamicName = curr.day + "/" + curr.month + "/" + curr.year; break;
                        case 2: 
                        if(curr.month == 1) curr.month = 'Ocak'
                        if(curr.month == 2) curr.month = 'Şubat'
                        if(curr.month == 3) curr.month = 'Mart'
                        if(curr.month == 4) curr.month = 'Nisan'
                        if(curr.month == 5) curr.month = 'Mayıs'
                        if(curr.month == 6) curr.month = 'Haziran'
                        if(curr.month == 7) curr.month = 'Temmuz'
                        if(curr.month == 8) curr.month = 'Ağustos'
                        if(curr.month == 9) curr.month = 'Eylül'
                        if(curr.month == 10) curr.month = 'Ekim'
                        if(curr.month == 11) curr.month = 'Kasım'
                        if(curr.month == 12) curr.month = 'Aralık'
                        dynamicName = curr.month + " " + curr.year; break;
                        case 3: dynamicName = curr.quarter + ". Çeyrek/" + curr.year; break;
                        case 4: dynamicName = curr.year; break;
                    }
                    
                    let chartSeries = (result[dynamicName] || []);
                    this.valueToplam += curr.count;
                    chartSeries.push({
                        'name': curr.name ? curr.name : '',
                        'value': curr.count
                    });
                    result[dynamicName] = chartSeries;

                    return result;
                }, {});
                let entriesArray = Object.entries(advancedPieData);

                if (this.valueToplam == 0) {
                    this.isData = false;
                } else {
                    this.isData = true;
                }

                entriesArray.forEach(data => {
                    this.stackedBarArray.push({
                        "name": data[0],
                        "series": data[1]
                    });
                });
                
            });
    }

    getByDate() {
        this.valueToplam = 0;
        this.stackedBarArray = [];
        this.isData = true;

        this.api.sqlPost("corporate/reportByHashtag", {
            uniqueName: this.company.uniqueName,
            period: 2,
            startDate: this.reportModel.startDate,
            endDate: this.reportModel.endDate
        }).map(res => res.json())
            .subscribe((data: any[]) => {
                this.barPadding = (1000 / data.length)
                let advancedPieData = data.reduce((result, curr) => {
                    let chartSeries = (result[curr.month + ". Ay/" + curr.year] || []);

                    this.valueToplam += curr.count;

                    chartSeries.push({
                        'name': curr.name ? curr.name : '',
                        'value': curr.count
                    });
                    result[curr.month + ". Ay/" + curr.year] = chartSeries;

                    return result;
                }, {});
                let entriesArray = Object.entries(advancedPieData);
                if (this.valueToplam == 0) {
                    this.isData = false;
                } else {
                    this.isData = true;
                }
                entriesArray.forEach(data => {
                    this.stackedBarArray.push({
                        "name": data[0],
                        "series": data[1]
                    });
                });
            });

    }

    getByDay(startDate,endDate) {

        this.valueToplam = 0;
        this.stackedBarArray = [];
        this.isData = true;

        this.api.sqlPost("corporate/reportByHashtag", {
            uniqueName: this.company.uniqueName,
            period: 1,
            startDate: startDate,
            endDate: endDate
        }).map(res => res.json())
            .subscribe((data: any[]) => {
                this.barPadding = (1000 / data.length)
                let advancedPieData = data.reduce((result, curr) => {
                    let dynamicName;
                    dynamicName = curr.day + "/" + curr.month + "/" + curr.year;
                    let chartSeries = (result[dynamicName] || []);
                    this.valueToplam += curr.count;
                    chartSeries.push({
                        'name': curr.name ? curr.name : '',
                        'value': curr.count
                    });
                    result[dynamicName] = chartSeries;

                    return result;
                }, {});
                let entriesArray = Object.entries(advancedPieData);

                if (this.valueToplam == 0) {
                    this.isData = false;
                } else {
                    this.isData = true;
                }
                
                entriesArray.forEach(data => {
                    this.stackedBarArray.push({
                        "name": data[0],
                        "series": data[1]
                    });
                });
            });
            
    }

    onSelect(event) {
        console.log(event);
    }




    entityChange(uniqueName) {
        this.allInterestEntity.filter((data, index) => {
            if (data.uniqueName === uniqueName)
                this.chooseCompany.setCompany(data, index)
        })
    }

    ngOnInit() {
    }
    dateFormat(data){
        var dd = data.getDate();
        var mm = data.getMonth() + 1; //January is 0!
        var yyyy = data.getFullYear();
         
        if (dd < 10) {
         dd = '0' + dd;
       }
       if (mm < 10) {
         mm = '0' + mm;
       }
    
       data = yyyy + '-' + mm + '-' + dd;
       return data;
     }
     getDaysInMonth(month,year) {
         //ayı güne çeviriyor.
        // Here January is 1 based
        //Day 0 is the last day in the previous month
       return new Date(year, month, 0).getDate();
      // Here January is 0 based
      // return new Date(year, month+1, 0).getDate();
      };

      selectValue(month,year){
          //select valueleri alınıyor.
          var day = this.getDaysInMonth(month,year);
          var firstDay = day - (day-1);
          if (firstDay < 10) {
            var lastFirstDay = '0' + firstDay;
          }
          if (month < 10) {
            month = '0' + month;
          }
          var startDate = year + '-' + month + '-' + lastFirstDay;
          var endDate = year + '-' + month + '-' + day;
          this.getByDay(startDate,endDate);
      }


}
