
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsDetailsComponent } from './tags-details.component';
import { SharedModule } from '../../../core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseWidgetModule } from '../../../core/components/widget/widget.module';
import { AuthGuard } from '../../../_guards/auth.guard';
import { RoleGuard } from '../../../_guards/role.guard';

const routes = [
  {
      path     : 'tags-details',
      component: TagsDetailsComponent,
      
      canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    NgxChartsModule,
    FuseWidgetModule
  ],
  declarations: [
    TagsDetailsComponent
  ],
  providers: [
    AuthGuard
  ]
})
export class TagsDetailsModule { }