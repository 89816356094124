import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fuseAnimations } from '../../../../core/animations';
import * as shape from 'd3-shape';
import { ApiService } from '../../../../core/services/api.service';
import { Graphic } from '../../../../core/interfaces/graphic.interface';
import { ChooseCompanyService } from '../../../../core/services/choose-company.service';
import { NativeDateAdapter, DateAdapter } from "@angular/material";
import { locale as english } from '../../sample/i18n/en';
import { locale as turkish } from '../../sample/i18n/tr';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';


import { UserService } from '../../../../core/services/user.service';


import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';

export class TurkishDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
      if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
          const str = value.split('.');
          if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
              return null;
          }
          return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
      }
      const timestamp = typeof value === 'number' ? value : Date.parse(value);
      return isNaN(timestamp) ? null : new Date(timestamp);
  }
}

@Component({
  selector: 'app-score-change',
  templateUrl: './score-change.component.html',
  styleUrls: ['./score-change.component.scss'],
  animations: fuseAnimations,
  providers: [{ provide: DateAdapter, useClass: TurkishDateAdapter }],
})
export class ScoreChangeComponent implements OnInit {
  scheme = { domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72'] };
  viewPie: any[] = [800, 250];
  view: any[] = [800, 350];
  reportModel: any = {};
  legend = true;
  labels = true;
  explodeSlices = false;
  doughnut = false;
  entityData = [];
  speedOfReplyInfo = [];
  company: any;
  feedback: any;
  afterReplyRate = [];

  revize : any;
  toppost: any;
  oran : any;

  ilkToplam:number = 0;
  deneme = true;

  widget5 = {
    timeline : false,
    currentRange: 'TW',
    xAxis: true,
    yAxis: true,
    gradient: false,
    legend: false,
    showXAxisLabel: false,
    xAxisLabel: 'Days',
    showYAxisLabel: false,
    yAxisLabel: 'Isues',
    scheme: {
      domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
    },
    onSelect: (ev) => {
      console.log(ev);
    },
    supporting: {
      currentRange: '',
      xAxis: false,
      yAxis: false,
      gradient: false,
      legend: false,
      showXAxisLabel: false,
      xAxisLabel: 'Days',
      showYAxisLabel: false,
      yAxisLabel: 'Isues',
      scheme: {
        domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
      },
      curve: shape.curveBasis
    }
  };
  

  constructor(private api: ApiService,
    private userService: UserService,
    private chooseCompany: ChooseCompanyService,
    private translationLoader: FuseTranslationLoaderService,
    private dateAdapter: DateAdapter<Date>,
    public dialog: MatDialog
    ) {
      this.feedback = 0 ;
      this.oran = 0 ;
      this.translationLoader.loadTranslations(english, turkish);
      this.dateAdapter.setLocale('tr');
      chooseCompany.getCompany().subscribe(data => {
        api.sqlPost('corporate/reportFeedbackHome', {
           uniqueName: data.uniqueName 
          }).map(res => res.json()).subscribe(feedback => {
          this.feedback = feedback[0];
        });
      });

     
      
  }

  ngOnInit() {
    
    

    this.chooseCompany.getCompany().subscribe(data => {
      this.company = data;
      if (data) {
        // this.api.sqlPost('corporate/reportByAnswerPosts', {
        //   uniqueName: data.uniqueName
        // }).map(res => {
        //   res.json().forEach((elem, index) => {
        //     let answered = {
        //       name: 'Cevaplanan',
        //       value: elem.answerCount
        //     }
        //     let notAnswered = {
        //       name: 'Cevaplanmayan',
        //       value: elem.count - elem.answerCount
        //     }
        //     this.entityData.push(answered, notAnswered);
        //   });
        // }).subscribe();

        // this.api.sqlPost('corporate/speedOfReply', {
        //   uniqueName: data.uniqueName
        // }).map(res => {
        //   res.json().forEach(elem => {
        //     let vertNewEntry = {
        //       name: elem.year + '/' + elem.month + '. Hafta',
        //       value: elem.hour/elem.count
        //     }
        //     this.speedOfReplyInfo.push(vertNewEntry);
        //   });
        // }).subscribe();


        ////
        this.reportModel.startDate = '2018-01-01';
       // this.reportModel.endDate = new Date(Date.now());

        var newDate2 =  new Date((new Date().getTime()) + Math.abs(new Date().getTimezoneOffset()*60000));
        this.reportModel.endDate = newDate2.toISOString().split('T')[0];

        this.api.sqlPost('corporate/afterReplyRate2', {
          uniqueName: data.uniqueName,
          startDate: '2018-04-01',
          endDate:  this.reportModel.endDate
        }).map(res => res.json()).subscribe(data => {
        let list = {
              "Eski Puan":[],
              "Revize Puan":[]
            }
            data.forEach(elem => {
            list["Eski Puan"].push({name: elem.day + '/' + elem.month + '/' + elem.year , value: elem.oldPoint });
            list["Revize Puan"].push({name: elem.day + '/' + elem.month + '/' + elem.year , value: elem.newPoint });
          });
          this.afterReplyRate = [];
            Object.entries(list).forEach(element => {
              this.afterReplyRate.push({
                name: element[0],
                series: element[1]
              });
            });
      });
      this.reportModel.endDate = newDate2;
      var newDate2 =  new Date(new Date().getTime());
      
    }
    });
}

getByDate() {
    
  var lastDate = this.reportModel.endDate;
  
  this.ilkToplam = 0; 
  

  var newDate2 =  new Date( this.reportModel.endDate.getTime() + Math.abs(this.reportModel.endDate.getTimezoneOffset()*60000)   );
  this.reportModel.endDate = newDate2.toISOString().split('T')[0];
  
  this.chooseCompany.getCompany().subscribe(data => {
    
    this.api.sqlPost('corporate/afterReplyRate2', {
      uniqueName: data.uniqueName,
      startDate: this.reportModel.startDate,
      endDate: this.reportModel.endDate
    }).map(res =>{
      
      let list = {
        "Eski Puan":[],
        "Revize Puan":[]
        }

      res.json().forEach((elem, index) => {

       
        
        
        this.ilkToplam += elem.newPoint;

       
        

        
      
      
        list["Eski Puan"].push({name: elem.day + '/' + elem.month + '/' + elem.year , value: elem.oldPoint });
        list["Revize Puan"].push({name: elem.day + '/' + elem.month + '/' + elem.year , value: elem.newPoint });
        
        }); 

        if(this.ilkToplam == 0){
           
             this.deneme = false;
             
            }else{
                
                this.deneme = true;
               
            }


      this.afterReplyRate = [];
        Object.entries(list).forEach(element => {
          this.afterReplyRate.push({
            name: element[0],
            series: element[1]
          });
        });
       
        
        this.reportModel.endDate = lastDate;

      
    }).subscribe();
  });
} 




}
