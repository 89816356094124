import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { fuseAnimations } from '../../../core/animations';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { ApiService } from '../../../core/services/api.service';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-tags-details',
  templateUrl: './tags-details.component.html',
  styleUrls: ['./tags-details.component.scss'],
  animations: fuseAnimations
})
export class TagsDetailsComponent implements OnInit, OnDestroy {
  tooltipHtml = '';
  widget = {
    currentRange: "",
    xAxis: true,
    yAxis: true,
    gradient: false,
    legend: false,
    showXAxisLabel: true,
    xAxisLabel: 'Etiketler',
    view: ['500', '300'],
    showYAxisLabel: true,
    yAxisLabel: 'Yüzdeler',
    scheme: {
      domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72', '#AAAAAA']
    },
    onSelect: (ev) => {
      console.log(ev);
      this.tooltipHtml = ev.series + "%" + ev.value;
    }
  };
  feedback: any;
  subscription: Subscription;
  brandRate: any;
  user: any;
  company: any;
  sayac = 0;
  hashInfo = [];
  uniq: any;
  hashInfoArray = [];
  customColors = [{
    name: 'Şikayet',
    value: '#8a8b8a'
  },
  {
    name: 'Memnuniyet',
    value: '#f28a74'
  },
  {
    name: 'Öneri',
    value: '#89aa6c'
  },
  {
    name: 'Tavsiye',
    value: '#a17cb6'
  },
  {
    name: 'Diger',
    value: '#7f90a8'
  }
  ];
  brand = [];
  hashModel = [
    { uniqueName: 'benimtavsiyem', name: 'Tavsiye Paylaşım Oranı' },
    { uniqueName: 'markamaönerim', name: 'Öneri Paylaşım Oranı' },
    { uniqueName: 'bunuçoksevdim', name: 'Memnuniyet Paylaşım Oranı' }
  ];
  reportModel: any = {};
  cardHour = 0;
  cardMinute = 0;
  displayedColumns = ['name', 'firstColumn', 'secondColumn'];
  dataSource;
  desserts = [];
  total = 0;
  totalPost = 0;
  totalCount = 0;
  reposts;
  tagPercent;
  show = true;
  noneData = true;
  constructor(private chooseCompany: ChooseCompanyService,
    private afm: AngularFireDatabase,
    private api: ApiService,
    private cd: ChangeDetectorRef) {
    // this.afm.list('brand/samsung/timeline').$ref.once('value',snap => {
    //   var a = Object.keys(snap.val());
    //   console.log(a.length);
    // })

    this.reportModel.startDate = '2018-01-01';
    var newDate2 = new Date(new Date().getTime() + Math.abs(new Date().getTimezoneOffset() * 60000));
    this.reportModel.endDate = newDate2.toISOString().split('T')[0];

    this.subscription = chooseCompany.getCompany().subscribe(data => {
      if (data.uniqueName) {

        this.subscription = this.api.sqlPost('corporate/interactionPosts2', {
          uniqueName: data.uniqueName,
          startDate: this.reportModel.startDate,
          endDate: this.reportModel.endDate,
          period: 1
        }).map(res => res.json()).subscribe(graphData => {
          graphData.forEach(element => {
            this.totalPost += parseInt(element.postCount)
            this.total += parseInt(element.totalAct)
          })
        })



        this.show = false;
        this.uniq = data.uniqueName;
     


        api.sqlPost("corporate/getCorporateTagReport", {
          uniqueName: data.uniqueName,
          startDate: '2018-01-01',
          endDate: this.reportModel.endDate
        })
          .map(res => res.json()).toPromise().then((data1: any) => {

            data1.forEach(element1 => {
              this.totalCount = element1.tagedCount;

            })
            if (data.uniqueName) {
              if (data1.length === 0) {
                this.show = false;
                this.noneData = false;
              }
              else {
                this.noneData = true;
              }

              let name = ' ';
              let advancedPieData = data1.reduce((result, curr) => {

                let dynamicName;
                dynamicName = curr.tag;
                var chartSeries = (result[dynamicName] || []);


                chartSeries.push({
                  'name': name,
                  'value': curr.percentTag
                });
                name = name + ' ';

                result[dynamicName] = chartSeries;
                return result;
              }, {});
              this.cd.markForCheck();

              let entriesArray = Object.entries(advancedPieData);

              entriesArray.forEach(data => {
                this.hashInfo.push({
                  "name": data[0],
                  "series": data[1],
                  "tooltipText": data[0]
                })
                this.cd.markForCheck();

              })

              this.cd.markForCheck();
            }
          })
        this.cd.markForCheck();
      }


    });
    this.cd.markForCheck();
  }


  ngOnInit() {



  }
  formatDate(date) {
    if (date.length == 10) {
      return date;
    }
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  getByDate() {
    this.reportModel.startDate = this.formatDate(this.reportModel.startDate);
    this.reportModel.endDate = this.formatDate(this.reportModel.endDate);
    this.show = true;
    this.totalPost = 0;
    this.totalCount = 0;
   
    this.subscription = this.api.sqlPost('corporate/interactionPosts2', {
      uniqueName: this.uniq,
      startDate: this.reportModel.startDate,
      endDate: this.reportModel.endDate,
      period: 1
    }).map(res => res.json()).subscribe(graphData => {
      graphData.forEach(element => {
        this.totalPost += parseInt(element.postCount)
        this.total += parseInt(element.totalAct)
      })
    })




    this.api.sqlPost("corporate/getCorporateTagReport", {
      uniqueName: this.uniq,
      startDate: this.reportModel.startDate,
      endDate: this.reportModel.endDate
    })
      .map(res => res.json()).toPromise().then((data1: any) => {
        data1.forEach(element1 => {
          this.totalCount = element1.tagedCount;

        })
        if (data1.length === 0) {
          this.show = false;
          this.noneData = false;
        }
        else {
          this.noneData = true;
        }


        let name = ' ';
        let advancedPieData = data1.reduce((result, curr) => {

          let dynamicName;
          dynamicName = curr.tag;
          var chartSeries = (result[dynamicName] || []);



          chartSeries.push({
            'name': name,
            'value': curr.percentTag
          });

          name = name + ' ';
          result[dynamicName] = chartSeries;
          return result;
        }, {});

        let entriesArray = Object.entries(advancedPieData);


        
        this.hashInfo = [];
        entriesArray.forEach(data => {
          this.hashInfo.push({
            "name": data[0],
            "series": data[1]
          })

          this.show = false;



        })
        this.cd.markForCheck();

      })



  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }



}

