import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '../../../core/animations';
import { FuseNavigationService } from '../../../core/components/navigation/navigation.service';

@Component({
  selector: 'app-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss'],
  animations: fuseAnimations
})
export class PricingPageComponent implements OnInit {
  test;
  constructor(private navigateService:FuseNavigationService) {
    this.navigateService.test.subscribe(data => {

      
      this.test = data;
    })

   }

  ngOnInit() {
  }

}
