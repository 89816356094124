export class NavigationModel {
    public model: any[];

    constructor() {
        this.model = [
            // {

            //             'id'      : 'dashboards',
            //             'title'   : 'Müşteri Geri Bildirim Yönetimi',
            //             'type'    : 'collapse',
            //             'icon'    : 'list',
            //             'children': [
            {
                'id': 'feedback-home',
                'title': 'Anasayfa',
                'type': 'item',
                'icon': 'feedback-home.png',
                'url': '/feedback-home',
                'role': '/business/professional/proplus'
            },
            {
                'id': 'mdy',
                'title': 'Müşteri Deneyim Yönetimi',
                'type': 'collapse',
                'icon': 'list',
                'children': [

                    {
                        'id': 'post-reply',
                        'title': 'Cevapla',
                        'type': 'item',
                        'icon': 'reply.png',
                        'url': '/post-reply',
                        'role': '/business/professional/proplus'
                    },
                    // {
                    //     'id': 'sample',
                    //     'title': 'Hashtag Kırılım Analizi',
                    //     'type': 'item',
                    //     'icon': 'hashtag-kirilim.png',
                    //     'url': '/sample',
                    //     'role': '/business/professional/proplus'
                    // },
                    // {
                    //     'id'   : 'hashtag',
                    //     'title': 'Hashtag Demografik',
                    //     'type' : 'item',
                    //     'icon' : 'hashtag-demographic.png',
                    //     'url'  : '/hashtag-demographic',
                    //     'role': '/mid/prof'
                    // },
                    // {
                    //     'id'   : 'follower',
                    //     'title': 'Takipçi Demografik',
                    //     'type' : 'item',
                    //     'icon' : 'followers-demographic.png',
                    //     'url'  : '/follower-demographic',
                    //     'role': '/mid/prof'
                    // },
                    {
                        'id': 'answer-details',
                        'title': 'Marka Skor Analizi',
                        'type': 'item',
                        'icon': 'answer-details.png',
                        'url': '/answer-details',
                        'role': '/business/professional/proplus'
                    },
                    // {
                    //     'id'   : 'score',
                    //     'title': 'Skor Analizi',
                    //     'type' : 'item',
                    //     'icon' : 'score-change.png',
                    //     'url'  : '/score-change',
                    //     'role': '/mid/prof'
                    // },
                    {
                        'id': 'customer-list',
                        'title': 'Müşteri Listesi',
                        'type': 'item',
                        'icon': 'customer-list.png',
                        'url': '/customer-list',
                        'role': '/professional/proplus'
                    },
                    {
                        'id': 'answer-templates',
                        'title': 'Şablonlar',
                        'type': 'item',
                        'icon': 'templates.png',
                        'url': '/answer-templates',
                        'role': '/business/professional/proplus'
                    },
                    {
                        'id': 'search-by-tag',
                        'title': 'Etiketler',
                        'type': 'item',
                        'icon': 'tags.png',
                        'url': '/search-by-tag',
                        'role': '/business/professional/proplus'
                    },
                    {
                        'id': 'tags-details',
                        'title': 'Etiket Analizi',
                        'type': 'item',
                        'icon': 'answer-details.png',
                        'url': '/tags-details',
                        'role': '/business/professional/proplus'
                    },
                    {
                        'id': 'word-details',
                        'tile': 'Kelime Analizi',
                        'type': 'item',
                        'icon': 'answer-details.png',
                        'url': '/keyword-list',
                        'role': '/business/professional/proplus'


                    }
                    // {
                    //     'id'   : 'support',
                    //     'title': 'Destek',
                    //     'type' : 'item',
                    //     'icon' : 'support.png',
                    //     'url'  : '/support',
                    //     'role': '/business/professional/proplus'
                    // }
                ]
            },
            {
                'id': 'ry',
                'title': 'Rekabet Analizi',
                'type': 'collapse',
                'icon': 'list',
                'children': [
                    {
                        'id': 'brand-crosscheck-all',
                        'title': 'Genel Sıralama',
                        'type': 'item',
                        'icon': 'rivals-brand-crosscheck.png',
                        'url': '/brand-crosscheck-all',
                        'role': '/business/professional/proplus'
                    },
                    {
                        'id': 'brand-crosscheck',
                        'title': 'Sektör Sıralama',
                        'type': 'item',
                        'icon': 'brand-crosscheck.png',
                        'url': '/brand-crosscheck',
                        'role': '/professional/proplus'
                    },
                    {
                        'id': 'rivals-crosscheck',
                        'title': 'Rekabet Sıralama',
                        'type': 'item',
                        'icon': 'rivals-crosscheck.png',
                        'url': '/rivals-crosscheck',
                        'role': '/proplus'
                    },

                    // {
                    //     'id'   : 'reply-list',
                    //     'title': 'Cevaplama Hızı',
                    //     'type' : 'item',
                    //     'icon' : 'speed-of-reply.png',
                    //     'url'  : '/reply-list',
                    //     'role': '/business/professional/proplus'
                    // },
                    {
                        'id': 'rivals-speeds-of-reply',
                        'title': 'Cevaplama Hızı Rakipler',
                        'type': 'item',
                        'icon': 'rivals-speed-of-reply.png',
                        'url': '/rivals-speeds-of-reply',
                        'role': '/proplus'
                    },
                    {
                        'id': 'competetion',
                        'title': 'Tıklama Oranı Analizi',
                        'type': 'item',
                        'icon': 'competition-analysis.png',
                        'url': '/competetion',
                        'role': '/proplus'
                    }
                ]
            },
            //     ]
            // },
            // {
            //     'id'      : 'mb',
            //     'title'   : 'Marka Bildirimleri',
            //     'type'    : 'collapse',
            //     'icon'    : 'list',
            //     'children': [
            //         {
            //             'id'   : 'brand-hashtags',
            //             'title': 'M.B. Hashtag Oranları',
            //             'type' : 'item',
            //             'icon' : 'interactions.png',
            //             'url'  : '/brand-hashtags',
            //             'role': '/business/professional/proplus'
            //         }
            //     ]
            // },
            {
                'id': 'mde',
                'title': 'Performans Analizi',
                'type': 'collapse',
                'icon': 'list',
                'children': [
                    {
                        'id': 'brand-criterias',
                        'title': 'Marka Değerlendirme',
                        'type': 'item',
                        'icon': 'brand-crosscheck.png',
                        'url': '/brand-criterias',
                        'role': '/professional/proplus'
                    },
                    {
                        'id': 'user-criterias',
                        'title': 'Müşteri Segmentasyonu',
                        'type': 'item',
                        'icon': 'user-criterias.png',
                        'url': '/user-criterias',
                        'role': '/proplus'
                    }
                ]
            },
            {
                'id': 'me',
                'title': 'Marka Etkileşim Analizi',
                'type': 'collapse',
                'icon': 'list',
                'children': [
                    {
                        'id': 'interaction-posts',
                        'title': 'Etkileşimler',
                        'type': 'item',
                        'icon': 'interactions.png',
                        'url': '/interaction-posts',
                        'role': '/business/professional/proplus'
                    },
                    {
                        'id': 'top-ten-hashtags',
                        'title': 'İlk 10 Hashtag',
                        'type': 'item',
                        'icon': 'interactions.png',
                        'url': '/top-ten-hashtags',
                        'role': '/business/professional/proplus'
                    },
                    {
                        'id': 'best-interactions-posts',
                        'title': 'İlk 5 Post',
                        'type': 'item',
                        'icon': 'interactions.png',
                        'url': '/best-interactions-posts',
                        'role': '/business/professional/proplus'
                    }
                ]
            },
            {
                'id': 'settings',
                'title': 'Ayarlar',
                'type': 'item',
                'icon': 'support.png',
                'children': [
                    {
                        'id': 'account',
                        'title': 'Hesap Ayarları',
                        'type': 'item',
                        'icon': 'settings_ikon-01.jpg',
                        'url': '/profile-settings',
                        'role': '/business/professional/proplus'

                    },
                    {
                        'id': 'banner',
                        'title': 'Banner Alanları',
                        'type': 'item',
                        'icon': 'settings_ikon-01.jpg',
                        'url': '/banner-upload',
                        'role': '/business/professional/proplus'
                    }

                ]

            },
            {
                'id': 'support',
                'title': 'Destek',
                'type': 'item',
                'icon': 'support.png',
                'url': '/support',
                'role': '/business/professional/proplus'
            }
        ];
    }
}
