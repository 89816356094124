import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { ApiService } from '../../../core/services/api.service';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { fuseAnimations } from '../../../core/animations';

@Component({
  selector: 'app-speed-of-reply-list',
  templateUrl: './speed-of-reply-list.component.html',
  styleUrls: ['./speed-of-reply-list.component.scss'],
  animations: fuseAnimations
})
export class SpeedOfReplyListComponent implements OnInit {
  bestList = [];
  company: any;
  constructor(private afm: AngularFireDatabase, private api: ApiService, private chooseCompany: ChooseCompanyService) {
    chooseCompany.getCompany().subscribe(data => {
      this.company = data;
      api.sqlPost('corporate/speedOfReplyList', {category : data.category}).map(res => res.json()).subscribe(list => {
        list.forEach(element => {
          if(data.uniqueName == element.name){
          this.bestList = element;
          }
        });
        
      })
    })
   }

  ngOnInit() {
  }

}
