import { Component, Input, OnDestroy, ViewEncapsulation, NgZone } from '@angular/core';
import { FuseNavigationService } from './navigation.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AngularFireDatabase } from '../../../../../node_modules/angularfire2/database';
import { AngularFirestore } from 'angularfire2/firestore';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseNavigationComponent implements OnDestroy {
    navigationModel: any[];
    navigationModelChangeSubscription: Subscription;

    @Input('layout') layout = 'vertical';

    constructor(private fuseNavigationService: FuseNavigationService, private router: Router, private afs: AngularFirestore, private afm: AngularFireDatabase, private zone: NgZone) {
        // this.navigationModelChangeSubscription =
        //     this.fuseNavigationService.onNavigationModelChange
        //         .subscribe((navigationModel) => { 
        //             this.navigationModel = navigationModel;
        //         });

        // databaseden list çekme
        const uid = localStorage.getItem('currentUserId');
        if (uid) {
            this.navigationModelChangeSubscription = this.afs.collection('corporateUsers').doc(uid).valueChanges().subscribe(data => {
                this.navigationModel = data['list'];
            })
        }

    }

    ngOnDestroy() {
        if (this.navigationModelChangeSubscription) {
            this.navigationModelChangeSubscription.unsubscribe();
        }
    }

}
