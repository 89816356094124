import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { fuseAnimations } from '../../../core/animations';
import { ChooseCompanyService } from '../../../core/services/choose-company.service';
import { ApiService } from '../../../core/services/api.service';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { Subscription } from 'rxjs';
import { AngularFirestore } from 'angularfire2/firestore';


@Component({
  selector: 'app-feedback-home',
  templateUrl: './feedback-home.component.html',
  styleUrls: ['./feedback-home.component.scss'],
  animations: fuseAnimations
})
export class FeedbackHomeComponent implements OnInit, OnDestroy {
  widget = {
    currentRange: "",
    xAxis: true,
    yAxis: true,
    gradient: false,
    legend: false,
    showXAxisLabel: false,
    xAxisLabel: 'Days',
    view: ['800', '400'],
    showYAxisLabel: false,
    yAxisLabel: 'Isues',
    scheme: {
      domain: ['#dd5346', '#57397c', '#ef3b2b', '#4a2a72']
    },
    onSelect: (ev) => {
      console.log(ev);
    }
  };
  feedback: any;
  subscription: Subscription[] = [];
  brandRate: any;
  user: any;
  company: any;
  sayac = 0;
  hashInfo = [];
  customColors = [{
    name: 'Şikayet',
    value: '#8a8b8a'
  },
  {
    name: 'Memnuniyet',
    value: '#f28a74'
  },
  {
    name: 'Öneri',
    value: '#89aa6c'
  },
  {
    name: 'Tavsiye',
    value: '#a17cb6'
  },
  {
    name: 'Diger',
    value: '#7f90a8'
  }
  ];
  brand = [];
  hashModel = [
    { uniqueName: 'benimtavsiyem', name: 'Tavsiye Paylaşım Oranı' },
    { uniqueName: 'markamaönerim', name: 'Öneri Paylaşım Oranı' },
    { uniqueName: 'bunuçoksevdim', name: 'Memnuniyet Paylaşım Oranı' }
  ];
  reportModel: any = {};
  cardHour = 0;
  cardMinute = 0;
  displayedColumns = ['name', 'firstColumn', 'secondColumn'];
  dataSource;
  desserts = [];
  total = 0;
  totalPost = 0;
  reposts;
  show = true;
  constructor(private chooseCompany: ChooseCompanyService,
    private afm: AngularFireDatabase,
    private afs: AngularFirestore,
    private api: ApiService,
    private cd: ChangeDetectorRef) {
    // this.afm.list('brand/samsung/timeline').$ref.once('value',snap => {
    //   var a = Object.keys(snap.val());
    //   console.log(a.length);
    // })


  }

  ngOnInit() {
    this.reportModel.startDate = '2018-01-01';
    var newDate2 = new Date(new Date().getTime() + Math.abs(new Date().getTimezoneOffset() * 60000));
    this.reportModel.endDate = newDate2.toISOString().split('T')[0];

    var to = 0;
    this.subscription.push(this.chooseCompany.getCompany().subscribe(data => {

      if (data.uniqueName) {

        this.api.sqlPost('corporate/interactionPosts3', {
          uniqueName: data.uniqueName,
          startDate: this.reportModel.startDate,
          endDate: this.reportModel.endDate,
          period: 1
        }).map(res => res.json()).toPromise().then((graphData => {

          this.totalPost = 0;
          this.total = 0;
          graphData.forEach(element => {
            this.totalPost += parseInt(element.postCount)
            this.total += parseInt(element.totalAct);
          })

        })).then(() => {
          var repAct = 0;
          this.subscription.push(this.api.sqlPost('corporate/interactionPosts3', {
            uniqueName: data.uniqueName,
            startDate: this.reportModel.startDate,
            endDate: this.reportModel.endDate,
            period: 2
          }).map(res => res.json()).subscribe(graphData => {
            graphData.forEach(element => {
              to += parseInt(element.likes);
              to += parseInt(element.comments);
              to += parseInt(element.reposts);
              to += parseInt(element.favorites);
              repAct += parseInt(element.likes) + parseInt(element.comments) + parseInt(element.favorites) + parseInt(element.reposts);
            });
            this.total += repAct;
          }));

        });


        this.subscription.push(this.api.sqlPost('corporate/answeredTime', {
          uniqueName: data.uniqueName,
          startDate: this.reportModel.startDate,
          endDate: this.reportModel.endDate,
        }).map(res => res.json()).subscribe(feedback => {
          this.cardHour = Math.floor(feedback[0].answeredTime / 60);

          this.cardMinute = feedback[0].answeredTime - (this.cardHour * 60);

          this.feedback = feedback[0];


        }));
        // this.afs.collection('brands').ref.where('uniqueName','==',data.uniqueName).get().then((brandId)=>{
        //   this.afs.collection('brands').doc(brandId.docs[0].id).collection('categories').ref.where('name','==','Genel').get().then((rateData=>{
        //     if(!rateData.empty){
        //       this.brandRate=rateData.docs[0].data().total/rateData.docs[0].data().peopleCount
        //     }
        //   }))
        // })
        if (data.uniqueName) {
          this.afs.collection('brands').ref.where('uniqueName', '==', data.uniqueName).get().then((brandId) => {
            this.afs.collection('brands').doc(brandId.docs[0].id).collection('categories').ref.where('name', '==', 'Genel').get().then((rateData => {
              if (!rateData.empty) {
                this.brandRate = rateData.docs[0].data().total / rateData.docs[0].data().peopleCount;
              }
            }))
          })
          this.cd.markForCheck();

        }

        this.api.sqlPost('corporate/crooscheckHashtagAll2', {
          uniqueName: data.uniqueName
        })
          .map(res => res.json()).toPromise().then(snap => {

            this.desserts = [];
            var toplam = 0;

            snap.forEach(element => {

              toplam += element.postCount;
              this.desserts.push({
                hashtags: this.capitalizeFirstLetter(element.hashtag),
                markaSiralama: element.row,
                paylasimSayisi: element.postCount,
                yuzde: (element.postCount / element.hashTotal) * 100 || 0
              });
            });

            this.desserts.push({
              hashtags: 'Toplam',
              paylasimSayisi: toplam,
              yuzde: toplam != 0 ? 100 : 0
            });
            this.dataSource = this.desserts;
            this.show = false;
            this.cd.markForCheck();
          });
        this.subscription.push(this.api.sqlPost("corporate/reportByHashtag2", {
          uniqueName: data.uniqueName,
          period: 2,
          startDate: '2018-01-01',
          endDate: this.reportModel.endDate
        })
          .map(res => res.json()).subscribe((data: any) => {
            let advancedPieData = data.reduce((result, curr) => {

              if (curr.name == 'sikayet') {
                curr.name = 'şikayet'
              }
              if (curr.month == 1) curr.month = 'Ocak'
              if (curr.month == 2) curr.month = 'Şubat'
              if (curr.month == 3) curr.month = 'Mart'
              if (curr.month == 4) curr.month = 'Nisan'
              if (curr.month == 5) curr.month = 'Mayıs'
              if (curr.month == 6) curr.month = 'Haziran'
              if (curr.month == 7) curr.month = 'Temmuz'
              if (curr.month == 8) curr.month = 'Ağustos'
              if (curr.month == 9) curr.month = 'Eylül'
              if (curr.month == 10) curr.month = 'Ekim'
              if (curr.month == 11) curr.month = 'Kasım'
              if (curr.month == 12) curr.month = 'Aralık'
              let dynamicName;
              dynamicName = curr.month + "/" + curr.year;
              let chartSeries = (result[dynamicName] || []);
              chartSeries.push({
                'name': curr.name ? this.capitalizeFirstLetter(curr.name) : '',
                'value': curr.count
              });


              result[dynamicName] = chartSeries;
              return result;
            }, {});

            let entriesArray = Object.entries(advancedPieData);
            entriesArray.forEach(data => {

              this.hashInfo.push({
                "name": data[0],
                "series": data[1]
              })


            })
            this.cd.markForCheck();


          }));
      }

    }));



  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  ngOnDestroy() {
    this.subscription.forEach(a => {
      a.unsubscribe();
    })
  }



}

