import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'storagePath'
})
export class StoragePathPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      if (value.indexOf("UserIcon.png") != -1) {
        value = "UserIcon.png";
      } else if (value.indexOf("no-image.png") != -1) {
        value = "no-image.png";
      }
      return environment.storagePath + value + "?alt=media"
    }
  }

}
