import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import * as firebase from "firebase";
import { MatDialog } from '@angular/material';
import { fuseAnimations } from '../../../core/animations';
import { AngularFireDatabase } from 'angularfire2/database-deprecated';
import { DialogComponent } from './dialog/dialog.component';
import { Dialog2Component } from './dialog2/dialog2.component';


@Component({
  selector: 'app-banner-upload',
  templateUrl: './banner-upload.component.html',
  styleUrls: ['./banner-upload.component.scss'],
  animations: fuseAnimations
})
export class BannerUploadComponent implements OnInit {
  form: FormGroup;
  formErrors: any;
  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {
    this.formErrors = {
      company: {},
      confirmPassword: {},
      password: {},
      currentPassword: {},
      address2: {},
      city: {},
      state: {},
      postalCode: {}
    };
  }

  ngOnInit() {

  }

  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.formErrors[field] = {};

      // Get the control
      const control = this.form.get(field);

      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }


  onFileSelected(event) {


  }
  openModel(data?, type?, index?) {
    this.dialog.open(DialogComponent, {
      width: '700px',
      data: data ? { type: type, ...data, key: index } : null
    });
  }
  openModel2(data?, type?, index?) {
    this.dialog.open(Dialog2Component, {
      width: '700px',
      data: data ? { type: type, ...data, key: index } : null
    });
  }


}
